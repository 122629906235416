<div
	*ngIf="userIsLoggedIn$ | async"
	id="header"
	class="NavContainer {{ uniquePageClass() }}"
>
	<app-sidenav class="MainNav"></app-sidenav>
</div>

<div
	class="col-12 col row"
	id="MainAppContainer"
	[ngClass]="{ LoggedIn: (userIsLoggedIn$ | async) }"
>
	<div class="col-12 full-height">
		<router-outlet class="router-outlet col-12 row"></router-outlet>
	</div>
</div>

<div id="footer" [ngClass]="{ footerLoggedOut: !(userIsLoggedIn$ | async) }">
	<app-footer></app-footer>
</div>
