<div class="dialog-container col">
	<div class="col-12 col">
		<div matDialogTitle>{{ title }}</div>

		<div *ngIf="this.textBlock != undefined">
			{{ this.textBlock }}
		</div>
		<br *ngIf="this.textBlock != undefined" />
	</div>

	<div class="col-12 col pb10">
		<div class="flex-1 col">
			<app-list-search-header
				[listTitle]="listTitle"
				[listControlForm]="listControlForm"
				[indexCount$]="listDataCount$"
				[indexFilteredCount$]="listDataFilteredCount$"
				class="search-header"
			></app-list-search-header>
		</div>

		<mat-slide-toggle
			*ngIf="(listDataFilteredCount$ | async) > 0 && userHasPermissionToEdit"
			class="list-checkbox pt10 select-all"
			[checked]="this.selectAllSelected"
			(change)="this.selectAllHandler()"
		>
			Select All</mat-slide-toggle
		>
	</div>

	<div *ngIf="this.displayTooManyOptions" class="note row col-12">
		<div class="col justify-center align-center pr20">
			<i aria-hidden="true" class="bi bi-exclamation-circle"></i>
		</div>
		<div class="flex-1 col justify-center">
			<span>There are too many options to display.</span>
			<span>Please use the search facility to reduce the options.</span>
		</div>
	</div>

	<div
		[formGroup]="checkboxFormGroup"
		mat-dialog-content
		class="no-scroll-visible"
	>
		<mat-list class="scroll-vertical column col-12" style="max-height: 42vh">
			<div
				class="dialog-checkbox-group no-scroll-visible"
				style="margin-top: 10px"
			>
				<ng-container *ngFor="let item of this.listDataFiltered$ | async">
					<mat-checkbox
						[ngClass]="{ disabled: !userHasPermissionToEdit }"
						class="list-checkbox notranslate align-center row"
						[formControlName]="item.id"
					>
						<span
							*ngIf="componentFactory === undefined"
							class="mt10 mb10 flex align-center"
							[ngClass]="{ dirty: checkboxFormGroup.controls[item.id].dirty }"
							[innerHtml]="this.labelFunction(item)"
						>
						</span>

						<!-- If we have a component fatory, render list items using it -->
						<app-injected-wrapper
							*ngIf="componentFactory !== undefined"
							[componentFactory]="componentFactory"
							[dataVO]="item"
							[paramName]="componentVOParamName"
						>
						</app-injected-wrapper>
					</mat-checkbox>
				</ng-container>
			</div>
		</mat-list>
	</div>

	<p class="selected-results tar">
		Selected Items ({{ this.selectedItemCount }})
	</p>

	<div mat-dialog-actions class="fb-right">
		<button mat-raised-button color="primary" (click)="dialogRef.close()">
			{{ cancelButtonLabel }}
		</button>
		<button
			mat-raised-button
			color="primary"
			[disabled]="checkboxFormGroup.pristine"
			(click)="this.selectionConfirm()"
		>
			{{ confirmButtonLabel }}
		</button>
	</div>
</div>
