import {
	map,
	takeUntil,
	first,
	startWith,
	debounceTime,
	delay,
	tap,
} from "rxjs/operators";
import { Component, OnInit, OnDestroy, ComponentFactory } from "@angular/core";

import { Hark, componentDestroyStream } from "modules/common/hark.decorator";

import { MatDialogRef } from "@angular/material/dialog";

import { FormGroup, FormBuilder, FormControl } from "@angular/forms";

import { Observable, of, combineLatest } from "rxjs";
import { isEqual, sortBy } from "lodash";

import { SearchUtils } from "modules/common/search-utils";
import { EntityPermissionAHubVO } from "app/valueObjects/ahub/accounts/entity-permission.ahub.vo";
import { PermissionsService } from "app/services/permissions/permissions.service";

@Component({
	selector: "app-list-dialog",
	templateUrl: "./select-multi-list-dialog.component.html",
	styleUrls: ["./select-multi-list-dialog.component.css"],
})
@Hark()
export class SelectMultiListDialogComponent implements OnInit, OnDestroy {

	/**
	 * Control for the form input
	 */
	searchFormControl: FormControl = new FormControl("");

	/**
	 * Form to control how the list is dispayed on screen.
	 */
	listControlForm: FormGroup = this.formBuilder.group({
		searchFormControl: this.searchFormControl,
	});

	// Component factory that generates component for displaying objects in our list - use instead of LabelFunction.
	componentFactory: ComponentFactory<any>;

	// Parameter name on the generated component to which to pass the item in - use instead of Label Function.
	componentVOParamName: string;

	/**
	 * This is the title and content to display in this confirmation dialog.
	 */
	public title: string;

	/**
	 * This is a block of text that goes bellow the title
	 */
	public textBlock: string;

	/**
	 * This is the title of the list selection.
	 */
	public listTitle: string;

	/**
	 * This is the list data to display.
	 */
	public listData$: Observable<any[]>;

	/**
	 * This is the count of the list data before filter.
	 */
	public listDataCount$: Observable<number>;

	/**
	 * The list data to display thats been filtered.
	 */
	public listDataFiltered$: Observable<any[]>;

	/**
	 * This is the count of the list data after filters.
	 */
	public listDataFilteredCount$: Observable<number>;

	/**
	 * This is the maximum number of options we can display.
	 */
	private static readonly MAX_OPTION_DISPLAY_COUNT = 500;

	/**
	 * This is the maximum display limit we can display at present.
	 */
	public displayTooManyOptions: boolean = false;

	/**
	 * This is a private instance of the filtered list
	 */
	private listDataFiltered: any[] = [];

	/**
	 * Create a stream which represents the stream of form value changes
	 */
	listControlFormValues$: Observable<any> =
		this.listControlForm.valueChanges.pipe(debounceTime(200));

	/**
	 * Property which will be used for the label of the item
	 */
	public labelProperty = "name";

	/**
	 * These are the labels to be used on the confirm and cancel buttons.
	 */
	public confirmButtonLabel: string;
	public cancelButtonLabel: string;

	/**
	 * Determine whether the list items should be editable (and savable)
	 */
	public editableAs: EntityPermissionAHubVO;
	userHasPermissionToEdit = true;

	/**
	 * Is the select all selected?
	 */
	public selectAllSelected = false;

	/**
	 * Form control for the check boxes
	 */
	public checkboxFormGroup: FormGroup;

	/**
	 * Selected items count
	 */
	public selectedItemCount = 0;

	/**
	 * Initial selected ids which we want to set as a default
	 */
	private initialSelectedIds: number[] = [];

	/**
	 * The currently selected ids.
	 */
	private currentSelectedIds: number[] = [];

	/**
	 * Has the state changed
	 */
	public stateChanged$: Observable<boolean> = of(false);

	/**
	 * Create an instance of the list dialog.
	 */
	constructor(
		private readonly formBuilder: FormBuilder,
		public readonly dialogRef: MatDialogRef<SelectMultiListDialogComponent>,
		private permissionService: PermissionsService
	) {}

	ngOnInit() {
		//Create a new empty from group we will add to this later
		this.checkboxFormGroup = this.formBuilder.group({});

		//Watch the form values so we can keep the select all flag maintained
		this.checkboxFormGroup.valueChanges
			.pipe(debounceTime(50), takeUntil(componentDestroyStream(this)))
			.subscribe((selectedData) => {
				//Have they all been selected, default to yes
				let isAllSelected = true;

				//Loop throught each of the properties
				for (const listData of this.listDataFiltered) {
					//Is this property selected
					if (!selectedData[listData.id]) {
						isAllSelected = false;
						break;
					}
				}

				//Set the selected item count to zero
				this.selectedItemCount = 0;

				//Loop throught each of the properties
				for (const propt in selectedData) {
					if (selectedData[propt]) {
						this.selectedItemCount++;
					}
				}

				//Set if all is currently selected
				this.selectAllSelected = isAllSelected;
			});

		//Subscribe to the data list to get the items from the list
		this.listDataFiltered$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((items) => {
				//List of the selected ids
				const selectedIds = this.initialSelectedIds
					? this.initialSelectedIds
					: [];

				//Loop through each of the items so we can add them all to the list
				items.forEach((item) => {
					//See if the item is on the selected id list
					const isSelectedByDefault: boolean =
						selectedIds.find((id) => id === item.id) !== undefined;

					//Create a new form control for the item this the specified id
					this.checkboxFormGroup.addControl(
						item.id,
						this.formBuilder.control(isSelectedByDefault)
					);
				});

				//Everything is unselected to set the unselected item
				this.selectAllSelected = false;

				//Set the list data filtered items
				this.listDataFiltered = items;

				this.checkboxFormGroup.valueChanges
					.pipe(takeUntil(componentDestroyStream(this)))
					.subscribe((values) => {

						// Now we need to work out if we need to change the current list of selected ids.
						// So get the keys as integers.
						const keys = Object.keys( values );

						// Repeat through each of the keys looking to see if any id's need to be added or removed.
						keys.forEach( key => {

							// Get each value.
							const value = values[ key ];

							// Make sure the key is an int
							const keyInt = parseInt( key );

							// Get the index of the key on the current list.
							const index = this.currentSelectedIds.indexOf( keyInt );

							// Now, do we need to add the value?
							if( value && index == -1 ) {
								this.currentSelectedIds.push( keyInt );
							}
							// Or do we need to remove the id?
							else if( !value && index > -1 ) {
								this.currentSelectedIds.splice( index, 1 );
							}
						});

						// Now we need to 
						const currentlySelectedIds = keys
							.map((x) => {
								return { key: x, value: values[x] };
							})
							.filter((y) => y.value)
							.map((z) => parseInt(z.key));

						if (isEqual(sortBy(selectedIds), sortBy(currentlySelectedIds))) {
							this.checkboxFormGroup.markAsPristine();
						}
					});
			});

		if (this.editableAs) {
			this.userHasPermissionToEdit =
				this.permissionService.userHasPermissionToView([this.editableAs]);
		}
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * This function will set the list data in the dialog.
	 */
	listDataSet(listData$: Observable<any[]>) {
		// Record the list data.
		this.listData$ = listData$;

		// Set up the non filtered count.
		this.listDataCount$ = this.listData$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((items) => (items ? items.length : 0))
		);

		// Set up the list data with a combine latest so the user can search the list.
		this.listDataFiltered$ = combineLatest([
			this.listData$,
			this.listControlFormValues$.pipe(startWith({})),
		]).pipe(
			takeUntil(componentDestroyStream(this)),
			map(([listData, listControlForm]) => {
				// Do we have any data? If not, stop here.
				if (listData === undefined) {
					return listData;
				}

				// Do we have any search form control values?
				if (this.searchFormControl.value.length > 0) {
					// Yes, so run the filter the list based on the search input
					listData = listData.filter((item) =>
						SearchUtils.stringSearch(listControlForm.searchFormControl, [
							item[this.labelProperty],
						])
					);
				}

				// Have we exceed what we can display?
				this.displayTooManyOptions =
					listData.length >
					SelectMultiListDialogComponent.MAX_OPTION_DISPLAY_COUNT;

				// Now return an empty array if we have exceeded the limit, otherwise the list.
				return this.displayTooManyOptions ? [] : listData;
			})
		);

		// Set up the filtered count.
		this.listDataFilteredCount$ = this.listDataFiltered$.pipe(
			takeUntil(componentDestroyStream(this)),
			map((items) => (items ? items.length : 0))
		);
	}

	/**
	 * This function will set the selected ids
	 */
	listDataSelectedSet(selectedIds: number[]) {
		//If the list is empty then bail out
		if (!selectedIds) {
			return;
		}

		//Set the selected ids
		this.initialSelectedIds = selectedIds;
		this.currentSelectedIds = selectedIds.slice();
	}

	/**
	 * Label function to get the label for the object supplied
	 */
	labelFunction(object: any) {
		return object ? object[this.labelProperty] : "";
	}

	/**
	 * Handel the select all click handler
	 */
	selectAllHandler() {
		//Are we setting all to selected?
		const valueToSet = !this.selectAllSelected;

		//Loop throught each of the properties
		for (const listData of this.listDataFiltered) {
			//Get the form control for this property
			const formControl = this.checkboxFormGroup.controls[listData.id];

			//Set the value in the form control
			formControl.setValue(valueToSet);
			formControl.markAsDirty();
		}

		//Set the select all
		this.selectAllSelected = !this.selectAllSelected;
	}

	/**
	 * Confirm the selection
	 */
	selectionConfirm() {
		//We will subscribe to the list to get the data
		this.listData$.pipe(first()).subscribe((list) => {

			//Array of the selected items
			const selectedItems = list.filter( item => this.currentSelectedIds.indexOf( parseInt( item.id ) ) > -1 );

			//Close the dialogue reference with the array of the selected items
			this.dialogRef.close(selectedItems.length > 0 ? selectedItems : []);
		});
	}
}
