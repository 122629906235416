<mat-chip
	disableRipple
	class="col-12 pl10 pl10"
	style="margin: 0px !important"
	[style.border-color]="this.extractAllocationObject?.section?.colour"
	[ngStyle]="{
		'background-color': this.extractAllocationObject?.section?.colour
			? 'white'
			: '#e0e0e0'
	}"
	class="col-12 col propertyChip"
	(click)="chipClick()"
>
	<div
		class="chipBG"
		[style.background]="this.extractAllocationObject?.section?.colour"
	></div>
	<div class="flex space-between align-center col-12 oh">
		<div class="col flex-1" style="max-width: 100%">
			<div
				style="text-overflow: ellipsis; max-width: 100%; overflow: hidden"
				class="col col-12"
			>
				<div
					style="
						text-overflow: ellipsis;
						max-width: 100%;
						overflow: hidden;
						font-size: 12px;
						margin-bottom: 3px;
					"
					class="notranslate"
				>
					{{ this.extractAllocationObject?.property?.label }}
					<span
						class="typeReference notranslate"
						*ngIf="this.extractAllocationObject?.property.typeReference"
					>
						({{
							this.extractAllocationObject?.property.typeReference | titlecase
						}})
					</span>
				</div>
				<div
					style="font-weight: lighter; text-overflow: ellipsis; font-size: 12px"
					class="notranslate"
					*ngIf="this.extractAllocationObject?.section?.label"
				>
					{{ this.extractAllocationObject?.section?.label }} -
					{{ this.extractAllocationObject?.productClass?.label }}
				</div>
			</div>
		</div>
		<div class="col align-center justify-center" *ngIf="enableIcons">
			<div
				*ngIf="this.extractAllocationObject?.property?.matrix"
				class="pb5 align-center col justify-end"
			>
				<mat-icon class="iconSize14" style="margin: 0px !important">
					grid_on</mat-icon
				>
			</div>
			<button
				mat-icon-button
				class="full-height lockButton"
				*ngIf="enableReadOnlyIcon"
			>
				<mat-icon
					class="iconSize16 notranslate"
					matTooltip="{{
						this.extractAllocationObject?.readOnly ? 'Read Only' : 'Editable'
					}}"
				>
					{{
						this.extractAllocationObject?.readOnly ? "lock" : "lock_open"
					}}</mat-icon
				>
			</button>
		</div>
	</div>
</mat-chip>
