import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { selectedRouteView } from "app/store/selector/view/view-routes.selector";
import { StoreAccess } from "app/store/store-access";
import { RouteViewVO } from "app/valueObjects/view/route.view.vo";
import { Observable } from "rxjs";

@Component({
	selector: "app-sidenav-route",
	templateUrl: "./sidenav-route.component.html",
	styleUrls: ["./sidenav-route.component.css"],
})
export class SidenavRouteComponent implements OnInit {
	@Input() routes: RouteViewVO[];

	@Input() depth: number;
	@Output() closeNav = new EventEmitter();

	selectedRouteView$: Observable<RouteViewVO> =
		StoreAccess.dataGetObvs(selectedRouteView);

	constructor() {
		// This is intentional
	}

	ngOnInit() {
		// This is intentional
	}

	closeNavEmit(event) {
		this.closeNav.emit();
	}

	closeNavClick(event) {
		this.closeNav.emit();
	}
}
