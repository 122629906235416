<div
	#hoverable
	class="assetContainer full-height"
	*ngIf="this._productAssetViewParam != undefined"
>
	<div
		makedroppable
		[allowDropToEmitDataEvent]="true"
		(dropped)="this.dragdropHandler($event)"
		class="droppable-area"
	>
		<!-- Asset Image -->
		<div class="assetImageContainer flex">
			<div
				*ngIf="!this._productAssetViewParam.glbURL"
				[matTooltip]="tooltip"
				[ngClass]="{ whiteBg: !noPreviewImage }"
			>
				<img
					#previewImage
					*ngFor="
						let assetImageUrl of this._productAssetViewParam
							.assetImagePreviewURL;
						index as i
					"
					[src]="assetImageUrl"
					alt="/assets/images/imageNotAvailable.png"
					class="assetImage"
					[ngClass]="{ noPreviewImage: noPreviewImage }"
					[ngStyle]="{ display: i == 0 ? 'block' : 'none' }"
					(load)="imageLoaded()"
					(error)="imageLoadFailed($event)"
					[attr.loading]="getImageLoadType(i)"
				/>

				<div *ngIf="imageLoading$ | async" class="imageLoading">
					<mat-spinner
						class="spinner"
						[diameter]="32"
						color="accent"
					></mat-spinner>
				</div>
			</div>

			<!-- GLB Asset -->
			<div *ngIf="this._productAssetViewParam.glbURL" class="glbContainer">
				<div class="glbImageContainer">
					<app-three-d-renderer
						#glbViewer
						class="glbImage"
						[params]="this.get3DRendererParams()"
						(dragOver)="this.dragOver3DHandlder($event)"
						(dragLeave)="this.dragLeave3DHandler($event)"
						(dragDrop)="this.dragDrop3DHandler($event)"
					></app-three-d-renderer>
				</div>
			</div>
			<!-- Busy Status -->
			<div
				*ngIf="this._productAssetViewParam.busyIndicatorState != 'none'"
				class="loadingSpinnerStatus"
			>
				<mat-progress-spinner
					diameter="30"
					[mode]="this._productAssetViewParam.busyIndicatorState"
					[value]="this._productAssetViewParam.busyIndicatorProgress | async"
					[value]="50"
					style="z-index: 60"
					color="accent"
				>
				</mat-progress-spinner>
			</div>
		</div>

		<!-- Top Left Button -->
		<button
			mat-icon-button
			*ngIf="this._productAssetViewParam.leftIcon"
			[matTooltip]="this._productAssetViewParam.leftIconTooltip"
			class="topLeftButton"
			[ngStyle]="{
				color: this._productAssetViewParam.leftIconColour
					? this._productAssetViewParam.leftIconColour
					: 'black',
				'pointer-events': this._productAssetViewParam.leftIconClickFunc
					? 'auto'
					: 'none'
			}"
			(click)="this.leftIconButtonClickHandler()"
		>
			<mat-icon class="notranslate"
				>{{ this._productAssetViewParam.leftIcon }}
			</mat-icon>
		</button>
		<!-- Top Right Icon -->
		<button
			mat-icon-button
			class="topRightButton"
			[matTooltip]="this._productAssetViewParam.rightIconTooltip"
			*ngIf="this._productAssetViewParam.rightIcon"
			(click)="this.rightIconButtonClickHandler()"
			[ngStyle]="{
				color: this._productAssetViewParam.rightIconColour
					? this._productAssetViewParam.rightIconColour
					: 'black'
			}"
		>
			<mat-icon class="notranslate">{{
				this._productAssetViewParam.rightIcon
			}}</mat-icon>
		</button>
	</div>

	<!-- Bottom Text -->
	<div
		class="assetTextContainer p10"
		*ngIf="!_productAssetViewParam?.hideAssetDetails"
	>
		<div
			style="
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
			"
		>
			<div class="col-12 pb5">
				<span
					class="sectionIconSmall"
					[style.background]="this._productAssetViewParam?.sectionColour"
				></span>
				<span class="bold">{{ this._productAssetViewParam?.sectionName }}</span>
			</div>
			<div class="col-12">
				<span>{{ this._productAssetViewParam?.propertyName }}</span>
			</div>
		</div>

		<!-- Menu Button -->
		<div
			*ngIf="
				_productAssetViewParam.menuButtonCheckVisible(_productAssetViewParam)
			"
		>
			<button
				mat-icon-button
				(click)="this.menuButtonClickHandler($event)"
				*ngIf="this._productAssetViewParam.menuButtonDisplay == 'button'"
			>
				<mat-icon>more_vert</mat-icon>
			</button>
			<button
				mat-icon-button
				(click)="$event.stopPropagation()"
				[matMenuTriggerFor]="assetMenu"
				*ngIf="this._productAssetViewParam.menuButtonDisplay == 'menu'"
			>
				<mat-icon>more_vert</mat-icon>
			</button>
		</div>

		<!-- Menu -->
		<mat-menu
			#assetMenu="matMenu"
			class="mouseleave slimMenu"
			yPosition="above"
			overlapTrigger="true"
		>
			<div
				(mouseleave)="closeMenu()"
				style="display: flex; flex-direction: column"
			>
				<!-- Configurable buttons specified in the parameters -->
				<div
					*ngFor="let menuButton of this._productAssetViewParam.menuButtons"
					[ngStyle]="{
						display: this.checkButtonVisability(menuButton) ? 'block' : 'none'
					}"
				>
					<button
						mat-menu-item
						[disabled]="menuButton?.buttonDisabled"
						class="menuButton"
						(click)="
							menuButton?.buttonClickFunc(this._productAssetViewParam);
							$event.stopPropagation()
						"
						[matTooltip]="menuButton?.buttonTooltip"
					>
						<div class="pr10 pl10">
							<span style="width: 100%; font-size: 12px; white-space: normal">{{
								menuButton?.buttonName
							}}</span>
						</div>
					</button>
				</div>
			</div>
		</mat-menu>
		<!-- Menu -->
	</div>
</div>
