<div class="col col-12">
	<div class="row space-between align-center">
		<div
			*ngIf="showTitle"
			class="card-sub-title"
			style="display: flex; justify-content: space-between; align-items: center"
		>
			<span class="notranslate">{{ property?.label }} Matrix</span>
			<button
				mat-raised-button
				*ngIf="showDoneButton"
				color="primary"
				class="tac"
				(click)="this.done()"
			>
				<span>Done</span>
			</button>
		</div>

		<!-- Dropdown to select the current dimention rendering the display -->
		<select
			style="
				height: 20px;
				width: 120px;
				margin-bottom: 10px;
				border: solid 1px #d8ddde;
			"
			*ngIf="this.zAxisDimension"
			(change)="matrixDimensionChange($event.target.value)"
		>
			<!-- Values for each dimension the value is the index location not the dimention number -->
			<option
				*ngFor="let option of this.zAxisDimension.options"
				value="{{ option.id }}"
			>
				{{ option.id }}
			</option>
		</select>
	</div>

	<!-- Table component -->
	<div class="col-12 flex-1 col mb10">
		<ag-grid-angular
			class="ag-theme-alpine"
			style="height: 220px; width: 454px"
			[frameworkComponents]="this.frameworkComponents"
			[gridOptions]="this.gridOptions"
			(keydown)="gridKeyDownHan($event)"
		>
		</ag-grid-angular>
	</div>

	<div
		class="copy-clipboard"
		style="
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 8px;
		"
		*ngIf="this.showRawDataInput"
	>
		<mat-form-field
			floatingPlaceholder="true"
			class="col-12 clear"
			appearance="outline"
		>
			<mat-label>Value</mat-label>
			<input
				matInput
				[ngModel]="matrixString"
				(ngModelChange)="matrixInputValueChange($event)"
				(keydown)="gridKeyDownHan($event)"
				[readonly]="!this.editable"
			/>
		</mat-form-field>

		<mat-icon
			id="copyIcon"
			class="notranslate"
			style="cursor: pointer; padding-left: 8px"
			matTooltip="Copy"
			(mousedown)="this.copyValueToClipboard()"
			>file_copy</mat-icon
		>
	</div>
</div>
