<div class="dialog-container">
	<h1 matDialogTitle class="dialog-title" style="display: flex">
		<mat-icon
			class="notranslate smallInfoIcon mr10"
			svgIcon="knowledgebase"
		></mat-icon
		>{{ title }}
	</h1>
	<div mat-dialog-content [innerHtml]="content | trustedContent: 'html'"></div>
	<div mat-dialog-actions class="fb-right">
		<button
			mat-button
			mat-raised-button
			color="primary"
			(click)="dialogRef.close(true)"
		>
			Ok
		</button>
	</div>
</div>
