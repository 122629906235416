<div
	style="
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		vertical-align: middle;
	"
	(mouseenter)="this.displayCopyButtonHandler(true)"
	(mouseleave)="this.displayCopyButtonHandler(false)"
	*ngIf="isValidTimestamp()"
>
	<span
		class="notranslate oh"
		[ngStyle]="{
			'max-width': this.displayCopyButton ? 'calc( 100% - 25px )' : '100%'
		}"
		>{{ this.valueToDisplay | date: params.dateFormat }}
	</span>

	<mat-icon
		id="copyIcon"
		class="notranslate"
		style="cursor: pointer"
		*ngIf="this.displayCopyButton"
		matTooltip="Copy"
		(mousedown)="this.copyValueToClipboard($event)"
		(click)="$event.stopPropagation()"
	>
		file_copy</mat-icon
	>
</div>
