<form
	[formGroup]="libraryViewClassConfigForm"
	[trackedForm]="libraryViewClassConfigForm"
	class="library-view-form full-height oh"
>
	<div class="col-12 row card-sub-title pb20 space-between">
		<span>{{ (selectedClass$ | async)?.label }} Properties</span>
		<mat-icon
			class="notranslate smallInfoIcon"
			matTooltip="Properties selected here will be inherited by child classes. Child class config can override inherited 'Asset' and 'Title' properties or add to 'Additional Properties'"
			svgIcon="knowledgebase"
		></mat-icon>
	</div>
	<div class="col-12 flex-1 col scroll-y">
		<div class="stageGrid">
			<div class="stageNumber align-center flex justify-center">
				<span>1</span>
			</div>
			<div class="stageTitle align-center flex">
				<span>Choose Asset</span>
			</div>
			<div class="stageInfo">
				<!-- -->
				<app-select-with-search
					class="chipFormField"
					*ngIf="
						hasValidSelectableOptions(this.filteredAssetTypeAllocs$ | async);
						else noAssetProperties
					"
					[selectFormControl]="
						libraryViewClassConfigForm.controls.productMainImagePropertyAlloc
					"
					style="padding-left: 0px !important; width: 100%"
					[placeholder]="'Select Property'"
					[selectOptions]="this.filteredAssetTypeAllocs$ | async"
					[searchableOptionValueFunction]="allocPropertyLabelValue"
					[selectedValueFunction]="allocIdValue"
				>
					<ng-template #selectOption let-option>
						<app-property-chip
							[enableIcons]="false"
							[extractAllocationObject]="option"
							class="col-12"
							style="line-height: 1em; padding: 5px 0px 5px 5px !important"
						></app-property-chip>
					</ng-template>
				</app-select-with-search>

				<ng-template #noAssetProperties>
					<div style="height: 60px">
						No asset properties available, please speak with your aHub
						administrator to resolve this
					</div>
				</ng-template>

				<!-- -->
			</div>

			<div class="stageNumber align-center flex justify-center">
				<span>2</span>
			</div>
			<div class="stageTitle align-center flex row flex-nowrap">
				<span>Choose Title</span>
			</div>
			<div class="stageInfo row flex-nowrap">
				<!-- -->
				<app-select-with-search
					class="chipFormField"
					*ngIf="
						hasValidSelectableOptions(
							this.filteredNoneAssetTypeAllocs$ | async
						);
						else noTitleProperties
					"
					[selectFormControl]="
						libraryViewClassConfigForm.controls.productIdentifierPropertyAlloc
					"
					style="width: 100%; padding-left: 0px !important"
					[placeholder]="'Select Property'"
					[selectOptions]="this.filteredNoneAssetTypeAllocs$ | async"
					[searchableOptionValueFunction]="allocPropertyLabelValue"
					[selectedValueFunction]="allocIdValue"
				>
					<ng-template #selectOption let-option>
						<app-property-chip
							[enableIcons]="false"
							[extractAllocationObject]="option"
							class="col-12"
							style="line-height: 1em; padding: 5px 0px 5px 5px !important"
						></app-property-chip>
					</ng-template>
				</app-select-with-search>

				<ng-template #noTitleProperties>
					<div style="height: 60px">
						No title properties available, please speak with your aHub
						administrator to resolve this
					</div>
				</ng-template>
				<!-- -->
			</div>

			<div class="stageNumber align-center flex justify-center">
				<span>3</span>
			</div>
			<div class="stageTitle align-center flex">
				<span>Additional Properties</span>
			</div>
			<div class="stageInfo col">
				<div class="infoTable col-12">
					<div
						*ngIf="(dataSource$ | async)?.length > 0"
						class="table-container"
					>
						<table
							mat-table
							[dataSource]="dataSource$"
							formArrayName="productInfoPropertyAllocs"
							aria-hidden="true"
						>
							<tr
								mat-header-row
								*matHeaderRowDef="displayedColumns; sticky: true"
							></tr>
							<tr
								mat-row
								*matRowDef="
									let row;
									let index = index;
									columns: displayedColumns
								"
							></tr>

							<ng-container matColumnDef="id">
								<mat-header-cell *matHeaderCellDef></mat-header-cell>
								<td
									mat-cell
									*matCellDef="let row; let index = index"
									[formGroupName]="index"
									style="display: flex; padding-left: 0px !important"
								>
									<app-select-with-search
										style="padding-left: 0px; padding-right: 0px"
										[selectFormControl]="row.controls.id"
										class="chipFormField col-12"
										[placeholder]="'Select Property'"
										[selectOptions]="filteredNoneAssetTypeAllocs$ | async"
										[searchableOptionValueFunction]="allocPropertyLabelValue"
										[selectedValueFunction]="allocIdValue"
									>
										<ng-template #selectOption let-option>
											<app-property-chip
												[enableIcons]="false"
												[extractAllocationObject]="option"
												class="col-12"
												style="
													line-height: 1em;
													padding: 5px 0px 5px 5px !important;
												"
											></app-property-chip>
										</ng-template>
									</app-select-with-search>
								</td>
							</ng-container>

							<!-- Property Value Column -->
							<ng-container matColumnDef="value">
								<mat-header-cell *matHeaderCellDef></mat-header-cell>
								<td
									mat-cell
									*matCellDef="let row; let index = index"
									[formGroupName]="index"
								>
									<div
										*ngIf="
											row.controls.id.value &&
											!inheritedAllocClassLabelMap.get(row.controls.id.value)
										"
										class="row justify-end align-center"
									>
										<button
											matTooltip="Move info property UP"
											(click)="move(-1, index)"
											class="editButton"
										>
											<mat-icon class="notranslate iconSize18">
												arrow_upward
											</mat-icon>
										</button>
										<button
											matTooltip="Move info property DOWN"
											(click)="move(1, index)"
											class="editButton"
										>
											<mat-icon class="notranslate iconSize18">
												arrow_downward
											</mat-icon>
										</button>
										<button
											matTooltip="REMOVE info property"
											(click)="remove(index)"
											class="editButton"
										>
											<mat-icon class="notranslate iconSize18">
												close
											</mat-icon>
										</button>
									</div>
								</td>
							</ng-container>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12 clear pb20 pt20">
		<app-raised-busy-button
			matTooltip="Click to add further properties to product views"
			[buttonLabel]="'Add properties'"
			*ngIf="
				hasValidSelectableOptions(filteredNoneAssetTypeAllocs$ | async);
				else noAdditionalProperties
			"
			(clicked)="this.addProductInfoAlloc()"
			[isDisabled]="emptyInfoPropertyAlreadyAvailable()"
		>
		</app-raised-busy-button>

		<ng-template #noAdditionalProperties>
			<div style="height: 60px">
				No properties available, please speak with your aHub administrator to
				resolve this
			</div>
		</ng-template>
	</div>
</form>
