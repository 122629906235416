<div class="col-12 col card-title">{{ title }}</div>
<div class="col-12 col pb20 pt20 align-center justify-center">
	<iframe
		width="560"
		height="315"
		title="{{ title }}"
		[src]="safeSrc"
		style="border: none; outline: none"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		allowfullscreen
	></iframe>
</div>
<div class="col-12 col align-end">
	<button mat-raised-button color="primary" (click)="onCloseClick()">
		{{ cancelButtonLabel }}
	</button>
</div>
