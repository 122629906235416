import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TreeStructure } from "../tree-structure.class";
import { TreeNodeVO } from "../tree.component";

@Component({
	selector: "app-reorder-dialog",
	templateUrl: "./reorder-dialog.component.html",
	styleUrls: ["./reorder-dialog.component.scss"],
})
export class ReorderDialogComponent implements OnInit {
	constructor(private readonly formBuilder: FormBuilder) {}

	/**
	 * Tree
	 */
	@Input()
	public tree: TreeStructure = null;

	/**
	 * VO
	 */
	@Input()
	public reorderingVO: ReorderingVO = null;

	currentPosition: number = 0;
	currentTreeNode: TreeNodeVO;
	siblings: TreeStructure[];
	treeWithKeys: Map<number, TreeStructure> = new Map();

	reorderingForm: FormGroup = this.buildForm();

	buildForm(): FormGroup {
		return this.formBuilder.group({
			targetNode: "",
			above: true,
		});
	}

	ngOnInit(): void {
		this.reorderingVO = {
			above: true,
			targetNode: this.reorderingForm.controls.targetNode.value,
		};

		this.currentTreeNode = this.tree.node;
		this.tree.parentTree.subTrees.map((v, k) => {
			let pos = k + 1;
			this.treeWithKeys.set(pos, v);
			if (v.id === this.currentTreeNode.id) {
				this.currentPosition = pos;
			}
		});

		this.siblings = this.tree.parentTree.subTrees.filter((subTree) => {
			return subTree.node !== this.currentTreeNode;
		});

		this.reorderingForm.controls.targetNode.valueChanges.subscribe((value) => {
			this.reorderingVO.targetNode = value;
		});
		this.reorderingForm.controls.above.valueChanges.subscribe((value) => {
			this.reorderingVO.above = value;
		});
	}
}

export interface ReorderingVO {
	above: boolean;
	targetNode: TreeStructure;
}
