<!-- List search header template -->
<mat-list class="col-12 oh">
	<div class="col-12 clear pt10 oh searchHeaderContainer">
		<div class="card-sub-title row flex-nowrap align-center">
			<div class="flex-1 align-center row oh flex-nowrap justify-start">
				<input
					type="checkbox"
					class="SideBarToggle"
					(click)="searchToggleClicked($event)"
				/>
				<span
					[ngStyle]="{ fontSize: titleFontSize }"
					class="search-list-title"
					>{{ listTitle }}</span
				>
				<div *ngIf="displayItemCount" class="flex-1 align-center">
					<span
						class="indexCount pl10"
						*ngIf="indexCount$ | async as indexCount"
						>({{ indexFilteredCount$ | async }}/{{ indexCount }})</span
					>
					<span class="indexCount pl10" *ngIf="!(indexCount$ | async)"
						>(0)</span
					>
				</div>
			</div>
			<div class="row align-center flex-nowrap">
				<app-knowledge-base-helper-icon
					style="margin-top: 8px"
					class="ml5"
					[knowledgeBasePagePath]="knowledgeBasePagePath"
				>
				</app-knowledge-base-helper-icon>
				<ng-content select=".inHeader" #injectedHeader></ng-content>
				<!-- This is the sort option and it's drop down.-->
				<div *ngIf="this.sortOptions.length > 0">
					<button
						mat-icon-button
						[matMenuTriggerFor]="sortMenu"
						*ngIf="!listControlForm.controls.showSearchFormControl"
					>
						<i
							aria-hidden="true"
							class="notranslate bi bi-filter"
							style="font-size: 24px"
						></i>
					</button>
					<mat-menu #sortMenu="matMenu">
						<button
							mat-menu-item
							(click)="
								this.sortPropertySet(
									sortOption.sortProperty,
									sortOption.invertSort
								)
							"
							*ngFor="let sortOption of this.sortOptions"
						>
							<span
								[ngClass]="
									activeSort == sortOption.sortProperty ? 'activeSort' : 'here'
								"
								>{{ sortOption.label }}</span
							>

							<i
								aria-hidden="true"
								class="notranslate bi bi-check sort-check"
								*ngIf="
									this.listControlForm.controls.sortPropertyName.value ===
									sortOption.sortProperty
								"
							></i>
						</button>
					</mat-menu>
				</div>
				<!-- This is the filter option and it's drop down.-->
				<div *ngIf="this.filterOptions.length > 0">
					<button
						mat-icon-button
						[matMenuTriggerFor]="filterMenu"
						*ngIf="!listControlForm.controls.showSearchFormControl"
					>
						<i
							aria-hidden="true"
							class="notranslate bi bi-funnel"
							style="font-size: 16px"
						></i>
					</button>
					<mat-menu #filterMenu="matMenu">
						<form [formGroup]="allFiltersForm" novalidate>
							<mat-slide-toggle
								class="toggleButton"
								formControlName="All"
								(click)="this.filterAllToggle($event)"
								*ngIf="this.displayAllFilters"
							>
								All
							</mat-slide-toggle>
						</form>
						<form
							[formGroup]="listFilterForm"
							class="flex-container column"
							novalidate
						>
							<mat-checkbox
								class="toggleButton"
								*ngFor="let filterOption of this.filterOptions"
								[formControlName]="filterOption.label"
								(click)="this.filterOptionClick($event)"
								(change)="this.filterOptionChangeHandler(filterOption)"
							>
								{{ filterOption.label }}
							</mat-checkbox>
						</form>
					</mat-menu>
				</div>
				<button
					mat-icon-button
					[matMenuTriggerFor]="menu"
					*ngIf="showButtonMenu"
				>
					<i
						aria-hidden="true"
						*ngIf="!(addButtonBusy | async) && !(deleteButtonBusy | async)"
						class="bi bi-three-dots-vertical"
						style="font-size: 19px"
					></i>
					<mat-spinner
						diameter="18"
						*ngIf="
							(addButtonBusy | async) ||
							(deleteButtonBusy | async) ||
							(menuBusy | async)
						"
					>
					</mat-spinner>
				</button>
				<mat-menu #menu="matMenu" #listButtonMenu>
					<div *ngIf="_showAdd" class="col-12">
						<button
							id="addButton"
							mat-menu-item
							(click)="addButtonClicked($event)"
							*visibleAs="addButtonVisibleAs"
						>
							<span>Add</span>
							<mat-spinner
								diameter="18"
								*ngIf="addButtonBusy | async"
							></mat-spinner>
						</button>
					</div>
					<div *ngIf="_showEdit && (indexCount$ | async)" class="col-12">
						<button
							id="editButton"
							mat-menu-item
							(click)="editButtonClicked()"
							*visibleAs="addButtonVisibleAs"
						>
							<span>Edit</span>
						</button>
					</div>
					<div *ngIf="_showDelete && (indexCount$ | async)" class="col-12">
						<button
							id="deleteButton"
							mat-menu-item
							(click)="deleteButtonClicked()"
							*visibleAs="addButtonVisibleAs"
						>
							<span>Delete</span>
						</button>
						<mat-spinner
							diameter="18"
							*ngIf="deleteButtonBusy | async"
						></mat-spinner>
					</div>
					<div *ngIf="_showDisable && (indexCount$ | async)">
						<button
							mat-menu-item
							(click)="disableButtonClicked()"
							*visibleAs="addButtonVisibleAs"
						>
							<span>Disable/Enable</span>
						</button>
					</div>
					<ng-content select=".headerMenu" #injectedMenuItem></ng-content>
				</mat-menu>
			</div>
		</div>
	</div>

	<!-- Header for the list  -->
	<div class="list-title-bar text-list-title pb10">
		<form [formGroup]="listControlForm" class="searchForm" novalidate>
			<div
				class="col-12 clear searchContainer"
				[ngClass]="_showSearch ? 'openList' : 'closedList'"
			>
				<button mat-mini-fab class="searchIcon">
					<mat-icon
						class="notranslate fabButton searchButton"
						style="color: #8687a7 !important"
						>search</mat-icon
					>
				</button>
				<input
					#searchFormInput
					class="searchInput"
					type="text"
					placeholder="Search"
					[formControlName]="listFilterFormSearchInputControlName"
				/>
			</div>
		</form>
	</div>
	<ng-content select=".underHeader"></ng-content>
</mat-list>
