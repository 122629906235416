<div class="dialog-container col-12 col full-height oh {{ wrapperCSSClass }} ">
	<h1
		*ngIf="title"
		matDialogTitle
		class="titleUnderline card-title"
		[innerHtml]="title"
	></h1>

	<div
		mat-dialog-content
		class="scroll-y flex-1"
		[ngClass]="matDialogContentClassName"
	>
		<app-component-dialog-injected-wrapper
			*ngIf="componentFactory != undefined"
			[componentFactory]="componentFactory"
			[dataVO]="initVO"
			[paramNameVO]="paramNameVO"
			[paramNameDialogValid]="paramNameDialogValid"
			[configurationParameter]="configurationParameter"
			[configurationObject]="configurationObject"
		>
		</app-component-dialog-injected-wrapper>
	</div>

	<div
		mat-dialog-actions
		class="col-12 pt10 bottom-buttons tar"
		*ngIf="cancelButtonLabel != undefined || confirmButtonLabel != undefined"
	>
		<button
			*ngIf="cancelButtonLabel != undefined"
			mat-raised-button
			color="primary"
			(click)="closeCancel()"
		>
			{{ cancelButtonLabel }}
		</button>

		<button
			*ngIf="confirmButtonLabel != undefined"
			mat-button
			mat-raised-button
			color="primary"
			[disabled]="!dialogValid"
			style="margin-left: 8px"
			(click)="closeOK()"
		>
			{{ confirmButtonLabel }}
		</button>
	</div>
	<div class="top-close-icon" (click)="closeCancel()">
		<button
			*ngIf="confirmButtonLabel != undefined"
			mat-button
			mat-raised-button
			color="primary"
			[disabled]="!dialogValid"
			style="margin: 5px"
			(click)="closeOK()"
		>
			{{ confirmButtonLabel }}
		</button>
	</div>
</div>
