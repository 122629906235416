import { Router } from "@angular/router";
import {
	ServiceOptions,
	ServiceSession,
} from "@harksolutions/ahub-web-services";
import { AppRoutingNavigation } from "app/app-routing-navigation";
import { AHubService } from "app/services/ahub/ahub.service";
import { RequestActionStatusEnum } from "app/valueObjects/app/request-action-status.app.enum";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import {
	catchError,
	concatMap,
	filter,
	mergeMap,
	switchMap,
} from "rxjs/operators";
import { AHubActions } from "../actions/ahub.actions";
import { AppActions } from "../actions/app.actions";
import {
	ActionString,
	ActionStringArray,
} from "../actions/types/common.action-types";
import { ActionWork } from "../actions/types/work.action-types";
import { ViewActions } from "../actions/view.actions";
import { AHubBaseEpic } from "./ahub-base.epics";
import { Epic } from "./epic";

/**
 * Class for the View epic functions
 */
export class AHubSessionEpics extends AHubBaseEpic implements Epic {
	serviceSession: ServiceSession = undefined;

	constructor(
		public readonly aHubService: AHubService,
		private readonly router: Router
	) {
		super(aHubService);

		const serviceOptions = new ServiceOptions(
			environment.aHubApi.domain,
			environment.aHubApi.basePath
		);

		serviceOptions.logRequest = true;

		//Create our service library with the base parameters for our requests
		this.serviceSession = new ServiceSession(serviceOptions);
	}

	private readonly NOOP_ACTION: ActionWork = { actionId: -1, type: "NOOP" };

	epicMethods(): any[] {
		return [
			this.loginToken,
			this.logUserValidate,
			this.loginUrl,
			this.loginIdentifierProviderTokenValidate,
			this.loginIdentifierProviderTokenRegister,
			this.loginTokenAuthorize,
			this.sessionToken,
			this.userSessionNewFromToken,
			this.sessionInfo,
			this.sessionInvalidate,
			// this.userSessionCredentialsFromEmailPasswordAndToken,
			this.userIdBySession,
		];
	}

	loginToken = (action$: Observable<ActionString>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.LOGIN_TOKEN_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.loginToken(this.reqOptSigned(), action.string),
					action,
					AppActions.loginTokenSet
				)
			)
		);

	logUserValidate = (action$: Observable<ActionStringArray>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.LOGIN_USER_VALIDATE),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.logUserValidate(
						this.reqOptSigned(),
						action.strings[0],
						action.strings[1],
						action.strings[2]
					),
					action,
					ViewActions.loginTokenValidatedSet,
					(error) => ViewActions.loginTokenErrorSet(error.message)
				)
			)
		);

	loginUrl = (action$: Observable<ActionString>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.LOGIN_URL_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.loginUrl(this.reqOptSigned(), action.string),
					action,
					ViewActions.loginURLSet
				)
			)
		);

	loginIdentifierProviderTokenValidate = (
		action$: Observable<ActionStringArray>
	) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === AHubActions.LOGIN_IDENTIFIER_PROVIDER_TOKEN_VALIDATE
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.loginIdentifierProviderTokenValidate(
						this.reqOptSigned(),
						action.strings[0],
						action.strings[1]
					),
					action,
					ViewActions.loginTokenValidatedSet,
					(err) =>
						AHubActions.loginIdentifierProviderTokenRegister(
							action.strings[0],
							action.strings[1]
						)
				)
			)
		);

	loginTokenAuthorize = (action$: Observable<ActionString>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.LOGIN_TOKEN_AUTHORIZE),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.loginTokenAuthorize(
						this.reqOptSigned(),
						action.string
					),
					action,
					(data) => this.NOOP_ACTION
				)
			)
		);

	loginIdentifierProviderTokenRegister = (
		action$: Observable<ActionStringArray>
	) =>
		action$.pipe(
			filter(
				({ type }) =>
					type === AHubActions.LOGIN_IDENTIFIER_PROVIDER_TOKEN_REGISTER
			),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.loginIdentifierProviderTokenRegister(
						this.reqOptSigned(),
						action.strings[0],
						action.strings[1]
					),
					action,
					ViewActions.loginTokenValidatedSet
				)
			)
		);

	sessionToken = (action$: Observable<ActionString>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.SESSION_TOKEN_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.sessionToken(this.reqOptSigned(), action.string),
					action,
					AppActions.sessionTokenSet
				)
			),
			catchError((error: any, caught: Observable<ActionWork>) => {
				// Navigate to logout.
				AppRoutingNavigation.navigateLogout(this.router);

				// Clear the session data if the session has expired or refused. or genrally broken.
				return of(
					AppActions.sessionrequestActionStatusAppend(
						this.actionStatusCreateError(error, {
							actionId: 0,
							sentTime: new Date(),
							workflowReference: undefined,
							status: RequestActionStatusEnum.ERROR,
							fault: true,
							error: "You have been logged out. Please log back in.",
							errorCode: 999,
							upload: undefined,
						})
					)
				);
			})
		);

	userSessionNewFromToken = (action$: Observable<ActionString>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.USER_SESSION_NEW_FROM_TOKEN),
			// this.tapLogAction(),
			switchMap((action) =>
				this.serviceSession.userSessionNewFromToken(
					this.reqOptSigned(),
					action.string
				)
			),
			concatMap((result) =>
				of(
					AppActions.sessionUserSessionCredentialsSet(result),
					AHubActions.userIdBySessionFetch(result)
				)
			)
		);

	sessionInfo = (action$: Observable<ActionWork>) =>
		action$.pipe(
			filter(({ type }) => type === AppActions.SESSION_INFO_FETCH),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.sessionInfo(this.reqOptSigned()),
					action,
					AppActions.sessionUserSessionCredentialsSet
				)
			)
		);

	sessionInvalidate = (action$: Observable<ActionWork>) =>
		action$.pipe(
			filter(({ type }) => type === AppActions.SESSION_INVALIDATE),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceSession.sessionInvalidate(
						this.reqOptSigned(),
						this.getUserId()
					),
					action,
					(data) => this.NOOP_ACTION
				)
			)
		);

	userIdBySession = (action$: Observable<ActionWork>) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.USER_ID_BY_SESSION_FETCH),
			switchMap((action) =>
				this.serviceSession.userIdBySession(this.reqOptSigned())
			),
			concatMap((result) =>
				of(
					AppActions.sessionUserIdSet(result),
					AHubActions.sessionUsersByIdsFetch([result]),
					AHubActions.userClientIndexsFetch(result)
				)
			),
			catchError((error: any, caught: Observable<ActionWork>) => {
				// Navigate to logout.
				AppRoutingNavigation.navigateLogout(this.router);

				// Clear the session data if the session has expired or refused. or genrally broken.
				return of(
					AppActions.sessionrequestActionStatusAppend(
						this.actionStatusCreateError(error, {
							actionId: 0,
							sentTime: new Date(),
							workflowReference: undefined,
							status: RequestActionStatusEnum.ERROR,
							fault: true,
							error: "Unable to get user from session info.",
							errorCode: 999,
							upload: undefined,
						})
					)
				);
			})
		);
}
