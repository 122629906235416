<div class="col-12 col" style="max-width: 500px" *ngIf="multiAddDialogVO">
	<div class="card-title titleUnderline" *ngIf="title">
		{{ title }}
	</div>

	<app-multiple-add-input
		[list]="multiAddDialogVO?.data"
		[inputForm]="addDialogForm"
		[inputLabel]="multiAddDialogVO.inputOptions.name"
		[content]="content"
		[allowDragSort]="true"
		[listChanged$]="listChanged$"
	>
	</app-multiple-add-input>
	<div class="col-12 justify-end align-end pt10">
		<button
			mat-raised-button
			color="primary"
			style="margin-right: 10px"
			(click)="onCloseClick()"
		>
			{{ cancelButtonLabel }}
		</button>
		<button
			mat-raised-button
			color="primary"
			style="margin-right: 10px"
			[disabled]="!(this.listChanged$ | async)"
			(click)="onSaveClick()"
		>
			{{ confirmButtonLabel }}
		</button>
	</div>
</div>
