<div
	style="display: flex; flex-direction: row; align-items: center; height: 100%"
>
	<mat-checkbox [(ngModel)]="state" *ngIf="showCheckbox"></mat-checkbox>

	<!-- Clickable layer to prevent direct control of the checkbox component. It also has a larger
    click box making it slightly easier to control -->
	<div
		style="position: absolute; z-index: 100; width: 20px; height: 20px"
		(click)="startEdit()"
	></div>
</div>
