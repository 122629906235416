<div
	class="row flex-nowrap clickable fb-center"
	[matMenuTriggerFor]="userMenu"
	*ngIf="sessionUser$ | async"
	[ngClass]="{ blink_me: (sessionAboutToExpire$ | async) }"
	style="background-color: #2a3041"
>
	<i
		aria-hidden="true"
		class="bi bi-person-circle"
		style="font-size: 24px; color: #7a839c; margin-left: 2px"
	></i>
	<span class="flex" style="padding-left: 23px; color: #fff !important">{{
		(sessionUser$ | async)?.firstName
	}}</span>
</div>

<mat-menu #userMenu="matMenu" x-position="before" y-position="below">
	<button mat-menu-item (click)="logout()">
		<mat-icon>power_settings_new</mat-icon>Logout
	</button>
	<button mat-menu-item (click)="logoutAllDevices()">
		<mat-icon>settings_power</mat-icon>Logout (all devices)
	</button>
	<button
		*ngIf="sessionAboutToExpire$ | async"
		mat-menu-item
		(click)="logout()"
	>
		<mat-icon>cached</mat-icon>Refresh Session
	</button>
	<button mat-menu-item (click)="openUserProfileDialog()">
		<mat-icon>face</mat-icon>Profile
	</button>
</mat-menu>
