<div class="col-12 col" (click)="moreInfoToggle()">
	<div div class="card-sub-title row flex-nowrap">
		<div class="subTitlePadding flex-1">
			{{ this.title }}
			<span
				class="indexCount"
				*ngIf="this.clientLogEntryCount$ | async as indexCount"
				>({{ this.clientLogEntriesFilteredCount$ | async }}/{{
					indexCount
				}})</span
			>

			<span class="indexCount" *ngIf="!(this.clientLogEntryCount$ | async)"
				>(0)</span
			>
		</div>

		<div class="row align-center flex-nowrap">
			<button mat-icon-button [matMenuTriggerFor]="filterMenu">
				<mat-icon svgIcon="hark_filter" class="iconSize25"></mat-icon>
			</button>

			<button mat-icon-button [matMenuTriggerFor]="menu">
				<i
					aria-hidden="true"
					class="bi bi-three-dots-vertical"
					style="font-size: 19px; color: #8687a7"
				></i>
			</button>
		</div>
	</div>

	<mat-menu #menu="matMenu">
		<button class="menuButton" mat-menu-item (click)="this.downloadHandler()">
			<span>Download</span>
		</button>
	</mat-menu>

	<mat-menu #filterMenu="matMenu">
		<div class="menu">
			<div class="menuTitle flex-1">Groups</div>

			<form [formGroup]="allGroupsFiltersForm" novalidate>
				<mat-slide-toggle
					class="toggleButton"
					formControlName="All"
					(click)="this.filterAllGroupsToggle($event)"
				>
					All
				</mat-slide-toggle>
			</form>
			<form
				[formGroup]="groupsFilterForm"
				class="flex-container column"
				novalidate
			>
				<mat-checkbox
					class="toggleButton"
					*ngFor="let logGroup of this.clientLogEntryGroupNames"
					[formControlName]="logGroup"
					(click)="this.filterOptionClick($event)"
					(change)="this.filterOptionChangeHandler()"
				>
					{{ logGroup | titleCase }}
				</mat-checkbox>
			</form>

			<div class="menuTitle flex-1">Types</div>

			<form [formGroup]="allTypesFiltersForm" novalidate>
				<mat-slide-toggle
					class="toggleButton"
					formControlName="All"
					(click)="this.filterAllTypesToggle($event)"
				>
					All
				</mat-slide-toggle>
			</form>
			<form
				[formGroup]="typesFilterForm"
				class="flex-container column"
				novalidate
			>
				<mat-checkbox
					class="toggleButton"
					*ngFor="let logType of this.logTypes"
					[formControlName]="logType"
					(click)="this.filterOptionClick($event)"
					(change)="this.filterOptionChangeHandler()"
				>
					{{ logTypeFilterLabel(logType) }}
				</mat-checkbox>
			</form>
		</div>
	</mat-menu>

	<div class="col-12 row">
		<form [formGroup]="searchForm" class="searchForm flex-1" novalidate>
			<div class="col-12 clear searchContainer openList">
				<button mat-mini-fab>
					<mat-icon
						class="notranslate fabButton searchButton"
						style="color: #8687a7 !important"
						>search
					</mat-icon>
				</button>
				<input
					#searchFormInput
					class="searchInput"
					type="text"
					placeholder="Search"
					formControlName="searchFormControl"
				/>
			</div>
		</form>
	</div>

	<div class="col-12 row logContainer oh">
		<app-infinity-scroller
			#infinityScroller
			style="height: 550px; width: 100%"
			[dataArray]="this.clientLogEntriesFiltered$"
			[componentFactory]="this.componentFactory"
			componentVOParamName="entry"
		>
		</app-infinity-scroller>
	</div>
</div>
