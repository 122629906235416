<div class="col align-center col-12 full-height">
	<div class="col col-12 align-center flex-1 oh relative">
		<app-three-d-renderer
			[params]="this.renderParams"
			style="width: 9500px; height: 700px"
		></app-three-d-renderer>
	</div>
	<div class="col-12 col pb10 pt10">
		<div>
			<span class="bold notranslate">{{ dialogParams.propertyName }}</span>
		</div>
		<div class="flex align-center">
			<span
				class="sectionIconSmall notranslate"
				[style.background]="dialogParams.sectionColour"
			></span>
			<span class="bold notranslate">{{ dialogParams.sectionName }}</span>
		</div>
	</div>
</div>
