<div class="dialog-container-shrink">
	<app-client-log
		[clientLogId]="this.clientLogId"
		[title]="this.title"
		class="col-12"
	></app-client-log>

	<div mat-dialog-actions class="fb-right" style="margin-top: 16px">
		<button
			mat-raised-button
			color="primary"
			style="margin-left: 8px"
			(click)="dialogRef.close(true)"
		>
			{{ confirmButtonLabel }}
		</button>
	</div>
</div>
