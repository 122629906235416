import { aViewClientLogos } from "./../../store/selector/aview.selector";
import { AViewActions } from "app/store/actions/aview.actions";
import { animate, style, transition, trigger } from "@angular/animations";
import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutingNavigation } from "app/app-routing-navigation";
import { AHubActions } from "app/store/actions/ahub.actions";
import { AppActions } from "app/store/actions/app.actions";
import { ListUtil } from "app/store/list.util";
import { aHubStatePermanentUserHasAViews } from "app/store/selector/ahub/ahub-permanent.selector";
import { aHubStateTemporaryClientLogos } from "app/store/selector/ahub/ahub-temporary.selector";
import {
	currentToolId,
	sessionClientConfig,
	sessionClientId,
} from "app/store/selector/app.selector";
import { aViewFirstComponentPublicationId } from "app/store/selector/aview.selector";
import { selectedRouteView } from "app/store/selector/view/view-routes.selector";
import { ViewAvailableRoutesStream } from "app/store/stream/view-available-routes.stream";
import { ClientConfigurationAHubVO } from "app/valueObjects/ahub/accounts/client-configuration.ahub.vo";
import { RouteViewVO } from "app/valueObjects/view/route.view.vo";
import { ToolIDEnum } from "app/valueObjects/view/tool-id.view.enum";
import { version } from "assets/jenkins_consts";
import { environment } from "environments/environment";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { combineLatest, Observable, timer } from "rxjs";
import {
	delay,
	filter,
	map,
	takeUntil,
	distinctUntilChanged,
	publishReplay,
	refCount,
} from "rxjs/operators";
import { StoreAccess } from "store/store-access";
import { DialogService } from "../common/dialogs/dialog.service";
import { Utils } from "../common/utils";
import { ExportDistributionAHubVO } from "app/valueObjects/ahub/accounts/export-distribution.ahub.vo";
import { viewSelectedExportDistribution } from "app/store/selector/view/view-exports.selector";

@Component({
	selector: "app-sidenav",
	templateUrl: "./sidenav.component.html",
	styleUrls: ["./sidenav.component.scss"],
	animations: [
		trigger("newVersionAnimation", [
			transition(":enter", [
				style({ transform: "translateY(-100%)" }),
				animate("500ms ease-in", style({ transform: "translateY(0%)" })),
			]),
			transition(":leave", [
				animate("500ms ease-in", style({ transform: "translateY(-100%)" })),
			]),
		]),
	],
})
@Hark()
export class SidenavComponent implements OnInit, OnDestroy {
	showNewVersionTab = false;

	currentClientConfig$: Observable<ClientConfigurationAHubVO> =
		StoreAccess.dataGetObvs(sessionClientConfig);

	publicationId$: Observable<number> = StoreAccess.dataGetObvs(
		aViewFirstComponentPublicationId
	);

	/**
	 * This is the pre-signed URL for the client logo if we can find one.
	 */
	clientLogo$: Observable<string>;
	clientLogo: any = null;

	/**
	 * Get an observable list of the routes
	 */
	routes$ = ViewAvailableRoutesStream.availableRoutes().pipe(delay(0));

	exportDistribution$ = StoreAccess.dataGetObvs(viewSelectedExportDistribution);

	/**
	 * Get the current tool id.
	 */
	currentToolId$: Observable<ToolIDEnum> = StoreAccess.dataGetObvs(
		currentToolId
	).pipe(delay(0));

	/**
	 * The number of milliseconds between each call to check if the user has aViews or not.
	 */
	private readonly USER_HAS_AVIEW_TIMEOUT = 10 * 60 * 1000;

	/**
	 * Is the current tool the aHub?
	 */
	currentToolIsAHub$: Observable<boolean> = this.currentToolId$.pipe(
		map((currentToolId) => currentToolId === ToolIDEnum.AHUB)
	);

	/**
	 * Is the current tool the aView?
	 */
	currentToolIsAView$: Observable<boolean> = this.currentToolId$.pipe(
		map((currentToolId) => currentToolId === ToolIDEnum.AVIEW)
	);

	/**
	 * Is the current tool the aView Pro?
	 */
	currentToolIsAViewPro$: Observable<boolean> = this.currentToolId$.pipe(
		map((currentToolId) => {
			return currentToolId === ToolIDEnum.AVIEW_PRO;
		})
	);

	/**
	 * Does the user have access to aView publications?
	 */
	userHasAViewPublications$: Observable<boolean> = StoreAccess.dataGetObvs(
		aHubStatePermanentUserHasAViews
	).pipe(delay(0));

	routeViewSelected$: Observable<RouteViewVO> =
		StoreAccess.dataGetObvs(selectedRouteView);

	titlePath = "";

	title = "";

	knowledgeBasePagePath: string;
	showKowledgeBaseIcon: boolean;

	version: string = version;
	newVersionNumber: string;

	environment = environment;

	constructor(
		private readonly http: HttpClient,
		private readonly router: Router,
		private readonly dialogueService: DialogService
	) {}

	// Navigation Toggle
	active = false;
	toggleNav() {
		this.active = !this.active;
	}

	closeNav() {
		this.active = false;
	}

	ngOnInit() {
		// StoreAccess.dataGetObvs(sessionClientId).pipe(
		//   Utils.isNotNullOrUndefined(),
		//   filter(sessionClientId => sessionClientId > 0)
		// ).subscribe(sessionClientId => {
		//   console.log("TCL: SidenavComponent -> ngOnInit -> sessionClientId", sessionClientId)
		//   StoreAccess.dispatch(AppActions.sessionClientConfigFetch())
		// })

		// Set up a timer that runs straight away and then every x milliseconds to
		// see if the user is attached to any aViews.
		timer(0, this.USER_HAS_AVIEW_TIMEOUT)
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((timer) => {
				// Make the call to update whether the user has aViews or not.
				StoreAccess.dispatch(AHubActions.userHasAViewsFetch(), true);
			});

		//Are we running on something other than a LOCAL deployment?
		if (environment && environment.name != "LOCAL") {
			//If so we want to check to see if there is a new version avalible to download
			setInterval(() => {
				//Get the version from our relative location
				this.http
					.get("version.txt", {
						responseType: "text",
					})
					.pipe(takeUntil(componentDestroyStream(this)))
					.subscribe(
						(response) => {
							//Grab the version
							this.newVersionNumber = response.valueOf().toString().trim();
							if (
								/^\d+\.\d+\.\d+$/.test(this.newVersionNumber) ||
								/Revision: \d+/.test(this.newVersionNumber)
							) {
								//If the version is different to the new version number then we should display it
								this.showNewVersionTab =
									version.toString().trim() != this.newVersionNumber;
							}
						},
						(error) => {}
					);
			}, 120000);
		}

		// Lets build a page title from the selected route view VO
		this.routeViewSelected$
			.pipe(
				Utils.isNotNullOrUndefined(),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((routerViewSelected) => {
				// Clear previous values prior to figuring out where we are now
				this.title = "";
				this.titlePath = "";
				this.knowledgeBasePagePath = routerViewSelected.knowledgeBasePagePath;
				this.showKowledgeBaseIcon = routerViewSelected.showKowledgeBaseIcon;

				// Get the page title from the current routeView
				const pageTitleArray = routerViewSelected.pageTitle.split(">");
				this.title = pageTitleArray.pop();
				if (pageTitleArray.join("/") !== "") {
					this.titlePath = pageTitleArray.join("/") + " /";
				}
			});

		this.publicationId$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
			)
			.subscribe((publicationId) => {
				if (publicationId && publicationId !== -1)
					StoreAccess.dispatch(AViewActions.exportClientLogoGet(publicationId));
			});

		this.clientLogo$ = combineLatest([
			this.publicationId$.pipe(
				takeUntil(componentDestroyStream(this)),
				distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
			),
			StoreAccess.dataGetObvs(aViewClientLogos),
		]).pipe(
			map(([publicationId, clientLogos]) => {
				const item = ListUtil.listDataItemGet(clientLogos, publicationId);
				return item && item.item ? item.item.signedUrl : null;
			}),
			publishReplay(1),
			refCount()
		);

		this.clientLogo$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((clientLogoUrl) => {
				this.clientLogo = clientLogoUrl;
			});
	}

	/**
	 * This handler is called whent he user clicks on the switch to aView tool button.
	 */
	toolSwitchAView() {
		// Stop here if we aren't changing tool id.
		if (StoreAccess.dataGet(currentToolId) === ToolIDEnum.AVIEW) {
			return;
		}

		// Switch to the aView screen.
		AppRoutingNavigation.navigateAView(this.router);

		// Make the call to switch to the aView tool.
		StoreAccess.dispatch(AppActions.currentToolIdSet(ToolIDEnum.AVIEW));
	}

	/**
	 * This handler is called whent he user clicks on the switch to aHub tool button.
	 */
	toolSwitchAHub() {
		// Stop here if we aren't changing tool id.
		if (StoreAccess.dataGet(currentToolId) === ToolIDEnum.AHUB) {
			return;
		}

		// Take the user back to the welcome screen.
		AppRoutingNavigation.navigateWelcome(this.router, false);

		// Make the call to switch to the aHub tool.
		StoreAccess.dispatch(AppActions.currentToolIdSet(ToolIDEnum.AHUB));
	}

	openTutorialWindow() {
		this.dialogueService.videoEmbedDialogOpen(
			"Welcome to aView",
			"https://www.youtube.com/embed/ICCnVTm8aIY?autoplay=1",
			"Close"
		);
	}

	openaViewProTutorialWindow() {
		this.dialogueService.videoEmbedDialogOpen(
			"Welcome to aView Pro",
			"https://www.youtube.com/embed/N1AwtCBodE0?autoplay=1",
			"Close"
		);
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	getNewVersion() {
		//Hide the tab
		this.showNewVersionTab = false;

		//Reset the path name to empty so that we are forced back to the root
		location.href = "/";
	}
}
