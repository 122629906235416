<div class="container flex">
	<button
		mat-raised-button
		color="primary"
		[disabled]="this.isDisabled || this.isBusy"
		class="tac"
		style="width: 100%; position: relative"
		(click)="this.actionButtonClickHandler()"
	>
		<i
			aria-hidden="true"
			class="notranslate bi {{ icon }} mr10"
			*ngIf="icon"
		></i>
		<span>{{ this.buttonLabel }}</span>

		<mat-progress-spinner
			*ngIf="isBusy"
			class="buttonSpinner ml10"
			mode="indeterminate"
			color="primary"
			diameter="16"
			style="display: inline-block; margin-top: -2px"
		></mat-progress-spinner>
	</button>
</div>
