<div style="display: flex; align-items: center; height: 100%">
	<span
		class="notranslate"
		*ngIf="!this.iconLeftOfLabel && this.iconLabel != undefined"
		>{{ this.iconLabel }}</span
	>
	<mat-icon
		style="margin: 0px 4px"
		class="notranslate"
		[ngStyle]="{ color: this.iconColour }"
		>{{ this.iconName }}
	</mat-icon>
	<span
		class="notranslate"
		*ngIf="this.iconLeftOfLabel && this.iconLabel != undefined"
		>{{ this.iconLabel }}</span
	>
</div>
