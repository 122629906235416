<div style="display: flex; flex-direction: column; align-items: center">
	<span class="notranslate">{{
		dialogParams?.chapters[this.chapterIndex]?.chapterLabel
	}}</span>

	<div class="flex row">
		<button
			mat-icon-button
			[disabled]="this.chapterIndex <= 0"
			[matTooltip]="'View previous chapter'"
			(click)="this.nextChapter(); $event.stopPropagation()"
		>
			<mat-icon class="notranslate largeIcon">keyboard_arrow_left</mat-icon>
		</button>

		<button
			mat-icon-button
			[disabled]="!(this.chapterIndex < dialogParams?.chapters?.length - 1)"
			[matTooltip]="'View next chapter'"
			(click)="this.nextChapter(); $event.stopPropagation()"
		>
			<mat-icon class="notranslate largeIcon">keyboard_arrow_right</mat-icon>
		</button>
	</div>

	<div class="assetContainer">
		<img
			#images
			*ngFor="let assetImageUrl of assetUrlsCurrentChapter"
			class="flickbookAsset"
			[src]="assetImageUrl"
			alt=""
		/>
	</div>

	<div class="col-12 col pb10 pt10">
		<div>
			<span class="bold notranslate">{{ dialogParams.propertyName }}</span>
		</div>
		<div class="flex align-center">
			<span
				class="sectionIconSmall notranslate"
				[style.background]="dialogParams.sectionColour"
			></span>
			<span class="bold notranslate">{{ dialogParams.sectionName }}</span>
		</div>
	</div>
</div>
