<div class="col-12 card-title titleUnderline row space-between align-center">
	<span>{{ title }}</span>
	<button
		*ngIf="
			this.enableResetButton &&
				userLibraryViewConfigMapStorageByClientIdAndDataSetId$ | async
		"
		(click)="resetUserLibraryViewConfig()"
		mat-raised-button
		matTooltip="Deletes user defined content view config and restores any config set in the Dataset content view config"
		color="primary"
	>
		Restore Default Configuration
	</button>
</div>

<!-- -->
<div class="col-12 flex-1 oh row pt20">
	<app-list-tree
		[sourceList$]="classIndex$"
		[rootNodeLabel]="rootNodeLabel"
		(selectedItem)="classSelectedHandler($event)"
		class="col stack250 scroll-y full-height"
	>
	</app-list-tree>
	<app-library-view-class-config
		[selectedClassLibraryViewConfig$]="selectedClassLibraryViewConfig$"
		[allocs$]="allocs$"
		[selectedClass$]="selectedClass$"
		(configChangeHandler)="configChangeHandler($event)"
		[classIndexes]="classIndex$ | async"
		class="flex-1 pl20 full-height oh"
	>
	</app-library-view-class-config>
</div>
<!-- -->

<div
	mat-dialog-actions
	class="col-12 pt10 tar"
	*ngIf="cancelButtonLabel != undefined || confirmButtonLabel != undefined"
>
	<button
		*ngIf="cancelButtonLabel != undefined"
		mat-raised-button
		color="primary"
		(click)="onCloseClick()"
	>
		{{ cancelButtonLabel }}
	</button>
	<button
		*ngIf="confirmButtonLabel != undefined"
		mat-button
		mat-raised-button
		color="primary"
		style="margin-left: 8px"
		(click)="onSaveClick()"
	>
		{{ confirmButtonLabel }}
	</button>
</div>
