<div #card class="col-12 col cardContainer full-height">
	<div
		class="full-width justify-center align-center cardImageContainer"
		(click)="productViewClick()"
	>
		<div
			*ngIf="!mainImageAsset"
			class="noImage"
			[matTooltip]="'Click to view product'"
		></div>

		<app-product-asset-view
			[tooltip]="'Click to view product'"
			*ngIf="mainImageAsset"
			[productAssetViewParam]="mainImageAsset"
			(hovering)="hovering($event)"
			class="asset-view"
			style="width: 100%; height: 100%"
		>
		</app-product-asset-view>
	</div>
	<div class="pt10 pb10 pl15 pr15 col justify-center">
		<div
			class="col-12 bold"
			[ngClass]="{ smallFont: !(productViewData.largeGrid$ | async) }"
			*ngIf="productIdentifier.length > 45"
		>
			{{ productIdentifier | slice: 0:45 }}...
		</div>

		<div
			class="col-12 bold"
			[ngClass]="{ smallFont: !(productViewData.largeGrid$ | async) }"
			*ngIf="productIdentifier.length <= 45"
		>
			{{ productIdentifier }}
		</div>
	</div>
	<div class="col-12 flex-1" *ngIf="productViewData.largeGrid$ | async">
		<table
			mat-table
			*ngIf="productInfoProperties && productInfoProperties.length > 0"
			[dataSource]="productInfoProperties"
			matSortActive="date"
			aria-hidden="true"
			class="infoTable"
		>
			<ng-container matColumnDef="propertyName">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<td
					mat-cell
					*matCellDef="let element"
					style="width: 30%; vertical-align: top"
				>
					<div class="col-12 col pb10 pt10">
						<div
							style="
								overflow: hidden;
								text-overflow: ellipsis;
								display: inline-block;
							"
							class="col-12 bold"
						>
							{{ element.propertyAlloc?.property.label }}
						</div>
					</div>
				</td>
			</ng-container>

			<ng-container matColumnDef="propertyValue">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<td mat-cell *matCellDef="let element" style="vertical-align: top">
					<div class="pb10 pt10 col-12 row">
						<!-- Text Lists -->
						<div
							*ngIf="
								element.propertyAlloc?.property.typeReference == 'TEXTLIST' &&
								element.propertyValue
							"
						>
							<ul class="ml15">
								<li *ngIf="stringToJson(element.propertyValue)[0]">
									{{ stringToJson(element.propertyValue)[0] }}
								</li>
								<li *ngIf="stringToJson(element.propertyValue)[1]">
									{{ stringToJson(element.propertyValue)[1] }}
									<span *ngIf="stringToJson(element.propertyValue).length > 2"
										>...</span
									>
								</li>
							</ul>
						</div>
						<!-- Text Lists -->

						<div
							*ngIf="
								element.propertyValue && element?.propertyAlloc?.property.matrix
							"
						>
							<mat-icon style="margin: 0px !important; cursor: pointer">
								grid_on</mat-icon
							>
						</div>
						<div
							*ngIf="
								element.propertyValue &&
								!element?.propertyAlloc?.property.matrix &&
								element.propertyAlloc?.property.typeReference !== 'TEXTLIST'
							"
						>
							<div *ngIf="element.propertyValue?.length > 65">
								{{ element.propertyValue | slice: 0:65 }}...
							</div>
							<div
								*ngIf="element.propertyValue?.length <= 65"
								style="white-space: normal; word-wrap: break-word"
							>
								{{ element.propertyValue }}
							</div>
						</div>
					</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
	</div>
</div>
