<div class="col-12 col">
	<div class="col-12 col card p20">
		<div
			class="flex-container tac column banner fb-center col-12"
			style="margin-bottom: 0px"
		>
			<i aria-hidden="true" class="bi bi-person-fill"> </i>
			<mat-card-title class="pb25" *ngIf="!(this.user$ | async)">
				No User Selected
			</mat-card-title>
			<mat-card-title class="pb25 bold">
				{{ (this.user$ | async)?.firstName }}&nbsp;{{
					(this.user$ | async)?.lastName
				}}
			</mat-card-title>
			<div *visibleAs="systemUser" class="moreDraw">
				<div
					*ngIf="(temporaryData$ | async) || (metaData$ | async)"
					(click)="showExtraInformation = !showExtraInformation"
				>
					<i
						aria-hidden="true"
						class="notranslate bi bi bi-arrow-right-circle"
						*ngIf="!showExtraInformation"
						matTooltip="more information"
					></i>
					<i
						aria-hidden="true"
						*ngIf="showExtraInformation"
						class="bi bi bi-arrow-left-circle"
						matTooltip="close"
					></i>
				</div>
			</div>
		</div>
		<div class="userProfileSection col-12">
			<mat-drawer-container class="full-height full-width">
				<mat-drawer
					#drawer
					[opened]="showExtraInformation"
					mode="over"
					position="end"
					class="mat-drawer-scrollable col-12"
				>
					<div class="col flex-1 m10">
						<mat-form-field floatingPlaceholder="true" appearance="outline">
							<mat-label>Session Id</mat-label>
							<input
								matInput
								placeholder="sessionId"
								[value]="(user$ | async)?.sessionId"
								disabled
							/>
						</mat-form-field>
						<mat-form-field
							*ngIf="temporaryData$ | async"
							floatingPlaceholder="true"
							appearance="outline"
						>
							<mat-label>Temporary Data</mat-label>
							<textarea
								matInput
								[matTextareaAutosize]="true"
								placeholder="temporaryData"
								[value]="temporaryData$ | async"
								disabled
							></textarea>
						</mat-form-field>
						<mat-form-field floatingPlaceholder="true" appearance="outline">
							<mat-label>Verification Date</mat-label>
							<input
								matInput
								placeholder="verificationDate"
								[value]="
									(user$ | async)?.verificationDate
										| date: 'd MMM yyyy HH:mm:ss'
								"
								disabled
							/>
						</mat-form-field>
					</div>
					<div *ngIf="(metaData$ | async)?.length > 0">
						<span class="mt10 mb10 spanText">Meta Data</span>
						<div
							*ngFor="let metaData of metaData$ | async"
							class="col stack flex-1 m10"
						>
							<div class="row col-12 stack">
								<mat-form-field
									floatingPlaceholder="true"
									appearance="outline"
									class="col-4 pr5"
								>
									<mat-label>Key</mat-label>
									<input
										matInput
										placeholder="key"
										[value]="metaData.key"
										disabled
									/>
								</mat-form-field>
								<mat-form-field
									floatingPlaceholder="true"
									appearance="outline"
									class="col-4"
								>
									<mat-label>Value</mat-label>
									<input
										matInput
										placeholder="value"
										[value]="metaData.value"
										disabled
									/>
								</mat-form-field>
								<mat-form-field
									floatingPlaceholder="true"
									appearance="outline"
									class="col-4 pl5"
								>
									<mat-label>Modified Date</mat-label>
									<input
										matInput
										placeholder="modifiedDate"
										[value]="
											metaData.modifiedDate | date: 'd MMM yyyy HH:mm:ss'
										"
										disabled
									/>
								</mat-form-field>
							</div>
						</div>
					</div>
				</mat-drawer>
				<div class="col-12 clear row flex-1">
					<div class="row flex-1">
						<div class="flex-1 stack p10">
							<form
								[formGroup]="userForm"
								(keydown.enter)="$event.preventDefault()"
							>
								<div class="col-12 clear">
									<mat-form-field
										floatingPlaceholder="true"
										class="col-12 clear"
										appearance="outline"
									>
										<mat-label>First Name</mat-label>
										<input
											matInput
											placeholder="First Name"
											autocomplete="off"
											formControlName="firstName"
										/>
										<mat-error style="font-size: 12px">{{
											formErrors.firstName
										}}</mat-error>
									</mat-form-field>
								</div>
								<div class="col-12 clear">
									<mat-form-field
										floatingPlaceholder="true"
										class="col-12 clear"
										appearance="outline"
									>
										<mat-label>Last Name</mat-label>
										<input
											matInput
											placeholder="Last Name"
											autocomplete="off"
											formControlName="lastName"
										/>
										<mat-error style="font-size: 12px">{{
											formErrors.lastName
										}}</mat-error>
									</mat-form-field>
								</div>
								<div class="col-12 clear">
									<mat-form-field
										floatingPlaceholder="true"
										class="col-12 clear"
										appearance="outline"
									>
										<mat-label>Email</mat-label>
										<input
											matInput
											placeholder="Email"
											autocomplete="off"
											formControlName="email"
										/>
										<mat-error style="font-size: 12px">{{
											formErrors.email
										}}</mat-error>
									</mat-form-field>
								</div>
							</form>
						</div>
						<div class="flex-1 stack p10">
							<div class="col-12 clear">
								<app-raised-busy-button
									class="col-12 clear ResetPasswordBtn"
									[isBusy]="this.actionRequestActionStatus$ | async"
									[buttonLabel]="'Reset Password'"
									(clicked)="resetPassword()"
									*ngIf="editable"
								>
								</app-raised-busy-button>
							</div>
							<div class="col-12 clear">
								<mat-form-field
									floatingPlaceholder="true"
									class="col-12 clear"
									appearance="outline"
								>
									<mat-label>Created Date</mat-label>
									<input
										matInput
										placeholder="Created Date"
										autocomplete="off"
										[ngModel]="
											(user$ | async)?.createdDate | date: 'd MMM yyyy HH:mm:ss'
										"
										readonly
									/>
								</mat-form-field>
							</div>

							<div class="col-12 clear">
								<mat-form-field
									floatingPlaceholder="true"
									class="col-12 clear"
									appearance="outline"
								>
									<mat-label>Session Expiry Date</mat-label>
									<input
										matInput
										placeholder="Session Expiry Date"
										autocomplete="off"
										[ngModel]="
											(user$ | async)?.keyExpireDate
												| date: 'd MMM yyyy HH:mm:ss'
										"
										readonly
									/>
								</mat-form-field>
							</div>
						</div>
						<div class="flex-1 stack p10">
							<div class="col-12 clear">
								<mat-form-field
									floatingPlaceholder="true"
									class="col-12 clear"
									appearance="outline"
								>
									<mat-label>Home Region</mat-label>
									<input
										matInput
										placeholder="Home Region"
										autocomplete="off"
										[ngModel]="(user$ | async)?.homeRegion"
										readonly
									/>
								</mat-form-field>
							</div>
							<div class="col-12 clear">
								<mat-form-field
									floatingPlaceholder="true"
									class="col-12 clear"
									appearance="outline"
								>
									<mat-label>ID</mat-label>
									<input
										matInput
										placeholder="ID"
										autocomplete="off"
										[ngModel]="(user$ | async)?.id"
										readonly
									/>
								</mat-form-field>
							</div>
							<div class="col-12 clear">
								<mat-form-field
									floatingPlaceholder="true"
									class="col-12 clear"
									appearance="outline"
								>
									<mat-label>Status</mat-label>
									<input
										matInput
										placeholder="Status"
										autocomplete="off"
										[ngModel]="(user$ | async)?.status"
										readonly
									/>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="verificationMessage" class="successMessage">
					<mat-icon class="notranslate no-scroll-visible">
						error_outline
					</mat-icon>
					<span class="pl10">{{ verificationMessage }} </span>
				</div>
			</mat-drawer-container>
		</div>
		<div
			*ngIf="editable"
			class="row justify-end space-between col-12 pt10 centerVertically"
			style="z-index: 99"
		>
			<app-raised-busy-button
				style="padding-right: 16px"
				[isBusy]="this.actionRequestActionStatus$ | async"
				[isDisabled]="userForm.pristine || !userForm.valid"
				[buttonLabel]="'Cancel'"
				(clicked)="cancel()"
			>
			</app-raised-busy-button>
			<div>
				<ng-container>
					<app-raised-busy-button
						*ngIf="
							emailAddressChangePending &&
							!waitingForVerificationCodeToBeEntered
						"
						[isBusy]="this.actionRequestActionStatus$ | async"
						[isDisabled]="userForm.pristine || !userForm.valid"
						[buttonLabel]="'Send Verification Code'"
						(clicked)="sendVerificationCode()"
					>
					</app-raised-busy-button>
					<div
						*ngIf="waitingForVerificationCodeToBeEntered"
						style="display: flex; flex-direction: row; align-items: center"
					>
						<mat-form-field class="mr10" appearance="outline">
							<mat-label>Verification Code</mat-label>
							<input
								matInput
								placeholder="Verification Code"
								[(ngModel)]="verificationCode"
							/>
						</mat-form-field>
						<app-raised-busy-button
							[isBusy]="this.actionRequestActionStatus$ | async"
							[isDisabled]="
								verificationCode === undefined || verificationCode.length === 0
							"
							[buttonLabel]="'Verify'"
							(clicked)="verifyCode()"
						>
						</app-raised-busy-button>
					</div>
					<app-raised-busy-button
						*ngIf="!emailAddressChangePending"
						[isBusy]="this.actionRequestActionStatus$ | async"
						[isDisabled]="userForm.pristine || !userForm.valid"
						[buttonLabel]="'Save'"
						(clicked)="this.userSaveHandler()"
					>
					</app-raised-busy-button>
				</ng-container>
			</div>
		</div>
	</div>
</div>
