<select
	style="width: 150px; margin: 4px"
	(change)="onChange($event.target.value)"
>
	<option
		*ngFor="let option of this.paramsCutsom.valueOptions"
		value="{{ option.value }}"
		[ngStyle]="option.labelStyle"
		[selected]="this.selectedObject == option"
		[innerHTML]="option.label"
	></option>
</select>
