<div style="display: flex; flex-direction: column; align-items: center">
	<video width="960" height="540" controls>
		<source src="{{ dialogParams.videoURL }}" type="video/mp4" />
		Your browser does not support the video tag.
	</video>
	<div class="col-12 col pb10 pt10">
		<div>
			<span class="bold notranslate">{{ dialogParams.propertyName }}</span>
		</div>
		<div class="flex align-center">
			<span
				class="sectionIconSmall notranslate"
				[style.background]="dialogParams.sectionColour"
			></span>
			<span class="bold notranslate">{{ dialogParams.sectionName }}</span>
		</div>
	</div>
</div>
