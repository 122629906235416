<div class="ag-cell-label-container" role="presentation">
	<app-feedback-message-icon
		*ngIf="(selectedSelectionId$ | async) > -1"
		class="feedback-icon ml5"
		[size]="12"
		[messageCount]="(allocationFeedbackViews$ | async)?.length"
		(click)="params.feedbackClickHandler($event)"
	>
	</app-feedback-message-icon>
	<span
		ref="eMenu"
		class="ag-header-icon ag-header-cell-menu-button notranslate bi-list ml5"
		(click)="onMenuClick()"
	></span>
	<div ref="eLabel" class="ag-header-cell-label" role="presentation">
		<div class="flex-container column">
			<span
				class="ag-header-cell-text notranslate"
				[ngClass]="{ 'text-medium': params.sectionLabel }"
				style="overflow: hidden"
				role="columnheader"
				>{{ params.propertyLabel }}</span
			>
			<span
				*ngIf="params.sectionLabel"
				class="ag-header-cell-label ag-header-cell-text"
			>
				<span
					class="sectionIcon10 notranslate"
					[ngStyle]="{ 'background-color': params.sectionColour }"
				></span>
				<span class="ag-header-cell-text notranslate" role="columnheader">{{
					params.sectionLabel
				}}</span>
			</span>
		</div>
		<span
			ref="eFilter"
			*ngIf="filter"
			class="ag-header-icon ag-filter-icon bi bi-funnel ml5"
		></span>
		<span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
		<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
		<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
		<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
	</div>
</div>
