<div class="dialog-container-shrink" style="max-width: 550px">
	<h1
		matDialogTitle
		class="dialog-title"
		[innerHtml]="title | trustedContent: 'html'"
	></h1>
	<div mat-dialog-content [innerHtml]="content | trustedContent: 'html'"></div>
	<div mat-dialog-actions class="fb-right" style="margin-top: 16px">
		<button mat-raised-button color="primary" (click)="dialogRef.close(false)">
			{{ cancelButtonLabel }}
		</button>
		<button
			mat-raised-button
			color="primary"
			style="margin-left: 8px"
			(click)="dialogRef.close(true)"
		>
			{{ confirmButtonLabel }}
		</button>
	</div>
</div>
