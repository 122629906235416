<div class="full-width col" style="max-width: 500px">
	<form
		[formGroup]="inputForm"
		class="col-12 row align-center justify-center flex-nowrap pl10"
	>
		<div class="flex-1">
			<mat-form-field appearance="outline" class="col-12">
				<mat-label>{{ inputLabel }}</mat-label>
				<input
					matInput
					formControlName="formInput"
					type="text"
					#addInput
					(keydown.enter)="multiAdd(); $event.stopPropagation()"
				/>
			</mat-form-field>
		</div>
		<div class="pl10 pr10">
			<button
				mat-raised-button
				color="primary"
				style="margin-top: -4px"
				[disabled]="!this.inputForm.controls.formInput.valid"
				(click)="multiAdd(); $event.stopPropagation()"
			>
				<mat-icon class="addIcon">add</mat-icon>
			</button>
		</div>
	</form>
	<div class="pl10 pr10 clear flex" *ngIf="content">
		<p>{{ content }}</p>
	</div>
	<div
		*ngIf="!this.inputForm.controls.formInput.valid"
		class="errorContainer pl10 pr10"
		[ngClass]="{
			activeError:
				!this.inputForm.controls.formInput.valid && this.inputForm.dirty
		}"
	>
		<div class="errorMessage col-12 tac">
			{{ this.inputForm.controls.formInput.errors?.errorMessage }}
		</div>
	</div>
	<div style="display: flex; width: 100%">
		<div
			class="scroll-y full-width col listContainer"
			*ngIf="list?.length > 0"
			cdkDropList
			(cdkDropListDropped)="drop($event)"
			[cdkDropListDisabled]="!allowDragSort"
		>
			<!-- <div > -->
			<div
				*ngFor="let item of list"
				cdkDrag
				[ngClass]="{ draggable: allowDragSort }"
				class="listItem"
			>
				<div class="p10 pl20 pr20 row justify-center align-center">
					<div class="flex-1">{{ item }}</div>
					<div class="flex-1 tar">
						<mat-icon (click)="removeFromList(item)" class="deleteIcon"
							>delete</mat-icon
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
