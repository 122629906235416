/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */
import { PropertyAViewVO } from "app/modules/routes/aview/valueObjects/property.aview.vo";
import { PublicationDistributionAHubVO } from "app/valueObjects/ahub/accounts/publication-distribution.ahub.vo";
import { UserMetaDataAHubVO } from "app/valueObjects/ahub/accounts/user-meta-data.ahub.vo";
import { UserAHubVO } from "app/valueObjects/ahub/accounts/user.ahub.vo";
import { ExporterBuildHistoryIndexAHubVO } from "app/valueObjects/ahub/library/exporter-build-history-index.ahub.vo";
import { ExporterBuildHistoryAHubVO } from "app/valueObjects/ahub/library/exporter-build-history.ahub.vo";
import { ExtractAssetMatchAHubVO } from "app/valueObjects/ahub/library/extract-asset-match.vo";
import { ProductPropertyCompletenessAHubVO } from "app/valueObjects/ahub/library/product-property-completeness.ahub.vo";
import { PaginationIdBreakdownVO } from "app/valueObjects/ahub/system/pagination-id-breakdown.ahub.vo";
import { AHubTemporaryState } from "reducers/ahub/ahub-temporary.reducer";
import { aHubState } from "selector/ahub/ahub.selector";
import { HashedMapStorage } from "store/hashed-map-storage.vo";
/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */
import { IdItemVO } from "store/id-item.vo";
import { List } from "store/list.vo";
import { MapStorage } from "store/map-storage.vo";
import { ClientConfigurationAHubVO } from "valueObjects/ahub/accounts/client-configuration.ahub.vo";
import { ClientIndexAHubVO } from "valueObjects/ahub/accounts/client-index.ahub.vo";
import { ClientLibraryIndexAHubVO } from "valueObjects/ahub/accounts/client-library-index.ahub.vo";
import { ClientLibraryVersionIndexAHubVO } from "valueObjects/ahub/accounts/client-library-version-index.ahub.vo";
import { ClientLibraryVersionAHubVO } from "valueObjects/ahub/accounts/client-library-version.ahub.vo";
import { ClientLibraryAHubVO } from "valueObjects/ahub/accounts/client-library.ahub.vo";
import { ClientQuotaAHubVO } from "valueObjects/ahub/accounts/client-quota.ahub.vo";
import { ClientAHubVO } from "valueObjects/ahub/accounts/client.ahub.vo";
import { DistributionGroupIndexAHubVO } from "valueObjects/ahub/accounts/distribution-group-index.ahub.vo";
import { DistributionGroupAHubVO } from "valueObjects/ahub/accounts/distribution-group.ahub.vo";
import { DistributionIndexAHubVO } from "valueObjects/ahub/accounts/distribution-index.ahub.vo";
import { DistributionAHubVO } from "valueObjects/ahub/accounts/distribution.ahub.vo";
import { ExportDistributionAHubVO } from "valueObjects/ahub/accounts/export-distribution.ahub.vo";
import { ExportIndexAHubVO } from "valueObjects/ahub/accounts/export-index.ahub.vo";
import { ExportAHubVO } from "valueObjects/ahub/accounts/export.ahub.vo";
import { UserExtendedAHubVO } from "valueObjects/ahub/accounts/user-extended.ahub.vo";
import { UserIndexAHubVO } from "valueObjects/ahub/accounts/user-index.ahub.vo";
import { WorkGroupIndexAHubVO } from "valueObjects/ahub/accounts/work-group-index.ahub.vo";
import { WorkGroupAHubVO } from "valueObjects/ahub/accounts/work-group.ahub.vo";
import { ClientLogAHubVO } from "valueObjects/ahub/library/client-log.ahub.vo";
import { DataSetCategoryAHubVO } from "valueObjects/ahub/library/dataset-category.ahub.vo";
import { DataSetIndexAHubVO } from "valueObjects/ahub/library/dataset-index.ahub.vo";
import { DataSetAHubVO } from "valueObjects/ahub/library/dataset.ahub.vo";
import { ExporterIndexAHubVO } from "valueObjects/ahub/library/exporter-index.ahub.vo";
import { ExporterAHubVO } from "valueObjects/ahub/library/exporter.ahub.vo";
import { ExtractDefinitionIndexAHubVO } from "valueObjects/ahub/library/extract-definition-index.ahub.vo";
import { ExtractDefinitionAHubVO } from "valueObjects/ahub/library/extract-definition.ahub.vo";
import { ExtractHistoryIndexAHubVO } from "valueObjects/ahub/library/extract-history-index.ahub.vo";
import { ExtractIndexAHubVO } from "valueObjects/ahub/library/extract-index.ahub.vo";
import { ExtractAHubVO } from "valueObjects/ahub/library/extract.ahub.vo";
import { ProductAssetAHubVO } from "valueObjects/ahub/library/product-asset.ahub.vo";
import { ProductClassIndexAHubVO } from "valueObjects/ahub/library/product-class-index.ahub.vo";
import { ProductClassProductCount } from "valueObjects/ahub/library/product-class-product-count.ahub.vo";
import { ProductClassAHubVO } from "valueObjects/ahub/library/product-class.ahub.vo";
import { ProductPropertyAllocChainIndexAHubVO } from "valueObjects/ahub/library/product-property-alloc-chain-index.ahub.vo";
import { ProductPropertyAllocChainAHubVO } from "valueObjects/ahub/library/product-property-alloc-chain.ahub.vo";
import { ProductPropertyAllocationAHubVO } from "valueObjects/ahub/library/product-property-allocation.ahub.vo";
import { ProductPropertyIndexAHubVO } from "valueObjects/ahub/library/product-property-index.ahub.vo";
import { ProductPropertySectionIndexAHubVO } from "valueObjects/ahub/library/product-property-section-index.ahub.vo";
import { ProductPropertySectionAHubVO } from "valueObjects/ahub/library/product-property-section.ahub.vo";
import { ProductPropertyTypeAHubVO } from "valueObjects/ahub/library/product-property-type.ahub.vo";
import { ProductPropertyAHubVO } from "valueObjects/ahub/library/product-property.ahub.vo";
import { ProductAHubVO } from "valueObjects/ahub/library/product.ahub.vo";
import {
	ResourcePackIndexAHubVO,
	ResourcePackAHubVO,
	AnalyticResultsAHubVO,
} from "@harksolutions/ahub-web-services-types";
import { PresignedUrlAHubVO } from "valueObjects/ahub/presigned-url.ahub.vo";
import { RelationshipAHubVO } from "valueObjects/ahub/relationship.ahub.vo";
import { SystemStatusAHubVO } from "valueObjects/ahub/system/system-status.ahub.vo";
import { MapStorageUtil } from "app/store/map-storage.util";
import {
	ClientFileChangeSet,
	CloudwatchLog,
	Job,
	JobAction,
} from "@harksolutions/integration-module-types";

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Selector to get the aHub temporary state from the ahub
 */
const aHubStateTemporary = (state): AHubTemporaryState =>
	aHubState(state).temporary;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * Selector to get a list of user indexes (Individual)
 */
export const aHubStateTemporaryUserIndexList = (state): List<UserIndexAHubVO> =>
	aHubStateTemporary(state).userIndexList;

/**
 * Selector to get full user array of user indexs( system administration, different to above which is an individual indexed LIST of user indexes. ).
 */
export const aHubStateTemporaryUserIndexes = (state): UserIndexAHubVO[] =>
	aHubStateTemporary(state).userIndexs;

/**
 * Selector to get all of the temporary list of extended users.
 */
export const aHubStateTemporaryUserList = (state): List<UserAHubVO> =>
	aHubStateTemporary(state).userList;

/**
 * Selector to get all of the temporary list of extended users.
 */
export const aHubStateTemporaryUserExtendedList = (
	state
): List<UserExtendedAHubVO> => aHubStateTemporary(state).userExtendedList;

/**
 * Selector to get user meta data.
 */
export const aHubStateTemporaryUserMetaData = (state): UserMetaDataAHubVO[] =>
	aHubStateTemporary(state).userMetaData;
/**
 * Selector to get the dataSets from the temporary ahub state
 */
export const aHubStateTemporaryDataSetIndexes = (state): DataSetIndexAHubVO[] =>
	aHubStateTemporary(state).dataSetIndexs;

/**
 * Selector to get the export generator mapping blueprint URL's stpred againt the exporter ids
 */
export const aHubStateTemporaryExportGeneratorMappingBlueprintUrlByExporterIdMap =
	(state): MapStorage<string> =>
		aHubStateTemporary(state).exportGeneratorBlueprintURLByExporterIdMap;

/**
 * Selector to get the exporter indexes from the temporary ahub state
 */
export const aHubStateTemporaryExporterIndexes = (
	state
): ExporterIndexAHubVO[] => aHubStateTemporary(state).exporterIndexes;

/**
 * Selector to get the exporter indexes from the temporary ahub state
 */
export const aHubStateTemporaryExporterBuildHistoryIndexMap = (
	state
): MapStorage<ExporterBuildHistoryIndexAHubVO[]> =>
	aHubStateTemporary(state).exporterBuildHistoryIndexMap;

/**
 * Selector to get the exporter build histories from the temporary aHub state.
 */
export const aHubStateTemporaryExporterBuildHistorys = (
	state
): List<ExporterBuildHistoryAHubVO> =>
	aHubStateTemporary(state).exporterBuildHistories;

/**
 * Selector to get the distribution groups from the temporary ahub state
 */
export const aHubStateTemporaryDistributionGroupIndexes = (
	state
): DistributionGroupIndexAHubVO[] =>
	aHubStateTemporary(state).distributionGroupIndexs;

/**
 * Selector to get the work groups from the temporary ahub state
 */
export const aHubStateTemporaryWorkGroupIndexes = (
	state
): WorkGroupIndexAHubVO[] => aHubStateTemporary(state).workGroupIndexs;

/**
 * Selector to get the work groups from the temporary ahub state
 */
export const aHubStateTemporaryWorkGroupList = (state): List<WorkGroupAHubVO> =>
	aHubStateTemporary(state).workGroups;

/**
 * Selector to get the work group user index list
 */
export const aHubStateTemporaryWorkGroupUserIndexList = (
	state
): List<RelationshipAHubVO<UserIndexAHubVO>> =>
	aHubStateTemporary(state).workGroupUserIndexList;

/**
 * Selector to get the distribution groups from the temporary ahub state
 */
export const aHubStateTemporaryDistributionGroupList = (
	state
): List<DistributionGroupAHubVO> =>
	aHubStateTemporary(state).distributionGroups;

/**
 * Selector to get the distribution group distribution index list
 */
export const aHubStateTemporaryDistributionGroupDistributionIndexList = (
	state
): List<RelationshipAHubVO<DistributionIndexAHubVO>> =>
	aHubStateTemporary(state).distributionGroupDistributionIndexList;

/**
 * Selector to get the distribution group user index list
 */
export const aHubStateTemporaryDistributionGroupUserIndexList = (
	state
): List<RelationshipAHubVO<UserIndexAHubVO>> =>
	aHubStateTemporary(state).distributionGroupUserIndexList;

/**
 * Selector to get the distributions list from the store
 */
export const aHubStateTemporaryDistributionsList = (
	state
): List<DistributionAHubVO> => aHubStateTemporary(state).distributions;

/**
 * Selector to get the export index array
 */
export const aHubStateTemporaryExportIndexs = (state): ExportIndexAHubVO[] =>
	aHubStateTemporary(state).exportIndexs;

/**
 * Selector to get the export list
 */
export const aHubStateTemporaryExportList = (state): List<ExportAHubVO> =>
	aHubStateTemporary(state).exports;

/**
 * Selector to get the export distribution list
 */
export const aHubStateTemporaryExportDistributionList = (
	state
): List<ExportDistributionAHubVO> =>
	aHubStateTemporary(state).exportDistributions;

/**
 * Selector to get the legacy ref list
 */
export const aHubStateTemporaryExportLegacyRefs = (
	state
): List<IdItemVO<string[]>> => aHubStateTemporary(state).exportLegacyRefs;

/**
 * Get whole map storage of publication distributions
 */
export const aHubPublicationDistributionsMap = (
	state
): MapStorage<PublicationDistributionAHubVO[]> =>
	aHubStateTemporary(state).publicationDistributions;

/**
 * Selector to get the export distribution index list
 */
export const aHubStateTemporaryExportDistributionIndexList = (
	state
): List<RelationshipAHubVO<DistributionIndexAHubVO>> =>
	aHubStateTemporary(state).exportDistributionIndexList;

/**
 * Selector to get the dataSet list
 */
export const aHubStateTemporaryDataSetList = (state): List<DataSetAHubVO> =>
	aHubStateTemporary(state).dataSets;

/**
 * Selector to get a map of data set products
 */
export const aHubStateTemporaryDataSetProducts = (
	state
): MapStorage<List<ProductAHubVO>> => aHubStateTemporary(state).dataSetProducts;

/**
 * Selector to get a map of the data set with the the product asset data
 */
export const aHubStateTemporaryDataSetProductAssets = (
	state
): MapStorage<List<ProductAssetAHubVO>> =>
	aHubStateTemporary(state).dataSetProductAssets;

/**
 * Selector to get the exporters list
 */
export const aHubStateTemporaryExporterList = (state): List<ExporterAHubVO> =>
	aHubStateTemporary(state).exporters;

/**
 * Selector to get the list of data set categories.
 */
export const aHubStateTemporaryDataSetCategoryList = (
	state
): List<DataSetCategoryAHubVO> => aHubStateTemporary(state).dataSetCategories;

/**
 * Get the current list of data set category products.
 */
export const aHubStateTemporaryDataSetCategoryProducts = (
	state
): MapStorage<HashedMapStorage<ProductAHubVO>> =>
	aHubStateTemporary(state).dataSetCategoryProducts;

/**
 * Selector to get all of the keys from the data set category product maps in this case.
 */
export const aHubStateTemporaryDataSetCategoryProductsKeys = (
	state
): number[] =>
	aHubStateTemporaryDataSetCategoryProducts(state).keys.map((v) => Number(v));

/**
 * Get the current list of data set category product id's sorted in id order.
 */
export const aHubStateTemporaryDataSetCategoryProductIdSorted = (
	state
): MapStorage<number[]> =>
	aHubStateTemporary(state).dataSetCategoryProductIdSorted;

/**
 * Selector to get export preview image urls.
 */
export const aHubStateTemporaryExportPreviewImageUrlsList = (
	state
): List<IdItemVO<PresignedUrlAHubVO>> =>
	aHubStateTemporary(state).exportPreviewImageUrls;

/**
 * Selector to get export path urls.
 */
export const aHubStateTemporaryExportPathUrlsList = (
	state
): List<IdItemVO<PresignedUrlAHubVO>> =>
	aHubStateTemporary(state).exportPathUrls;

/**
 * Selector to get full client list indexs ( user independent ).
 */
export const aHubStateTemporaryClientIndexs = (state): ClientIndexAHubVO[] =>
	aHubStateTemporary(state).clientIndexs;

/**
 * Selector to get full client list ( user independent ).
 */
export const aHubStateTemporaryClientList = (state): List<ClientAHubVO> =>
	aHubStateTemporary(state).clients;

/**
 * Selector to get full client library version indexs ( user independent ).
 */
export const aHubStateTemporaryClientLibraryVersionIndexs = (
	state
): ClientLibraryVersionIndexAHubVO[] =>
	aHubStateTemporary(state).clientLibraryVersionIndexs;

/**
 * Selector to get full client library version list  ( user independent ).
 */
export const aHubStateTemporaryClientLibraryVersionList = (
	state
): List<ClientLibraryVersionAHubVO> =>
	aHubStateTemporary(state).clientLibraryVersions;

/**
 * Selector to get the currently selected client library version.
 */
export const aHubStateTemporaryClientLibraryVersionCurrent = (
	state
): ClientLibraryVersionAHubVO =>
	aHubStateTemporaryClientLibraryVersionList(state).data.find(
		(libraryVersion) => libraryVersion.buildFrom
	);

/**
 * Selector to get client library version Model URLs List.
 */
export const aHubStateTemporaryClientLibraryVersionModelUrlsList = (
	state
): List<IdItemVO<PresignedUrlAHubVO>> =>
	aHubStateTemporary(state).clientLibraryVersionModelUrls;

/**
 * Selector to get the client library indexes.
 */
export const aHubStateTemporaryClientLibraryIndexes = (
	state
): ClientLibraryIndexAHubVO[] => aHubStateTemporary(state).clientLibraryIndexes;

/**
 * Selector to get the full client library list.
 */
export const aHubStateTemporaryClientLibrariesList = (
	state
): List<ClientLibraryAHubVO> => aHubStateTemporary(state).clientLibraries;

/**
 * Selector to get client quotas ( user independent ).
 */
export const aHubStateTemporaryClientQuotas = (
	state
): List<ClientQuotaAHubVO> => aHubStateTemporary(state).clientQuotas;

/**
 * Selector to get client quotas history.
 */
export const aHubStateTemporaryClientQuotasHistory = (
	state
): List<ClientQuotaAHubVO> => aHubStateTemporary(state).clientQuotasHistory;

/**
 * Selector to get full client configuration list.
 */
export const aHubStateTemporaryClientConfigurationList = (
	state
): List<ClientConfigurationAHubVO> =>
	aHubStateTemporary(state).clientConfigurations;

export const aHubStateTemporaryClientLogos = (
	state
): List<IdItemVO<PresignedUrlAHubVO>> => aHubStateTemporary(state).clientLogos;

/**
 * Selector to get the list of product class product counts for the current client.
 */
export const aHubStateTemporaryProductClassProductCounts = (
	state
): ProductClassProductCount[] =>
	aHubStateTemporary(state).productClassProductCounts;

/**
 * Selector to get the product class indexes from the temporary ahub state
 */
export const aHubStateTemporaryProductClassIndexes = (
	state
): ProductClassIndexAHubVO[] => aHubStateTemporary(state).productClassIndexs;

/**
 * Selector to get the product classes from the temporary ahub state
 */
export const aHubStateTemporaryProductClassList = (
	state
): List<ProductClassAHubVO> => aHubStateTemporary(state).productClasses;

/**
 * Selector to get all of the product property types from the aHub temporary state.
 */
export const aHubStateTemporaryProductPropertyTypes = (
	state
): ProductPropertyTypeAHubVO[] => aHubStateTemporary(state).productProperyTypes;

/**
 * Selector to get the product property indexes from the temporary ahub state
 */
export const aHubStateTemporaryProductPropertyIndexes = (
	state
): ProductPropertyIndexAHubVO[] =>
	aHubStateTemporary(state).productPropertyIndexs;

/**
 * Selector to get the product property aview vos from the temporary ahub state
 */
export const aHubStateTemporaryProductPropertyAViewVOs = (
	state
): PropertyAViewVO[] => aHubStateTemporary(state).productPropertyAViewVOs;

/**
 * Selector to get the product property list from the temporary ahub state
 */
export const aHubStateTemporaryProductPropertyList = (
	state
): List<ProductPropertyAHubVO> => aHubStateTemporary(state).productProperties;

/**
 * Selector to get the product property section indexes from the temporary ahub state
 */
export const aHubStateTemporaryProductPropertySectionIndexes = (
	state
): ProductPropertySectionIndexAHubVO[] =>
	aHubStateTemporary(state).productPropertySectionIndexs;

/**
 * Selector to get the product property section list from the temporary ahub state
 */
export const aHubStateTemporaryProductPropertySectionList = (
	state
): List<ProductPropertySectionAHubVO> =>
	aHubStateTemporary(state).productPropertySections;

/**
 * Selector to get the product property allocation indexes from the temporary ahub state
 */
export const aHubStateTemporaryProductPropertyAllocationIndexes = (
	state
): ProductPropertyAllocationAHubVO[] =>
	aHubStateTemporary(state).productPropertyAllocations;

/**
 * Selector to get the list of product property allocation chain indexes.
 */
export const aHubStateTemporaryProductPropertyAllocationChainIndexes = (
	state
): ProductPropertyAllocChainIndexAHubVO[] =>
	aHubStateTemporary(state).productPropertyAllocationChainIndexs;

/*
 * Selector to get all of the product property allocation chains.
 */
export const aHubStateTemporaryProductPropertyAllocationChains = (
	state
): List<ProductPropertyAllocChainAHubVO> =>
	aHubStateTemporary(state).productPropertyAllocationChains;

/**
 * Selector to get the extract definition indexes.
 */
export const aHubStateTemporaryExtractDefinitionIndexes = (
	state
): ExtractDefinitionIndexAHubVO[] =>
	aHubStateTemporary(state).extractDefinitionIndexes;

/**
 * Selector to get the extract definitions list.
 */
export const aHubStateTemporaryExtractDefinitionList = (
	state
): List<ExtractDefinitionAHubVO> =>
	aHubStateTemporary(state).extractDefinitions;

/**
 * Selector to get all of extract definition indexes.
 */
export const aHubStateTemporaryExtractIndexes = (state): ExtractIndexAHubVO[] =>
	aHubStateTemporary(state).extractIndexes;

/**
 * Selector to get a list of the extracts.
 */
export const aHubStateTemporaryExtractList = (state): List<ExtractAHubVO> =>
	aHubStateTemporary(state).extracts;

/**
 * Selector to get all of extract history definition indexes.
 */
export const aHubStateTemporaryExtractHistoryIndexes = (
	state
): ExtractHistoryIndexAHubVO[] =>
	aHubStateTemporary(state).extractHistoryIndexes;

/**
 * Selector to get the storage map which stores the extract product completness pagination page
 */
export const aHubStateTemporaryExtractProductCompletenessMap = (
	state
): MapStorage<ProductPropertyCompletenessAHubVO[]> =>
	aHubStateTemporary(state).extractProductCompletenessMap;

/**
 * Selector to get the storage map which stores the extract product completness pagination page
 */
export const aHubStateTemporaryExtractProductCompletenessPaginationMap = (
	state
): MapStorage<PaginationIdBreakdownVO> =>
	aHubStateTemporary(state).extractProductCompletenessPaginationMap;

/**
 * Selector to get the storage map which stores the products by extract id
 */
export const aHubStateTemporaryExtractProducts = (
	state
): MapStorage<HashedMapStorage<ProductAHubVO>> =>
	aHubStateTemporary(state).extractProducts;

/**
 * Selector to get all of the keys from the extract product maps in this case its the extract id's
 */
export const aHubStateTemporaryExtractProductsKeys = (state): number[] =>
	aHubStateTemporaryExtractProducts(state).keys.map((v) => Number(v));

/**
 * Selector to get all of extract definition indexes.
 */
export const aHubStateTemporaryExtractProductAssets = (
	state
): List<ProductAssetAHubVO> => aHubStateTemporary(state).extractProductsAssets;

/**
 * Selector to get the storage map which stores the extract asset matches for the currently selected extract
 */
export const aHubStateTemporaryExtractAssetMatches = (
	state
): ExtractAssetMatchAHubVO[] => aHubStateTemporary(state).extractAssetMatches;

/**
 * Selector to get all of the resource pack indexes.
 */
export const aHubStateTemporaryResourcePackIndexes = (
	state
): ResourcePackIndexAHubVO[] => aHubStateTemporary(state).resourcePackIndexes;

/**
 * Selector to get all of the resource pack objects.
 */
export const aHubStateTemporaryResourcePackList = (
	state
): List<ResourcePackAHubVO> => aHubStateTemporary(state).resourcePacks;

/**
 * Selector to get a list of the client logs.
 */
export const aHubStateTemporaryClientLogs = (state): List<ClientLogAHubVO> =>
	aHubStateTemporary(state).clientLogs;

/**
 * Get Client File Change Sets
 */
export const clientFileChangeSets = (state): List<ClientFileChangeSet> =>
	aHubStateTemporary(state).clientFileChangeSets;

/**
 * Get Change Set Jobs by ClientFileChangeSetId
 *
 * @param clientFileChangeSetId
 */
export const changeSetJobsByClientFileChangeSetId =
	(clientFileChangeSetId: number) =>
	(state): List<Job> => {
		return MapStorageUtil.mapStorageGet(
			aHubStateTemporary(state).changeSetJobs,
			clientFileChangeSetId?.toString()
		);
	};

/**
 * Get job logs by job id
 *
 * @param jobId
 */
export const jobLogs =
	(jobId: number) =>
	(state): CloudwatchLog[] => {
		return MapStorageUtil.mapStorageGet(
			aHubStateTemporary(state).jobLogs,
			jobId?.toString()
		);
	};

/**
 * Get change set logs
 *
 * @param changeSetId
 */
export const changeSetLogs =
	(changeSetId: number) =>
	(state): CloudwatchLog[] => {
		return MapStorageUtil.mapStorageGet(
			aHubStateTemporary(state).changeSetLogs,
			changeSetId?.toString()
		);
	};

/**
 * Get Job Actions
 */
export const jobActions = (state): List<JobAction> =>
	aHubStateTemporary(state).jobActions;

/**
 * The current analytic results.
 *
 * @param state 		The state to check in.
 *
 * @returns 			Returns the current results.
 */
export const analyticsResults = (state): AnalyticResultsAHubVO =>
	aHubStateTemporary(state).analyticsResults;

/**
 * Selector to get all of the system statuses
 */
export const aHubStateTemporarySystemStatus = (state): SystemStatusAHubVO[] =>
	aHubStateTemporary(state).systemStatus;
