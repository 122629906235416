<div
	style="
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		vertical-align: middle;
	"
>
	<input
		#dateInput
		matInput
		type="date"
		(change)="dateChanged($event)"
		style="width: 100%"
		pattern="[0-9]{4}/[0-9]{2}/[0-9]{2}"
	/>
</div>
