<!-- Top Application Bar -->
<div class="col-12" id="TopBar">
	<div class="text-top-nav-menu row align-start">
		<div class="col flex-1">
			<div class="col-12 partOne">{{ titlePath }}</div>
			<div class="col-12 partTwo">
				{{ title }}
				<app-knowledge-base-helper-icon
					style="margin-top: 5px; margin-left: 10px"
					[knowledgeBasePagePath]="knowledgeBasePagePath"
				>
				</app-knowledge-base-helper-icon>
			</div>
		</div>
		<!-- aView Tutorial -->
		<div
			class="flex pointer videoTutorialButton align-center row"
			*ngIf="this.currentToolIsAView$ | async"
			(click)="openTutorialWindow()"
		>
			<mat-icon class="mr10">ondemand_video</mat-icon>
			<span>Watch Tutorial</span>
		</div>
		<!-- aView Tutorial -->

		<!-- aView Pro Tutorial -->
		<div
			class="flex pointer videoTutorialButton align-center row"
			*ngIf="this.currentToolIsAViewPro$ | async"
			(click)="openaViewProTutorialWindow()"
		>
			<mat-icon class="mr10">ondemand_video</mat-icon>
			<span>Watch Tutorial</span>
		</div>
		<!-- aView Pro Tutorial -->
	</div>
	<div
		*ngIf="showNewVersionTab"
		class="new-version-tab"
		[@newVersionAnimation]="newVersionAnimation"
	>
		<span style="color: white"
			>New Version [{{ newVersionNumber }}] Available</span
		>
		<button
			*ngIf="environment?.name != 'BETA'"
			class="new-version-tab-button pulsate"
			(click)="getNewVersion()"
		>
			Get It Now!
		</button>
		<button
			*ngIf="environment?.name == 'BETA'"
			class="new-version-tab-button pulsate"
		>
			Ctrl F5 Time, Woo!
		</button>
	</div>
</div>
<div class="top-right-widgets row align-center">
	<img
		src="/assets/images/aViewProLogo.png"
		class="secondaryLogo logoAnimation hideForaWorkbook"
		alt="aView"
		*ngIf="(clientLogo$ | async) && this.currentToolIsAViewPro$ | async"
		style="height: 30px"
	/>
	<app-client-menu
		class="ClientMenu"
		*ngIf="this.currentToolIsAHub$ | async"
	></app-client-menu>

	<app-system-health></app-system-health>
</div>

<div
	id="MenuIcon"
	class="group1"
	(click)="toggleNav()"
	[ngClass]="active ? 'activeNav' : 'inactiveNav'"
>
	<i aria-hidden="true" class="notranslate bi-list" *ngIf="!active"></i>
	<i aria-hidden="true" class="bi bi-chevron-left" *ngIf="active"></i>
</div>
<img
	src="/assets/images/aHubLogo-2.svg"
	class="mainLogo logoAnimation hideForaWorkbook"
	alt="aHub"
	*ngIf="this.currentToolIsAHub$ | async"
/>
<img
	src="/assets/images/aViewLogo.png"
	class="mainLogo logoAnimation hideForaWorkbook"
	alt="aView"
	*ngIf="this.currentToolIsAView$ | async"
	style="height: 40px"
/>
<img
	src="/assets/images/aViewProLogo.png"
	class="mainLogo logoAnimation hideForaWorkbook"
	alt="aView"
	*ngIf="!(clientLogo$ | async) && this.currentToolIsAViewPro$ | async"
	style="height: 40px"
/>
<img
	[src]="clientLogo$ | async"
	class="mainLogo logoAnimation hideForaWorkbook"
	alt="aView"
	*ngIf="(this.currentToolIsAViewPro$ | async) && (clientLogo$ | async)"
	style="height: 50px"
/>
<img
	src="/assets/images/aWorkbook-logo-retina.png"
	class="mainLogo logoAnimation showForaWorkbook"
	alt="aWOrkbook"
	style="height: 40px"
/>
<!-- Pop out top level side panel section navigation -->
<div
	*ngIf="routes$ | async"
	id="Navigation"
	#sidenav
	(mouseleave)="closeNav()"
	[ngClass]="active ? 'activeNav' : 'inactiveNav'"
	class="col-12 col"
>
	<div class="tools-menu">
		<div class="navLogoContainer">
			<img
				src="/assets/images/menuLogo.png"
				[routerLink]="'/welcome'"
				*ngIf="this.currentToolIsAHub$ | async"
				alt="aHub"
				class="menuLogo pointer"
			/>

			<img
				src="/assets/images/aViewLogo2.png"
				*ngIf="
					(this.currentToolIsAView$ | async) ||
					(this.currentToolIsAViewPro$ | async)
				"
				alt="aView"
				class="menuLogo pointer aview-logo"
			/>
		</div>
	</div>

	<!-- Our navigation is a list -->
	<div
		id="MainMenu"
		*ngIf="
			!(this.currentToolIsAView$ | async) &&
			!(this.currentToolIsAViewPro$ | async)
		"
	>
		<ul class="LevelOne">
			<!-- Level One -->
			<li class="LevelOneItem">
				<!-- Level Two -->

				<app-sidenav-route
					[routes]="routes$ | async"
					[depth]="1"
					(closeNav)="closeNav($event)"
				></app-sidenav-route>
			</li>
		</ul>
	</div>

	<div id="bottomMenu" class="col-12 col">
		<div
			class="col-12 col toolsMenu"
			*ngIf="this.userHasAViewPublications$ | async"
		>
			<div class="menuTitle">Tools</div>

			<div
				class="row align-center flex-nowrap pt10 pb10 toolItem-ahub"
				(click)="this.toolSwitchAHub()"
				*ngIf="!(this.currentToolIsAHub$ | async)"
			>
				<div class="navLogoContainer">
					<img src="/assets/images/menuLogo.png" alt="aHub" class="toolIcon" />
				</div>
			</div>

			<div
				class="row align-center flex-nowrap pt10 pb10 toolItem-aview"
				(click)="this.toolSwitchAView()"
				*ngIf="
					!(this.currentToolIsAView$ | async) &&
					!(this.currentToolIsAViewPro$ | async)
				"
			>
				<div class="navLogoContainer">
					<img
						src="/assets/images/aViewLogo2.png"
						alt="aView"
						class="toolIcon"
					/>
				</div>
			</div>
		</div>
		<div class="loginMenu">
			<app-user-menu
				class="valign"
				style="min-height: 40px; padding-right: 13px"
			>
			</app-user-menu>
		</div>
	</div>
</div>
