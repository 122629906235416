<div
	class="pr10 pb20 pt10 message-container"
	[ngClass]="{ 'current-user': isCurrentUser, pl10: isCurrentUser }"
>
	<div
		class="user-message col"
		attr.data-date="{{ feedback.lastModified | date: 'medium' }}"
	>
		<div>{{ feedback.feedbackMessage }}</div>
		<button
			mat-icon-button
			(click)="deleteFeedbackClicked(feedback.feedbackId)"
			*ngIf="isCurrentUser"
			matTooltip="Delete feedback"
			style="position: absolute; top: 0px; right: 0px"
		>
			<i
				aria-hidden="true"
				class="notranslate bi bi-x"
				style="font-size: 16px"
			></i>
		</button>
	</div>
	<app-user-spot
		*ngIf="feedback.userIndex"
		[size]="18"
		class="mr10"
		[user]="feedback.userIndex"
		style="background: #eef2f5; opacity: 0.7"
	>
	</app-user-spot>
</div>
