<form [formGroup]="reorderingForm" class="col col-12 oh">
	<div class="reorderLabel">Move: {{ this.tree.node.label }}</div>
	<mat-radio-group
		formControlName="above"
		class="pb20 row align-center space-between"
	>
		<mat-radio-button [value]="true" class="mr10">Above</mat-radio-button>
		<mat-radio-button [value]="false">Below</mat-radio-button>
	</mat-radio-group>
	<mat-form-field class="" appearance="outline" class="col-12">
		<mat-label>Select</mat-label>
		<mat-select class="notranslate" formControlName="targetNode">
			<mat-option
				*ngFor="let sibling of treeWithKeys | keyvalue"
				[value]="sibling.value"
				[disabled]="currentPosition === sibling.key"
			>
				<div class="mat-option-container">
					<span class="notranslate"
						>{{ sibling.key }}. {{ sibling.value.node.label }}</span
					>
				</div>
			</mat-option>
		</mat-select>
	</mat-form-field>
</form>
