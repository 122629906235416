<div
	class="user-icon"
	[matTooltipClass]="'notranslate'"
	*ngIf="this.userInitials$ | async"
	matTooltip="{{
		tooltip ? (userName$ | async) + '\n' + tooltip : (userName$ | async)
	}}"
	matTooltipClass="multilineTooltip"
	[ngClass]="{ 'current-user': (isSessionUser$ | async) }"
	[ngStyle]="{ width: size + 'px', height: size + 'px' }"
>
	<div class="user-icon-bg"></div>
	<div class="user-icon-bg-inner"></div>
	<div
		class="user-icon-text notranslate"
		[ngClass]="{ bold: size / 2 < 12 }"
		[ngStyle]="{ 'font-size': size / 2 + 'px' }"
	>
		{{ this.userInitials$ | async }}
	</div>
</div>
