<!-- Activity Toast -->
<div
	class="aHubToast col fadeIn"
	[ngClass]="{
		success: successState(notification$ | async) === 'SUCCESS',
		failed: successState(notification$ | async) === 'FAILED',
		uploading: (this.notificationUploadCurrentDataStream$ | async),
		fadeOut: toastDisabled,
		fadeIn: !toastDisabled
	}"
	*ngIf="notification$ | async"
>
	<!-- Toast Content Container -->
	<div class="row pl10 pr10 flex-nowrap align-center pb5 inner-toast">
		<!-- Toast Icons -->
		<mat-icon
			class="mr10"
			*ngIf="
				(notification$ | async)?.notificationIcon !== 'sync' &&
				(notification$ | async)?.notificationIcon
			"
		>
			{{ (notification$ | async)?.notificationIcon }}
		</mat-icon>
		<div
			*ngIf="
				(notification$ | async)?.notificationIcon === 'sync' && !showQueueIcon
			"
			class="flex mr15 pl5"
		>
			<mat-spinner diameter="18" strokeWidth="3" color="accent"> </mat-spinner>
		</div>
		<div class="row align-center pr10" *ngIf="showQueueIcon">
			<mat-icon svgIcon="clock-fast" [matTooltip]="'Queue'"></mat-icon>
		</div>
		<!-- Toast Icons -->
		<!-- User Icon -->
		<app-user-spot
			[size]="18"
			class="mr10"
			[userId$]="this.userIdObservableGet(this.workflowStatus?.userId)"
			*ngIf="(sessionUser$ | async)?.id !== this.workflowStatus?.userId"
		>
		</app-user-spot>
		<app-user-spot
			[size]="18"
			class="mr10"
			[user$]="sessionUser$"
			*ngIf="(sessionUser$ | async)?.id === this.workflowStatus?.userId"
		>
		</app-user-spot>
		<!-- User Icon -->
		<!-- Toast Text & Progress-->
		<div class="innerToast col pl10 pr40">
			<div class="part1">{{ (notification$ | async)?.notificationLabel }}</div>
			<div
				class="part2 col pt5"
				*ngIf="this.notificationUploadCurrentDataStream$ | async"
			>
				<div class="col-12">
					{{ (this.notificationUploadCurrentDataStream$ | async)?.progress }}%
					complete ({{ this.notificationUploadDataComplete$ | async }}/{{
						this.notificationUploadDataTotal$ | async
					}})
				</div>
			</div>
		</div>
		<!-- Toast Text & Progress-->
		<!-- Close Toast Button -->
		<mat-icon class="pointer closeToast" (click)="closeToast()">close</mat-icon>
		<!-- Close Toast Button -->
	</div>
	<!-- Toast Content Container -->
	<!-- Loading Bar -->
	<div
		class="loadingBarContainer"
		*ngIf="this.notificationUploadCurrentDataStream$ | async"
	>
		<div class="loadingBar">
			<div
				class="loadingBarInner"
				[ngStyle]="{
					width:
						(this.notificationUploadCurrentDataStream$ | async)?.progress + '%'
				}"
			></div>
		</div>
	</div>
	<!-- Loading Bar -->
</div>
<!-- Activity Toast -->
