<div>
	<div mat-dialog-content>
		<textarea style="height: 50vh; width: 70vw" readonly>{{
			this.notificationJSON
		}}</textarea>
	</div>
	<div class="fb-right mat-dialog-actions" style="margin-top: 16px">
		<button mat-button style="margin-left: 8px" (click)="dialogRef.close(true)">
			Got it!
		</button>
	</div>
</div>
