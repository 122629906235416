<div style="width: 100%; height: 100%">
	<div class="optionSeleted" style="border-bottom: 1px solid #bdc3c7">
		<span style="padding-left: 12px">{{ this.selectedValueLabel }}</span>
	</div>

	<div #container style="max-height: 200px; overflow: auto">
		<div
			*ngFor="let option of this.valueOptions; let i = index"
			class="optionValue"
			style="display: block"
		>
			<span
				style="padding: 6px 6px 6px 12px; display: block"
				(click)="onClick(i)"
				>{{ option.label }}</span
			>
		</div>
	</div>
</div>
