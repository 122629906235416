<div class="col align-center col-12 full-height">
	<div class="col col-12 align-center flex-1 oh">
		<img
			*ngIf="dialogParams.imageUrl"
			class="productImageViewImage"
			[src]="dialogParams.imageUrl"
			alt=""
		/>
	</div>

	<div class="col-12 col pb10 pt10">
		<div>
			<span class="bold notranslate">{{ dialogParams.propertyName }}</span>
		</div>
		<div class="flex align-center">
			<span
				class="sectionIconSmall notranslate"
				[style.background]="dialogParams.sectionColour"
			></span>
			<span class="bold notranslate">{{ dialogParams.sectionName }}</span>
		</div>
	</div>
</div>
