<div class="dialog-container">
	<div matDialogTitle>{{ title }}</div>

	<app-list-search-header
		[listTitle]="listTitle"
		[listControlForm]="listControlForm"
		[indexCount$]="listDataCount$"
		[indexFilteredCount$]="listDataFilteredCount$"
	></app-list-search-header>

	<div mat-dialog-content class="no-scroll-visible">
		<mat-list
			class="scroll-vertical padding-u column col-12"
			style="height: 500px"
		>
			<mat-radio-group
				class="dialog-radio-group no-scroll-visible"
				[(ngModel)]="selectedValue"
				style="margin-top: 10px"
			>
				<mat-radio-button
					class="dialog-radio-button notranslate"
					*ngFor="let item of this.listDataFiltered$ | async"
					[value]="item[this.itemSelectorProperty]"
				>
					{{ item.name ? item.name : item.label }}
				</mat-radio-button>
			</mat-radio-group>
		</mat-list>
	</div>

	<div class="fill-remaining-space"></div>

	<div mat-dialog-actions class="fb-right" style="margin-top: 16px">
		<button mat-raised-button color="primary" (click)="dialogRef.close()">
			{{ cancelButtonLabel }}
		</button>
		<button
			mat-raised-button
			color="primary"
			[disabled]="selectedValue ? false : true"
			(click)="dialogRef.close(this.selectedValue)"
		>
			{{ confirmButtonLabel }}
		</button>
	</div>
</div>
