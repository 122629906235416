<i
	aria-hidden="true"
	[ngStyle]="{ 'font-size': size + 'px', color: colour }"
	class="bi pointer note-button"
	[ngClass]="{
		'bi-chat-dots-fill': messageCount > 0,
		'bi-chat-dots': messageCount < 1
	}"
	[matBadgeHidden]="messageCount < 1"
	[matBadge]="messageCount"
	matBadgeSize="small"
	matBadgePosition="below after"
	matBadgeColor="accent"
	class="notranslate"
></i>
