<div style="display: flex; height: 100%; background-color: white">
	<input
		#valueInput
		type="text"
		style="width: 100%; height: 100%"
		[(ngModel)]="this.value"
		(keyup)="onKeyUpHandler($event)"
		(keydown.enter)="enterHandler($event)"
		(keydown)="onKeyDownHandler($event)"
	/>
	<mat-icon
		class="notranslate"
		*ngIf="this.isError"
		style="color: red"
		[matTooltip]="errorTooltip"
		matTooltipClass="multilineTooltipWithTabs"
		>error</mat-icon
	>
	<mat-icon class="notranslate" *ngIf="!this.isError" style="color: green"
		>check</mat-icon
	>
</div>
