<!-- Capture the mouse events entering this class, but prevent them from travelling up the subtrees -->
<svg:g
	class="line"
	(mousedown)="prepareForClassMove($event); $event.stopPropagation()"
>
	<!-- Data display for this section of the tree, using wrapped component generaed by supplied factory -->
	<svg:g (click)="selectSubtreeClick()">
		<svg:g
			#subTreeNode
			[node]="this.tree.node"
			[treeConfig]="this.treeConfig"
			[isDropTarget]="this.tree.isDropTarget"
			app-node-wrapper
		/>
	</svg:g>

	<!-- Edit icon , only show if selected and not root ( ie has parent ) -->
	<svg:g
		*ngIf="this.editable && this.tree.isSelected"
		[attr.transform]="this.nodeTopLeftTranslate"
		class="svg-accent-button"
		(click)="editNodeClick()"
	>
		<title>edit</title>
		<svg:circle cx="0" cy="0" r="14"></svg:circle>
		<svg:g
			transform="translate(-9 -9) scale( 0.75, 0.75) "
			class="svg-accent-button-icon"
		>
			<svg fill="none" height="24" width="24">
				<path
					d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
				/>
			</svg>
		</svg:g>
	</svg:g>

	<!-- Delete icon , only show if selected and not root ( ie has parent ) -->
	<svg:g
		*ngIf="this.editable && this.tree.isSelected && this.tree.parentTree"
		[attr.transform]="this.nodeTopRightTranslate"
		class="svg-delete-button"
		(click)="deleteNodeClick()"
	>
		<title>delete</title>
		<svg:circle cx="0" cy="0" r="14"></svg:circle>
		<svg:g class="svg-delete-button-icon">
			<svg:line x1="-5" y1="-5" x2="5" y2="5"></svg:line>
			<svg:line x1="-5" y1="5" x2="5" y2="-5"></svg:line>
		</svg:g>
	</svg:g>

	<!-- Add button beneath childless part of the tree -->
	<svg:g
		*ngIf="this.editable && !hasSubTrees && this.tree.isSelected"
		[attr.transform]="
			this.nodeSubTreeTranslate + this.nodeRotateCCWhenLandscape
		"
		(click)="addNodeClick()"
	>
		<svg:line x1="0" y1="0" x2="0" y2="30"></svg:line>
		<svg:g transform="translate(0 30)" class="svg-accent-button">
			<svg:circle cx="0" cy="0" r="14"></svg:circle>
			<svg
				fill="#fff"
				class="svg-accent-button-icon"
				height="24"
				viewBox="0 0 24 24"
				width="24"
				x="-12"
				y="-12"
				style="stroke-width: 0"
			>
				<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
			</svg>
		</svg:g>
		<title>add child</title>
	</svg:g>

	<!-- Showing subtree children -->
	<svg:g
		*ngIf="tree.childrenShow && hasSubTrees"
		[style.opacity]="subTreesDisplay"
	>
		<!-- makinging all the sub tree stuff relative to the subtree start point for this node.-->
		<svg:g [attr.transform]="this.nodeSubTreeTranslate">
			<!-- There might be a really clever way to work out how to draw the lines just using
            positions relative to the sub tree positions.. its simplier just to choose horizontal
            or vertical layouts according to the tree orinentation. -->

			<!-- Show Add control to the side of tree , if this part of the tree is selected or highlighted     [attr.transform]="subTreeTranslate(subTrees.length-1)"-->

			<svg:g
				*ngIf="this.editable && this.tree.isHighlighted"
				[attr.transform]="subTreeTranslate(subTrees.length - 1)"
				(click)="addNodeClick()"
			>
				<svg:g
					[attr.transform]="
						this.nodeTopPlusHalfLevelGapTranslate + this.nodeRotateWhenLandscape
					"
				>
					<svg:line x1="0" y1="0" x2="30" y2="0"></svg:line>
					<svg:g transform="translate(30 0)" class="svg-accent-button">
						<svg:circle cx="0" cy="0" r="14"></svg:circle>
						<svg
							fill="#fff"
							height="24"
							class="svg-accent-button-icon"
							viewBox="0 0 24 24"
							width="24"
							x="-12"
							y="-12"
							style="stroke-width: 0"
						>
							<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
						</svg>
					</svg:g>
				</svg:g>
				<title>add child</title>
			</svg:g>

			<!-- Portrait -->
			<svg:g *ngIf="!treeConfig.treeLandscape">
				<!-- Line from this node to expanding line -->
				<svg:line
					stroke-linecap="round"
					[attr.x1]="0"
					[attr.y1]="0"
					[attr.x2]="0"
					[attr.y2]="this.treeConfig.nodeLevelGap / 2"
				></svg:line>

				<!-- Expanding line, first subtree to last subtree -->
				<svg:line
					stroke-linecap="round"
					[attr.x1]="subTreeChildDeltaSize(0).width"
					[attr.y1]="this.treeConfig.nodeLevelGap / 2"
					[attr.x2]="subTreeChildDeltaSize(subTrees.length - 1).width"
					[attr.y2]="this.treeConfig.nodeLevelGap / 2"
				></svg:line>

				<!-- Line from expanding line to child sub tree -->
				<svg:line
					*ngFor="let subTree of this.subTrees; let subTreeIndex = index"
					stroke-linecap="round"
					[attr.x1]="subTreeChildDeltaSize(subTreeIndex).width"
					[attr.y1]="this.treeConfig.nodeLevelGap / 2"
					[attr.x2]="subTreeChildDeltaSize(subTreeIndex).width"
					[attr.y2]="this.treeConfig.nodeLevelGap"
				></svg:line>
			</svg:g>

			<!-- Landscape -->
			<svg:g *ngIf="treeConfig.treeLandscape">
				<!-- Line from this node to expanding line -->
				<svg:line
					stroke-linecap="round"
					[attr.x1]="0"
					[attr.y1]="0"
					[attr.x2]="this.treeConfig.nodeLevelGap / 2"
					[attr.y2]="0"
				></svg:line>

				<!-- Expanding line, first subtree to last subtree -->
				<svg:line
					stroke-linecap="round"
					[attr.x1]="this.treeConfig.nodeLevelGap / 2"
					[attr.y1]="subTreeChildDeltaSize(0).height"
					[attr.x2]="this.treeConfig.nodeLevelGap / 2"
					[attr.y2]="subTreeChildDeltaSize(subTrees.length - 1).height"
				></svg:line>

				<!-- Line from expanding line to child sub tree -->
				<svg:line
					*ngFor="let subTree of this.subTrees; let subTreeIndex = index"
					stroke-linecap="round"
					[attr.x1]="this.treeConfig.nodeLevelGap / 2"
					[attr.y1]="subTreeChildDeltaSize(subTreeIndex).height"
					[attr.x2]="this.treeConfig.nodeLevelGap"
					[attr.y2]="subTreeChildDeltaSize(subTreeIndex).height"
				></svg:line>
			</svg:g>

			<!-- Sub Trees , recursive call -->
			<svg:g
				*ngFor="let subTree of this.subTrees; let subTreeIndex = index"
				[tree]="subTree"
				[treeConfig]="this.treeConfig"
				[attr.transform]="subTreeTranslate(subTreeIndex)"
				app-subtree
			/>
		</svg:g>
	</svg:g>

	<!-- control for showing sub tress ( children ) and hiding them if displayed. -->
	<svg:g
		*ngIf="hasSubTrees"
		[attr.transform]="this.nodeSubTreeTranslate"
		(click)="toggleChildren(); selectSubtreeClick(tree)"
		class="svg-accent-button"
	>
		<!-- Children are showing show hide option -->
		<svg:g
			*ngIf="tree.childrenShow"
			[attr.transform]="this.nodeRotateCCWhenLandscape"
		>
			<svg:circle
				cx="0"
				cy="0"
				r="14"
				class="svg-un-selected-button"
			></svg:circle>
			<svg
				fill="#000"
				height="24"
				viewBox="0 0 24 24"
				width="24"
				x="-12"
				y="-12"
				style="stroke-width: 0"
			>
				<path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
			</svg>
			<title>hide children</title>
		</svg:g>

		<!-- Children are hiding , display show option -->
		<svg:g
			*ngIf="!tree.childrenShow"
			[attr.transform]="this.nodeRotateCCWhenLandscape"
		>
			<svg:circle cx="0" cy="0" r="14"></svg:circle>
			<svg
				height="24"
				viewBox="0 0 24 24"
				width="24"
				x="-12"
				y="-12"
				class="svg-accent-button-icon"
				style="stroke-width: 1"
			>
				<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
			</svg>
			<title>show children</title>
		</svg:g>
	</svg:g>

	<!-- If hiding subtree children , indicate they exist with elippsis -->
	<svg:g
		*ngIf="!tree.childrenShow && hasSubTrees"
		(mouseup)="toggleChildren(); selectSubtreeClick(tree)"
		class="hiddenChildren"
		[attr.transform]="
			this.nodeSubTreeTranslate + this.nodeRotateCCWhenLandscape
		"
	>
		<!-- Vertical line from this node line down -->
		<svg:g class="hiddenChildrenCircles">
			<title>show children</title>
			<svg:circle cx="0" [attr.cy]="24" r="4" />
			<svg:circle cx="0" [attr.cy]="34" r="4" />
			<svg:circle cx="0" [attr.cy]="44" r="4" />
		</svg:g>

		<!-- Warning icon, show if one of our children has a warning but is hidden. -->
		<svg:g
			*ngIf="this.subTreesHaveWarning"
			[attr.transform]="'translate(0 ' + this.treeConfig.nodeLevelGap + ')'"
		>
			<svg:g
				[attr.transform]="this.nodeRotateWhenLandscape + ' translate(-12 -12)'"
			>
				<title>Parts of the sub tree has warnings. Expand for detail.</title>
				<path d="M1 21h22L12 2 1 21z" class="svg-warning-icon" />
				<path
					d="M13 18h-2v-2h2v2zm0-4h-2v-4h2v4z"
					class="svg-no-border"
					style="fill: #000000"
				/>
			</svg:g>
		</svg:g>
	</svg:g>

	<!-- Warning icon, show if this specific node has a warning : Note we do this here instead of in the node detail as we want to show it when the tree is collapsed and detail is not displayed  -->
	<svg:g
		*ngIf="this.tree.node.hasWarning"
		[attr.transform]="this.nodeBottomLeftTranslate"
	>
		<svg:g transform="translate(-12 -12)">
			<title>{{ this.tree.node.message }}</title>
			<path d="M1 21h22L12 2 1 21z" class="svg-warning-icon" />
			<path
				d="M13 18h-2v-2h2v2zm0-4h-2v-4h2v4z"
				class="svg-no-border"
				style="fill: #000000"
			/>
		</svg:g>
	</svg:g>
</svg:g>
