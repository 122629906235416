/**
 * Component showing health and throughput of the aHub system.
 */
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import {
	delay,
	distinct,
	distinctUntilChanged,
	filter,
	map,
	mergeMap,
	startWith,
	switchMap,
	takeUntil,
	tap,
} from "rxjs/operators";
import {
	notificationRecords,
	sessionClientId,
	sessionUserId,
} from "selector/app.selector";

/**
 * Store and selectors
 */
import { StoreAccess } from "store/store-access";

/**
 * Value Objects
 */
import { NotificationRecordVO } from "valueObjects/app/notification-record.vo";
import { Utils } from "../../utils";

@Component({
	selector: "app-system-health",
	templateUrl: "./system-health.component.html",
	styleUrls: ["./system-health.component.scss"],
})
@Hark()
export class SystemHealthComponent implements OnInit, OnDestroy {
	private readonly styleInactiveActivityCenter = "inactive-activity-center";
	private readonly styleActiveActivityCenter = "active-activity-center";

	@ViewChild("activityCenter", { static: true }) activityCenter;
	@ViewChild("toastContainer") toastContainer;

	/**
	 * This is the limit of how many activity toasts display
	 */
	toastLimit = 3;

	/**
	 * General booleans for UX UI toggles
	 */
	clearedToasts = false;
	fullActivity = false;
	hideToasts = false;
	minimized = true;

	/**
	 * Create a observable watching the current client id.
	 */
	currentClientId$: Observable<number> = StoreAccess.dataGetObvs(
		sessionClientId
	).pipe(distinctUntilChanged());

	/**
	 * Observable list of notification records from the store.
	 */
	notificationRecords$: Observable<NotificationRecordVO[]> =
		StoreAccess.dataGetObvs(notificationRecords).pipe(
			takeUntil(componentDestroyStream(this)),
			map((notifications) => {
				return notifications.sort((a, b) => {
					return a.notificationTime.valueOf() > b.notificationTime.valueOf()
						? -1
						: 1;
				});
			}),
			Utils.isNotNullOrUndefined()
		);

	/**
	 * Returns the count of the current items in the queue.
	 */
	queueCount$: Observable<number> = combineLatest([
		this.notificationRecords$.pipe(
			takeUntil(componentDestroyStream(this)),
			Utils.isNotNullOrUndefined()
		),
		this.currentClientId$,
	]).pipe(
		map(([activityNotificationRecords, currentClientId]) => {
			return activityNotificationRecords.filter(
				(record) =>
					record.notificationType === "Workflow" &&
					record.notificationObject.worklogs.find(
						(worklog) =>
							worklog.startTime === undefined &&
							worklog.clientId === currentClientId
					)
			).length;
		})
	);

	/**
	 * Returns a stream of the current active notifications
	 */
	activeNotificationRecords$ = combineLatest([
		this.currentClientId$,
		this.notificationRecords$.pipe(Utils.isNotNullOrUndefined()),
	]).pipe(
		map(([currentClientId, activityNotificationRecords]) => {
			return activityNotificationRecords.filter((record) => {
				return (
					record.notificationType === "Workflow" &&
					!record.notificationObject.complete &&
					record.notificationObject.worklogs.find(
						(worklog) => worklog.clientId === currentClientId
					)
				);
			});
		})
	);

	/**
	 * Active Toast Notifications Non Worklogs
	 */
	activeToastNotificationsNonWorklogs$ = this.notificationRecords$.pipe(
		mergeMap((activityNotificationRecords) => activityNotificationRecords),
		distinct((notification) => notification.notificationId),
		filter((notification) => notification.notificationType !== "Workflow"),
		Utils.isNotNullOrUndefined()
	);

	/**
	 * Returns a stream of the finished notifications
	 */
	finishedNotificationRecords$: Observable<NotificationRecordVO[]> =
		this.notificationRecords$.pipe(
			takeUntil(componentDestroyStream(this)),
			Utils.isNotNullOrUndefined(),
			map((records) => {
				return records.filter(
					(record) =>
						record.notificationObject.complete ||
						record.notificationObject.fault ||
						record.notificationType === "General"
				);
			})
		);

	/**
	 * Creates a stream used for the toasts
	 */
	onScreenNotificationRecordsWorkLogs: ObservableWorklogNotification[] = [];
	onScreenNotificationRecordsNoneWorkLogs: Observable<NotificationRecordVO>[] =
		[];
	onScreenNotificationRecordsTheirs: ObservableWorklogNotification[] = [];

	/**
	 * Creates array for the finished items
	 */
	finishedItems: NotificationRecordVO[] = [];

	/**
	 * Creates array for the active items
	 */
	activeItems: NotificationRecordVO[] = [];

	/**
	 * Last time user looked at the notifications
	 */
	notificationLastSeen$: BehaviorSubject<Date> = new BehaviorSubject(
		new Date()
	);

	/**
	 * Last time client changed
	 */
	clientLastChanged$: BehaviorSubject<Date> = new BehaviorSubject(new Date());

	lastSeenNotificationCount = 0;

	notificationsCountAfterLastSeen$ = combineLatest([
		this.notificationRecords$,
		this.notificationLastSeen$.asObservable().pipe(startWith()),
		this.currentClientId$,
	]).pipe(
		map(([records, lastSeen, currentClientId]) => {
			const result = records.filter((record) => {
				if (!record.notificationObject.worklogs) {
					return false;
				}
				return (
					record.notificationTime.getTime() >= lastSeen.getTime() &&
					record.notificationObject.worklogs.find(
						(worklog) => worklog.clientId === currentClientId
					)
				);
			});
			this.lastSeenNotificationCount = result.length;
			return result.length > 0;
		})
	);

	/**
	 * Notifications about workflows.
	 */
	notificationsRecordsWork$: Observable<NotificationRecordVO[]> =
		this.notificationRecords$.pipe(
			map((notificationRecords) =>
				notificationRecords.filter(
					(notificationRecord) =>
						notificationRecord.notificationType === "Workflow"
				)
			)
		);

	/**
	 * Notifications about actions ( requests to do something )
	 */
	notificationsRecordsAction$: Observable<NotificationRecordVO[]> =
		this.notificationRecords$.pipe(
			map((notificationRecords) =>
				notificationRecords.filter(
					(notificationRecord) =>
						notificationRecord.notificationType === "Action"
				)
			)
		);

	/**
	 * General notifications ( portal is trying to tell you something ! )
	 */
	notificationsRecordsGeneral$: Observable<NotificationRecordVO[]> =
		this.notificationRecords$.pipe(
			map((notificationRecords) =>
				notificationRecords.filter(
					(notificationRecord) =>
						notificationRecord.notificationType === "General"
				)
			)
		);

	constructor() {
		//Not required yet
	}

	ngOnInit() {
		/**
		 * Reset the notifications when the client changes.
		 */
		this.currentClientId$.subscribe((reset) => {
			this.finishedItems = [];
			this.onScreenNotificationRecordsWorkLogs = [];
			this.onScreenNotificationRecordsTheirs = [];
			this.activeItems = [];
			this.clientLastChanged$.next(new Date());
		});

		/**
		 * Updates the data to display the toasts
		 *
		 */
		this.currentClientId$
			.pipe(
				switchMap((clientId) => {
					return this.notificationRecords$.pipe(
						mergeMap(
							(activityNotificationRecords) => activityNotificationRecords
						),
						distinct((notification) => notification.notificationId),
						filter(
							(notification) => notification.notificationType === "Workflow"
						),
						filter((notification) => {
							return (
								notification.notificationObject.worklogs.find(
									(worklog) => worklog.clientId === clientId
								) ||
								notification.notificationObject.worklogs.find(
									(worklog) =>
										worklog.userId === StoreAccess.dataGet(sessionUserId)
								)
							);
						}),
						Utils.isNotNullOrUndefined()
					);
				}),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((worklogNotification) => {
				const userId = StoreAccess.dataGet(sessionUserId);

				// Is it the current session users notification?
				const worklogForUserId =
					worklogNotification.notificationObject.worklogs.find(
						(worklogRecord) => worklogRecord.userId === userId
					);

				const worklog = worklogNotification.notificationObject.worklogs.find(
					(worklogRecord) => worklogRecord
				);

				// Create an observable notification for the notification lists
				const observableWorkflowNotification = this.notificationRecords$.pipe(
					Utils.isNotNullOrUndefined(),
					map((allRecords) => {
						return allRecords.find((record) => {
							if (!record.notificationObject.worklogs) {
								return false;
							}
							return (
								record.notificationId === worklogNotification.notificationId
							);
						});
					}),
					delay(0)
				);

				// Create value object for the notification lists
				const observableWorklogNotification: ObservableWorklogNotification = {
					observableNotification: observableWorkflowNotification,
					userId: worklogNotification.notificationObject.userId,
				};

				if (
					!worklog.endTime ||
					this.clientLastChanged$.value.getTime() < worklog.endTime.getTime()
				) {
					// Add worklogs to correct lists
					if (worklogForUserId) {
						this.onScreenNotificationRecordsWorkLogs.push(
							observableWorklogNotification
						);
					} else {
						this.onScreenNotificationRecordsTheirs.push(
							observableWorklogNotification
						);
					}
				}

				// Update worklog list with other user notifications if there is space.
				this.updateListWithOtherUserWorklogs();
			});

		/**
		 * None worklog Notification Records
		 */
		this.activeToastNotificationsNonWorklogs$.subscribe(
			(notificationStream) => {
				this.onScreenNotificationRecordsNoneWorkLogs.push(
					of(notificationStream)
				);
			}
		);

		/**
		 * Finished Notification Records
		 */
		this.finishedNotificationRecords$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				mergeMap((notificationRecordsFinished) => notificationRecordsFinished),
				distinct((notification) => notification.notificationId),
				Utils.isNotNullOrUndefined()
			)
			.subscribe((records) => {
				this.finishedItems.unshift(records);
			});

		/**
		 * Active Notification Records
		 */
		this.activeNotificationRecords$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				Utils.isNotNullOrUndefined()
			)
			.subscribe((activityNotificationRecords) => {
				this.activeItems = activityNotificationRecords;
			});
	}

	/**
	 * Toggles the activity window
	 */
	toggleNotifications() {
		const active = this.activityCenter.nativeElement.classList.contains(
			this.styleActiveActivityCenter
		);
		if (active) {
			this.notificationLastSeen$.next(new Date());
			this.activityCenter.nativeElement.classList.remove(
				this.styleActiveActivityCenter
			);
			this.activityCenter.nativeElement.classList.add(
				this.styleInactiveActivityCenter
			);
			if (!this.hideToasts) {
				this.toastContainer.nativeElement.classList.add("fadeInElement");
				this.toastContainer.nativeElement.classList.remove("fadeOut");
			}
		} else {
			this.fullActivity = false;
			this.activityCenter.nativeElement.classList.remove(
				this.styleInactiveActivityCenter
			);
			this.activityCenter.nativeElement.classList.add(
				this.styleActiveActivityCenter
			);
			if (!this.hideToasts) {
				this.toastContainer.nativeElement.classList.remove("fadeInElement");
				this.toastContainer.nativeElement.classList.add("fadeOut");
			}
		}
		this.minimized = !this.minimized;
	}

	/**
	 * closes the activity window
	 */
	closeActivityCenter() {
		this.notificationLastSeen$.next(new Date());
		this.activityCenter.nativeElement.classList.remove(
			this.styleActiveActivityCenter
		);
		this.activityCenter.nativeElement.classList.add(
			this.styleInactiveActivityCenter
		);
		if (!this.hideToasts) {
			this.toastContainer.nativeElement.classList.add("fadeInElement");
			this.toastContainer.nativeElement.classList.remove("fadeOut");
		}
		this.minimized = true;
	}

	/**
	 * Removes the notification from a specified list
	 * @param notification
	 * @param recordsList
	 */
	removeNotification(notification, recordsList) {
		const index = recordsList.indexOf(notification);
		if (index >= 0) {
			recordsList.splice(index, 1);
		}
		this.updateListWithOtherUserWorklogs();
	}

	/**
	 * Toggles all toasts on and off
	 */
	hideToastsToggle() {
		if (this.hideToasts) {
			this.hideToasts = false;
		} else {
			this.toastContainer.nativeElement.classList.remove("fadeInElement");
			this.toastContainer.nativeElement.classList.add("fadeOut");
			this.hideToasts = true;
		}
	}

	/**
	 * Clears all of the notification toasts.
	 */
	clearAllNotificationToasts() {
		this.notificationLastSeen$.next(new Date());
		this.clearedToasts = true;
		setTimeout(() => {
			this.onScreenNotificationRecordsWorkLogs = [];
			this.onScreenNotificationRecordsTheirs = [];
			this.clearedToasts = false;
		}, 500);
	}

	/**
	 * Toggles view between recent activities and current activities.
	 */
	toggleActivity() {
		this.notificationLastSeen$.next(new Date());
		this.fullActivity = !this.fullActivity;
	}

	/**
	 * Update Toast list with other user notifications if the allocated slot for these is free.
	 */
	updateListWithOtherUserWorklogs() {
		// Sort so that the other users item appears at the top.
		const userId = StoreAccess.dataGet(sessionUserId);
		const otherUserWorklogs = this.onScreenNotificationRecordsTheirs;
		const hasOtherUsersWorklog = this.onScreenNotificationRecordsWorkLogs.find(
			(notification) => {
				return notification.userId !== userId;
			}
		);

		if (hasOtherUsersWorklog) {
			return;
		}

		if (otherUserWorklogs.length > 0) {
			this.onScreenNotificationRecordsWorkLogs.push(otherUserWorklogs.shift());
		}

		this.onScreenNotificationRecordsWorkLogs.sort((a, b) => {
			return a.userId !== userId ? -1 : 1;
		});
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}
}

/**
 * Observable Worklog Notification Value Object
 */
export interface ObservableWorklogNotification {
	observableNotification: Observable<NotificationRecordVO>;
	userId: number;
}
