import {
	Component,
	ContentChild,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { Utils } from "../../utils";

@Component({
	selector: "app-select-with-search",
	templateUrl: "./select-with-search.component.html",
	styleUrls: ["./select-with-search.component.css"],
})
export class SelectWithSearchComponent implements OnInit, OnChanges {
	@ContentChild("selectOption") optionTemplate: TemplateRef<any>;

	// search input field, allows us to give it focus
	@ViewChild("searchInput", { static: true }) searchInput;

	@Input() placeholder: string = "PLACEHOLDER NOT SET!";

	/**
	 * The form search input control name.
	 */
	@Input() selectFormControl: FormControl = undefined;

	/**
	 * Optional variable to hold selected option id if we dont want to use this conmponent with a form control (see above).
	 */
	@Input() set selectNgModel(selectNgModel) {
		this._selectNgModel = selectNgModel;
		if (this.selectFormControl) {
			this.selectFormControl.setValue(selectNgModel, { emitEvent: false });
			if (this.disabled) {
				this.selectFormControl.disable();
			}
			this.selectedOption = this.selectOptions.find(
				(option) => option.id === this.selectFormControl.value
			);
		}
	}

	_selectNgModel;

	@Input() disabled?: boolean = false; //Used to disable this input if we are not using form input (e.g. using selectNgModel)
	@Output() selectedValueChanged: EventEmitter<any> = new EventEmitter();

	/**
	 * function provided by parent which returns the value we should search against for specified option (object)
	 */
	@Input() searchableOptionValueFunction;

	/**
	 * function provided by parent which returns the value we should set when an option is selected (e.g. id)
	 */
	@Input() selectedValueFunction;

	// Sort Alphabetically
	//@Input() sortAlphabetically = false;

	/**
	 * Input which holds all the available options
	 */
	@Input() selectOptions: any[] = [];

	_disabledOptionIds: number[];
	_disabledOptionsByIdWithReason: Map<number, string> = new Map();
	@Input() set disabledOptionsByIdWithReason(
		disabledOptions: Map<number, string>
	) {
		if (disabledOptions) {
			this._disabledOptionIds = Array.from(disabledOptions.keys());
			this._disabledOptionsByIdWithReason = disabledOptions;
		}
	}

	// Used to help display the selected option in the same way as the options in the list
	selectedOption: any;

	allSelectOptions: any[];

	constructor(private readonly formBuilder: FormBuilder) {}

	ngOnInit() {
		// If we dont have a form control for this select, we can use an ng model approach
		// Internally we will still use a form control, but when the selection changes we will emit an event with the new id
		if (this._selectNgModel !== undefined) {
			this.selectFormControl = new FormControl({
				value: this._selectNgModel,
				disabled: this.disabled,
			});
		}

		if (this.selectFormControl.value) {
			// Initialise selected option
			this.selectedOption = this.selectOptions.find(
				(option) => option.id === this.selectFormControl.value
			);
		}

		this.selectFormControl.valueChanges.pipe().subscribe((value) => {
			// Lets grab an 'option' by the id of the selected option, such that the view of a selected option looks like the drop down options.
			// (see mat-select-trigger)
			this.selectedOption = this.findOption();

			this.selectedValueChanged.emit(this.selectedOption);
		});
	}

	findOption() {
		return this.selectOptions.find((option) =>
			!option ? false : option.id === this.selectFormControl.value
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["selectOptions"]) {
			// Lets keep a copy of 'all' options we could return to after a search term is removed
			// if(this.sortAlphabetically){
			//  this.allSelectOptions = Utils.clone(this.selectOptions.sort((a,b) => a.property.label > b.property.label ? 1 : -1) );
			// }else{
			this.allSelectOptions = Utils.clone(this.selectOptions);

			// Oh the options have changed, lets find the currently set form value and assign the selectedOption
			if (this.selectFormControl && this.selectOptions) {
				this.selectedOption = this.findOption();
				// this.selectedValueChanged.emit(this.selectedOption)
			}
			//}
		}
	}

	focusSearchInput() {
		this.searchInput.nativeElement.value = "";
		this.updateOptionsForSearchTerm(this.searchInput);
		setTimeout(() => {
			this.searchInput.nativeElement.focus();
		}, 100);
	}

	searchTermChanged($event) {
		if ($event.key === "ArrowUp" || $event.key === "ArrowDown") {
			return;
		}

		const searchTerm = $event.target.value;

		this.updateOptionsForSearchTerm(searchTerm);
	}

	updateOptionsForSearchTerm(searchTerm: string) {
		if (searchTerm.length > 0) {
			const inputValueInLowerCase = searchTerm.toLowerCase();

			this.selectOptions = this.allSelectOptions.filter((option) => {
				const searchableValue = this.searchableOptionValueFunction(option);
				return searchableValue
					.toString()
					.toLowerCase()
					.includes(inputValueInLowerCase);
			});
		} else {
			// no search term, lets put the whole list back
			this.selectOptions = Utils.clone(this.allSelectOptions);
		}
	}

	shouldOptionBeDisabled(option) {
		let disable = false;

		if (this._disabledOptionIds) {
			const optionId = this.selectedValueFunction(option);

			disable = this._disabledOptionIds.includes(optionId);
		}
		return disable;
	}
}
