import { AppGlobalErrorHandler } from "app/app-global-error-handler";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
	name: "GAMMA",
	clusterId: "gamma",
	production: true,
	aHubApi: {
		domain: "https://api.gamma.ahub.harksolutions.net",
		basePath: "/api/latest",
		aworkbookApiBasePath: "/app",
	},
	integrationApi: {
		domain: "https://api.gamma.ahub.harksolutions.net",
		basePath: "/integration",
	},
	knowledgeBasePath: "https://support.ahub.cloud",
	errorHandler: AppGlobalErrorHandler,
};

console.log("GAMMA");
