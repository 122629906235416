<div
	#infiniteListContainer
	class="full-screen"
	(scroll)="updateScrollView(true)"
	style="overflow-x: hidden; overflow-y: auto; padding-bottom: 20px"
>
	<div
		#infiniteList
		class="col-12 pb60"
		[ngClass]="{
			largeGrid: (largeGrid$ | async) && isGrid,
			smallGrid: !(largeGrid$ | async) && isGrid,
			col: !isGrid
		}"
		class="oh"
	>
		<div
			*ngFor="
				let data of this.dataArray | async | slice: this.viewStart:this.viewEnd;
				trackBy: trackById
			"
			class="scrollerItem"
		>
			<app-injected-wrapper
				*ngIf="componentFactory != undefined"
				[componentFactory]="componentFactory"
				[dataVO]="data"
				[paramName]="componentVOParamName"
				class="col-12"
			>
			</app-injected-wrapper>
		</div>
	</div>
</div>
