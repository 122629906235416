<!-- Activity Toasts Container -->
<div class="activity-toasts" #toastContainer>
	<div class="col-12 col">
		<!-- Worklog Toasts -->
		<div
			*ngFor="
				let notification of onScreenNotificationRecordsWorkLogs;
				let i = index
			"
		>
			<div class="toast-container" *ngIf="i < toastLimit">
				<app-toast
					(close)="
						removeNotification(
							notification,
							onScreenNotificationRecordsWorkLogs
						)
					"
					class="flex col pt15"
					[notification$]="notification.observableNotification"
				>
				</app-toast>
			</div>
		</div>
		<!-- Worklog Toasts -->
		<!-- General Toasts -->
		<div
			*ngFor="
				let notification$ of onScreenNotificationRecordsNoneWorkLogs;
				let i = index
			"
		>
			<div class="toast-container">
				<app-toast
					(close)="
						removeNotification(
							notification$,
							onScreenNotificationRecordsNoneWorkLogs
						)
					"
					class="flex col"
					[notification$]="notification$"
				>
				</app-toast>
			</div>
		</div>
		<!-- General Toasts-->
		<!-- Activity Button Buttons -->
		<div class="activity-buttons row align-center justify-end pr10">
			<!-- Clear Toasts Button-->
			<div
				class="activity-button pointer"
				[ngClass]="{ mr10: onScreenNotificationRecordsWorkLogs.length > 2 }"
				(click)="clearAllNotificationToasts()"
				*ngIf="onScreenNotificationRecordsWorkLogs.length > 2"
			>
				<span>Clear</span>
				<mat-icon>close</mat-icon>
			</div>
			<!-- Clear Toasts Button-->
			<!-- View More Activity Button -->
			<div
				class="activity-button pointer"
				(click)="toggleNotifications()"
				*ngIf="activeItems?.length > toastLimit"
			>
				Click to view {{ activeItems?.length - toastLimit }} more item<span
					*ngIf="activeItems?.length - toastLimit > 1"
					>s</span
				>
			</div>
			<!-- View More Activity Button -->
		</div>
		<!-- Activity Button Buttons -->
	</div>
</div>
<!-- Activity Toasts Container -->
<!-- Activity Center Button -->
<button (blur)="closeActivityCenter()" class="ml20">
	<div
		class="activity-toggle"
		(click)="toggleNotifications()"
		[ngClass]="{ active: !minimized }"
	>
		<!-- Notification Icon -->
		<i
			aria-hidden="true"
			class="notranslate bi bi-bell-fill"
			*ngIf="!(notificationsCountAfterLastSeen$ | async)"
		></i>

		<i
			aria-hidden="true"
			*ngIf="notificationsCountAfterLastSeen$ | async"
			class="bi bi-bell-fill"
			matBadge="!"
			matBadgeColor="accent"
			matBadgeSize="small"
			matBadgeOverlap="true"
		></i>
		<!-- Notification Icon -->
		<!-- Queue -->
		<div
			class="activity-queue nowrap row align-center justify-center"
			[ngClass]="{
				'active-activity-queue': !minimized || (queueCount$ | async) > 0
			}"
		>
			<mat-icon
				matBadgeSize="small"
				*ngIf="(queueCount$ | async) > 0"
				svgIcon="clock-fast"
				[matTooltip]="'Queue'"
				class="active-activity-icon"
				matBadgeOverlap="true"
				matBadge="{{ queueCount$ | async }}"
				matBadgeColor="accent"
			>
			</mat-icon>
			<mat-icon
				matBadgeSize="small"
				*ngIf="(queueCount$ | async) < 1"
				class="disabled-queue"
				[matTooltip]="'Queue'"
				svgIcon="clock-fast"
			>
			</mat-icon>
		</div>
		<!-- Queue -->
		<!-- Title -->
		<div
			class="flex-1 activity-title flex pl5 nowrap pr10 align-center justify-center"
		>
			Activity Centre
		</div>
		<!-- Title -->
	</div>
	<!-- Activity Center Button -->
	<!-- Activity Center -->
	<div class="activity-center" #activityCenter>
		<!-- Activity Top Bar -->
		<div class="col-12 row align-center">
			<!-- Activity Tabs -->
			<div class="flex align-center flex-1 tab-buttons">
				<div>
					<div
						class="tab-button pointer"
						(click)="toggleActivity()"
						*ngIf="fullActivity"
					>
						Current Activity
					</div>
					<div
						class="tab-button active"
						*ngIf="!fullActivity"
						matBadgeColor="accent"
					>
						Current Activity
					</div>
				</div>
				<div>
					<div
						class="tab-button pointer"
						(click)="toggleActivity()"
						*ngIf="!fullActivity"
						matBadgeOverlap="true"
						matBadge="!"
						[matBadgeHidden]="
							lastSeenNotificationCount - activeItems.length < 1
						"
						matBadgeColor="accent"
					>
						History
					</div>
					<div
						class="tab-button active"
						*ngIf="fullActivity"
						matBadgeOverlap="true"
						matBadge="!"
						matBadgeColor="accent"
						[matBadgeHidden]="
							lastSeenNotificationCount - activeItems.length < 1
						"
					>
						History
					</div>
				</div>
			</div>
			<!-- Activity Tabs -->
			<!-- Live Feed Toggle -->
			<div class="row align-center feed-toggle">
				<span (click)="hideToastsToggle()" class="pr5"
					>Hide onscreen notification pop ups</span
				>
				<mat-icon (click)="hideToastsToggle()" *ngIf="!hideToasts"
					>check_box_outline_blank</mat-icon
				>
				<mat-icon
					(click)="hideToastsToggle()"
					*ngIf="hideToasts"
					class="mat-icon-active"
					>check_box</mat-icon
				>
			</div>
			<!-- Live Feed Toggle -->
		</div>
		<!-- Activity Top Bar -->
		<div class="activity-tabs">
			<!-- Recent History -->
			<div class="col-12 col activity-tab" *ngIf="!fullActivity">
				<div class="col-12 col scroll-y inner-activity-tab">
					<div class="col-12 col" *ngIf="activeItems?.length > 0">
						<div
							*ngFor="let notificationRecord of activeItems"
							class="col-12 col"
						>
							<app-notification-record
								class="col-12 col"
								[notificationRecord]="notificationRecord"
							>
							</app-notification-record>
						</div>
					</div>
					<div class="no-activity" *ngIf="activeItems?.length < 1">
						No current activity
					</div>
				</div>
			</div>
			<!-- Recent History -->
			<!-- Full History -->
			<div class="col-12 col activity-tab" *ngIf="fullActivity">
				<div class="col-12 col scroll-y inner-activity-tab">
					<div class="col-12 col" *ngIf="finishedItems">
						<div
							*ngFor="let notificationRecord of finishedItems"
							class="col-12 col"
						>
							<app-notification-record
								class="col-12 col"
								*ngIf="notificationRecord"
								[notificationRecord]="notificationRecord"
							>
							</app-notification-record>
						</div>
					</div>
					<div class="no-activity" *ngIf="finishedItems.length < 1">
						No recent activity
					</div>
				</div>
			</div>
			<!-- Full History -->
		</div>
	</div>
</button>
<!-- Activity Center -->
