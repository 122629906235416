<button mat-icon-button [matMenuTriggerFor]="menu">
	<i
		aria-hidden="true"
		class="bi bi-three-dots-vertical"
		style="font-size: 19px; color: #8687a7"
	></i>
</button>

<mat-menu #menu="matMenu" class="tal">
	<button
		*ngFor="let menuItem of menuItems"
		mat-menu-item
		(click)="menuItem.menuItemClickHandler($event)"
		[disabled]="menuItem.disabled ? menuItem.disabled : false"
	>
		<span>{{ menuItem.label }}</span>
	</button>
</mat-menu>
