<button
	[ngClass]="{
		'toolbar-button-left': position === 'left',
		'toolbar-button-center': position === 'center',
		'toolbar-button-right': position === 'right',
		'toolbar-button-light': this.isBusy == true
	}"
	[matBadge]="badgeValue$ | async"
	class="toolbar-button toolbar-button-left"
	(click)="buttonClickHandler()"
	[matTooltip]="this.buttonToolTip"
	[disabled]="this.isDisabled"
>
	<i
		aria-hidden="true"
		*ngIf="this.isBusy == false"
		class="notranslate bi {{ this.buttonIcon }} notranslate"
	></i>
	<mat-progress-spinner
		*ngIf="this.isBusy == true"
		class="fabSpinner"
		mode="indeterminate"
		color="accent"
		diameter="16"
	>
	</mat-progress-spinner>
</button>
