<!-- Notification -->
<div class="col-12 notificationItem align-center">
	<!-- Loading Bars -->
	<!-- In Progress -->
	<div
		class="loadingBar"
		*ngIf="this.notificationUploadCurrentDataStream$ | async"
	>
		<div
			class="loadingBarInner"
			[ngStyle]="{
				width:
					(this.notificationUploadCurrentDataStream$ | async)?.progress + '%'
			}"
		></div>
	</div>
	<!-- In Progress -->
	<!-- Complete -->
	<div
		class="loadingBar"
		*ngIf="!(this.notificationUploadCurrentDataStream$ | async)"
	>
		<div class="loadingBarInner complete" style="width: 100%"></div>
	</div>
	<!-- Complete -->
	<!-- Failed -->
	<div
		class="loadingBar"
		*ngIf="
			this.notificationRecord?.notificationType == 'Action' ||
			this.notificationRecord?.notificationObject.failed
		"
	>
		<div class="loadingBarInner failed" style="width: 100%"></div>
	</div>
	<!-- Failed -->
	<!-- Loading Bars -->
	<div
		class="col-12 col pt10 flex-nowrap pb10 pl20 space-between align-center pr20"
	>
		<div class="row align-center space-bewteen col-12">
			<!-- Left Icons -->
			<div class="row justify-end" style="width: 60px">
				<!-- Progress Spinner  -->
				<div
					class="col align-start justify-center"
					style="width: 30px"
					*ngIf="
						notificationRecord?.notificationIcon === 'sync' && !showQueueIcon
					"
				>
					<mat-spinner diameter="18" strokeWidth="3"> </mat-spinner>
				</div>
				<!-- Progress Spinner -->
				<!-- Queue Icon -->
				<div
					class="col align-start justify-center"
					style="width: 30px"
					*ngIf="
						showQueueIcon && !this.notificationRecord?.notificationObject.failed
					"
				>
					<mat-icon
						class="cloud-queue"
						svgIcon="clock-fast"
						[matTooltip]="'Queue'"
					></mat-icon>
				</div>
				<!-- Queue Icon -->
				<!-- Warning Icon -->
				<div
					class="col align-start justify-center"
					style="width: 30px"
					*ngIf="
						this.notificationRecord?.notificationType == 'Action' &&
						notificationRecord?.notificationIcon !== 'sync'
					"
				>
					<mat-icon class="warning mr10"> warning </mat-icon>
				</div>
				<div
					class="row align-center justify-center"
					style="width: 30px"
					*ngIf="this.notificationRecord?.notificationObject.failed"
				>
					<mat-icon
						*ngIf="this.notificationRecord?.notificationObject.failed"
						class="warning mr10"
					>
						warning
					</mat-icon>
				</div>
				<!-- Warning Icon -->
				<!-- User Icon -->
				<div
					class="col align-start justify-center"
					style="width: 30px; background: #f6f9fd"
					*ngIf="(sessionUser$ | async)?.id !== this.workflowStatus?.userId"
				>
					<app-user-spot
						[size]="18"
						[userId$]="this.userIdObservableGet(this.workflowStatus?.userId)"
					>
					</app-user-spot>
				</div>
				<div
					class="col align-start justify-center"
					style="width: 30px; background: #f6f9fd"
					*ngIf="(sessionUser$ | async)?.id === this.workflowStatus?.userId"
				>
					<app-user-spot
						[size]="18"
						class="mr10"
						[user$]="sessionUser$"
						*ngIf="(sessionUser$ | async)?.id === this.workflowStatus?.userId"
					>
					</app-user-spot>
				</div>
				<!-- User Icon -->
			</div>
			<!-- Left Icons -->
			<!-- Chip Text -->
			<div
				class="actionText flex-1"
				[innerHtml]="this.notificationRecord?.notificationLabel"
			></div>
			<!-- Chip Text -->
			<!-- Progress -->
			<div
				class="actionText pl10 pr10"
				*ngIf="this.notificationUploadCurrentDataStream$ | async"
			>
				{{ (this.notificationUploadCurrentDataStream$ | async)?.progress }}%
				complete ({{ this.notificationUploadDataComplete$ | async }}/{{
					this.notificationUploadDataTotal$ | async
				}})
			</div>
			<div class="actionText pl20">
				{{
					this.padStart(
						this.notificationRecord?.notificationTime.getHours(),
						2,
						"0"
					)
				}}:{{
					this.padStart(
						this.notificationRecord?.notificationTime.getMinutes(),
						2,
						"0"
					)
				}}
			</div>
			<!-- Progress -->
			<!-- Code toggle -->
			<div
				class="col align-center justify-center flex pointer ml10"
				(click)="toggleRecord()"
			>
				<mat-icon *ngIf="!activeToggle">code</mat-icon>
				<mat-icon *ngIf="activeToggle">close</mat-icon>
			</div>
			<!-- Code toggle -->
		</div>
		<!-- Json Data -->
		<pre
			class="col-12 mt10"
			style="position: relative"
			*ngIf="activeToggle"
			[innerHtml]="this.notificationRecord | json"
		></pre>

		<button
			*ngIf="activeToggle"
			mat-fab
			color="primary"
			matTooltip="Copy message payload to clipboard"
			(click)="
				copyJsonToClipboard(this.notificationRecord); $event.stopPropagation()
			"
			style="position: absolute; bottom: 40px; right: 40px"
		>
			<mat-icon style="color: white">content_copy</mat-icon>
		</button>
		<!-- Json Data -->
	</div>
</div>
<!-- Notification -->
