import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppActions } from "actions/app.actions";
import { AppRoutingNavigation } from "app/app-routing-navigation";
import { RequestActionMonitorService } from "app/services/request-action-monitor/request-action-monitor.service";
import { AHubActions } from "app/store/actions/ahub.actions";
import { ViewActions } from "app/store/actions/view.actions";
import { aHubStateTemporaryUserMetaData } from "app/store/selector/ahub/ahub-temporary.selector";
import { EntityPermissions } from "app/valueObjects/ahub/accounts/entity-permissions.ahub";
import { UserExtendedAHubVO } from "app/valueObjects/ahub/accounts/user-extended.ahub.vo";
import { UserMetaDataAHubVO } from "app/valueObjects/ahub/accounts/user-meta-data.ahub.vo";
import { RequestActionStatusVO } from "app/valueObjects/app/request-action-status.vo";
import { componentDestroyStream, Hark } from "modules/common/hark.decorator";
import { BehaviorSubject, Observable, timer } from "rxjs";
import {
	debounceTime,
	distinctUntilKeyChanged,
	filter,
	first,
	takeUntil,
} from "rxjs/operators";
import { StoreAccess } from "store/store-access";
import { UserAHubVO } from "valueObjects/ahub/accounts/user.ahub.vo";
import { DialogService } from "../../dialogs/dialog.service";
import { Utils } from "../../utils";

const EMAIL_REGEX =
	/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
	selector: "app-user-profile",
	templateUrl: "./user-profile.component.html",
	styleUrls: ["./user-profile.component.css"],
})
@Hark()
export class UserProfileComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private requestActionMonitorService: RequestActionMonitorService,
		private router: Router,
		private dialogService: DialogService
	) {}

	@Input() editable = false;

	@Input() user$: Observable<UserExtendedAHubVO> = undefined;

	/**
	 * This is the sessions expiry date.
	 */
	sessionExpiryDate: Date;

	/**
	 *  Subscribe to the request action status object that has an action id that matches this current id.
	 */
	actionRequestActionStatus$: Observable<boolean> = undefined;

	/**
	 * This is the form used to hold the user data.
	 */
	userForm: FormGroup;

	emailAddressChanged$: Observable<any>;

	emailAddressChangePending: boolean;

	waitingForVerificationCodeToBeEntered: boolean;

	pristineUser: UserAHubVO;

	verificationCode: string;

	verificationMessage;

	sendVerificationCodeAction$: Observable<RequestActionStatusVO>;
	verifyCodeAction$: Observable<RequestActionStatusVO>;

	formErrors = {
		email: "",
		firstName: "",
		lastName: "",
	};

	validationMessages = {
		email: {
			required: "Email is required.",
			pattern: "Please enter a valid email address (e.g. you@wherever.com)",
			// 'maxlength': 'Name cannot be more than 24 characters long.',
			// 'forbiddenName': 'Someone named "Bob" cannot be a hero.'
		},
		firstName: {
			required: "First name is required.",
		},
		lastName: {
			required: "Last name is required.",
		},
	};

	temporaryData$: BehaviorSubject<string> = new BehaviorSubject(undefined);
	metaData$: Observable<UserMetaDataAHubVO[]> = StoreAccess.dataGetObvs(
		aHubStateTemporaryUserMetaData
	);

	systemUser = EntityPermissions.SYSTEM_USER;

	showExtraInformation = false;

	ngOnInit() {
		// Go get the latest session info (including the current session expiry date)
		StoreAccess.dispatch(AppActions.sessionInfoFetch());

		this.userForm = this.userFormCreate();

		this.user$
			?.pipe(
				takeUntil(componentDestroyStream(this)),
				filter((user) => user !== undefined),
				distinctUntilKeyChanged("id")
			)
			?.subscribe((user) => {
				this.verificationCode = undefined;
				this.waitingForVerificationCodeToBeEntered = false;
				this.emailAddressChangePending = false;
				this.verificationMessage = undefined;
				this.pristineUser = user;
				/**
				 * The users temporary data.
				 */
				this.temporaryData$.next(JSON.stringify(user.temporaryData, null, 4));
				this.userFormPopulate(user);
				StoreAccess.dispatch(AHubActions.userMetaDataFetch(user.id));
			});

		this.userForm.valueChanges
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((data) => this.onValueChanged(data));

		this.emailAddressChanged$ = this.userForm.controls.email.valueChanges;

		this.emailAddressChanged$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				Utils.isNotNullOrUndefined(),
				debounceTime(300)
			)
			.subscribe((newEmailAddress) => {
				if (this.pristineUser && newEmailAddress !== this.pristineUser.email) {
					this.verificationMessage =
						"a new email address will require re-verification";
					this.emailAddressChangePending = true;
				}
			});

		this.onValueChanged();
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	userFormPopulate(user: UserAHubVO) {
		this.userForm.patchValue(user);
	}

	userFormCreate() {
		return this.formBuilder.group({
			firstName: [{ value: "", disabled: !this.editable }, Validators.required],
			lastName: [
				{ value: "", disabled: !this.editable },
				[Validators.required],
			],
			email: [
				{ value: "", disabled: !this.editable },
				[Validators.required, Validators.pattern(EMAIL_REGEX)],
			],
		});
	}

	onValueChanged(data?: any) {
		if (!this.userForm) {
			return;
		}
		const form = this.userForm;

		for (const field in this.formErrors) {
			// clear previous error message (if any)
			this.formErrors[field] = "";
			const control = form.get(field);

			if (control && control.dirty && !control.valid) {
				const messages = this.validationMessages[field];
				for (const key in control.errors) {
					this.formErrors[field] += messages[key] + " ";
				}
			}
		}
	}

	sendVerificationCode() {
		const actionId = StoreAccess.dispatch(
			AHubActions.sendEmailUpdateVerificationCode(
				this.userForm.controls.email.value,
				this.pristineUser.id
			)
		);

		// Get an Observable which will indicate if this action is busy or not
		this.sendVerificationCodeAction$ =
			this.requestActionMonitorService.requestActionStatusObservableByActionId(
				actionId
			);

		// Action request status
		this.sendVerificationCodeAction$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter(
					(sendVerificationCodeAction) =>
						sendVerificationCodeAction !== undefined &&
						sendVerificationCodeAction.status === "completed"
				),
				first()
			)
			.subscribe((sendVerificationCodeAction) => {
				if (!sendVerificationCodeAction.fault) {
					this.verificationMessage =
						"verification code sent to " +
						this.userForm.controls.email.value +
						". Please enter below";
					this.waitingForVerificationCodeToBeEntered = true;
				} else {
					this.verificationMessage = sendVerificationCodeAction.error;
				}
			});
	}

	verifyCode() {
		const actionId = StoreAccess.dispatch(
			AHubActions.verifyEmailUpdate(this.pristineUser.id, this.verificationCode)
		);

		// Get an Observable which will indicate if this action is busy or not
		this.verifyCodeAction$ =
			this.requestActionMonitorService.requestActionStatusObservableByActionId(
				actionId
			);

		// Action request status
		this.verifyCodeAction$
			.pipe(
				takeUntil(componentDestroyStream(this)),
				filter(
					(verifyCodeAction) =>
						verifyCodeAction !== undefined &&
						(verifyCodeAction.status === "completed" || verifyCodeAction.fault)
				),
				first()
			)
			.subscribe((verifyCodeAction) => {
				// Verification successful, lets tell the user and reset
				if (!verifyCodeAction.fault) {
					StoreAccess.dispatch(
						AHubActions.sessionUsersByIdsFetch([this.pristineUser.id]),
						true
					);
					this.verificationMessage = "Verification successful, email updated!";
					this.userForm.markAsPristine();
					timer(3000).subscribe((magicalDisappearingMessageTimer) => {
						this.emailAddressChangePending = false;
						this.waitingForVerificationCodeToBeEntered = false;
						this.verificationMessage = undefined;
						this.verificationCode = undefined;
					});
				} else {
					// Something went wrong, lets tell the user
					if (verifyCodeAction.errorCode === 404) {
						this.verificationMessage =
							"Verification Code incorrect, please check and try again";
					} else {
						this.verificationMessage = verifyCodeAction.error;
					}
				}
			});
	}

	userSaveHandler() {
		this.pristineUser.firstName = this.userForm.controls.firstName.value;
		this.pristineUser.lastName = this.userForm.controls.lastName.value;

		const actionId = StoreAccess.dispatch(
			AHubActions.userCommit(this.pristineUser)
		);

		// Get an Observable which will indicate if this action is busy or not
		this.actionRequestActionStatus$ =
			this.requestActionMonitorService.requestActionStatusObservableByActionIdBusy(
				actionId
			);

		// Action request status
		this.actionRequestActionStatus$
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((actionRequestActionStatus) => {
				if (actionRequestActionStatus) {
					this.userForm.markAsPristine();
				}
			});
	}

	cancel() {
		this.userFormPopulate(this.pristineUser);
		this.waitingForVerificationCodeToBeEntered = false;
		this.emailAddressChangePending = false;
		this.verificationMessage = undefined;
	}

	resetPassword() {
		// Ask the user if they are sure they want to reset their password
		let dialogRef: Observable<boolean> = this.dialogService.confirmDialogOpen(
			"Reset Password?",
			`Resetting your password log you out of aHub and generate an automated email containing a link.
       This link will take you to a page where you can create a new password. Until you have created a new password, your current password
       will still work. Are you sure you would like to reset your password?`,
			"Reset Password"
		);

		//Get the email address from the form
		let emailAddress = this.userForm.controls.email.value;

		// Subscribe to the returned value so we know when the user makes a decision.
		dialogRef
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe((result) => {
				// If the user selects reset password we will call the ahub to send an email with a verification link.
				if (result === true) {
					//Ask for a verification code for the user
					let actionId = StoreAccess.dispatch(
						AHubActions.sendVerificationCode(emailAddress)
					);

					// Set the action request observable
					let actionRequest$ =
						this.requestActionMonitorService.requestActionStatusObservableByActionId(
							actionId
						);

					//Wait till it's done!
					actionRequest$
						.pipe(takeUntil(componentDestroyStream(this)))
						.subscribe((status) => {
							//Did we fail to get a verification code?
							if (status.fault) {
								//Display a warning dialoue
								this.dialogService
									.alertDialogOpen(
										"Failed to get verification code",
										"Too many attempts made in a short period",
										"Please wait a while, then try again or contact your administrator for assitance"
									)
									.pipe(takeUntil(componentDestroyStream(this)))
									.subscribe();

								return;
							}

							// Call the clear session action.
							StoreAccess.dispatch(AppActions.sessionClear());

							// Clear the aHub permanent store and login.
							StoreAccess.dispatch(AHubActions.storePermanentClear());
							StoreAccess.dispatch(ViewActions.loginClear());
							//Navigate to the verification screen
							AppRoutingNavigation.navigateVerificationEmailSent(
								this.router,
								emailAddress
							);
						});
				}
			});
	}
}
