<ul [ngClass]="{ LevelTwo: depth === 1, LevelThree: depth > 1 }">
	<li
		*ngFor="let route of routes"
		[ngClass]="{
			hasChildren: route.routes !== undefined && route.routes.length > 0,
			LevelTwoItem: depth === 1,
			LevelThreeItem: depth > 1,
			ActiveLevelTwo: (selectedRouteView$ | async)?.routerLink?.includes(
				route.routerLink
			)
		}"
	>
		<a
			[routerLink]="route.routerLink"
			[ngClass]="{
				LevelTwoItemLink: depth === 1,
				LevelThreeItemLink: depth > 1,
				activeLink:
					depth > 1 &&
					(selectedRouteView$ | async)?.routerLink?.includes(route.routerLink)
			}"
			style="display: flex; align-items: center"
			*ngIf="!route.hideInMenu"
		>
			<i
				aria-hidden="true"
				class="bi {{ route.icon }}"
				*ngIf="
					!(selectedRouteView$ | async)?.routerLink?.includes(route.routerLink)
				"
			></i>
			<i
				aria-hidden="true"
				class="bi {{ route.iconActive }}"
				*ngIf="
					(selectedRouteView$ | async)?.routerLink?.includes(route.routerLink)
				"
			></i>

			<span>{{ route.name }}</span>
		</a>

		<input
			type="checkbox"
			*ngIf="route.routes !== undefined && route.routes.length > 0"
			class="MenuToggle"
			[checked]="
				(selectedRouteView$ | async)?.routerLink?.includes(route.routerLink)
			"
		/>

		<app-sidenav-route
			*ngIf="route.routes"
			[routes]="route.routes"
			[depth]="depth + 1"
			(closeNav)="closeNavEmit($event)"
		>
		</app-sidenav-route>
	</li>
</ul>
