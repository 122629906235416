<div style="max-width: 500px" class="col-12 col">
	<h1 matDialogTitle>{{ title }}</h1>
	<div class="col-12 col">
		<div class="col-12 col">
			<p>
				{{ content }}
			</p>
			<input
				#fileInput
				[multiple]="this.allowMultipleSelect ? true : null"
				[accept]="this.acceptAtributeValue"
				type="file"
				style="display: none"
				(change)="onChangeHandler()"
			/>

			<div
				class="flex-container fb-center clickable mb20"
				style="height: 100px; width: 100%"
				makedroppable
				(dropped)="this.onDrop($event)"
				(click)="this.chooseFilesHandler()"
			>
				Drag and drop here or click to select
			</div>
			<mat-chip-list class="col col-12 mb20" *ngIf="currentFileList.length > 0">
				<mat-chip
					disableRipple
					class="chip notranslate row flex-nowrap align-center justify-center"
					*ngFor="let file of this.currentFileList"
				>
					<span>{{ file.name }}</span>
					<button
						class="notranslate"
						mat-icon-button
						(click)="this.fileRemove(file)"
					>
						<mat-icon class="notranslate smallIcon">close</mat-icon>
					</button>
				</mat-chip>
			</mat-chip-list>
		</div>
	</div>
	<div class="col-12 justify-end align-end row">
		<app-raised-busy-button
			[buttonLabel]="cancelButtonLabel"
			class="mr10"
			(click)="dialogRef.close(false)"
		></app-raised-busy-button>
		<app-raised-busy-button
			[buttonLabel]="confirmButtonLabel"
			class="mr10"
			(click)="dialogRef.close(this.currentFileList)"
		></app-raised-busy-button>
	</div>
</div>
