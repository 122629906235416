/**
 * For virtually all lists we have a title bar with a search box form.
 * This has been abstracted to a common component and this is it.
 */
import {
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewChildren,
	ChangeDetectorRef,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EntityPermissionAHubVO } from "app/valueObjects/ahub/accounts/entity-permission.ahub.vo";
import { Observable, fromEvent, of, timer } from "rxjs";
import { FilterOptionVO } from "valueObjects/view/filter-option.view.vo";
import { SortOptionVO } from "valueObjects/view/sort-option.view.vo";
import { delay, take } from "rxjs/operators";
import { Hark } from "../../hark.decorator";
import { MatMenu } from "@angular/material/menu";

@Component({
	selector: "app-list-search-header",
	templateUrl: "./list-search-header.component.html",
	styleUrls: ["./list-search-header.component.css"],
})
@Hark()
export class ListSearchHeaderComponent implements OnInit {
	// Title for the searchable list header bar.
	@Input() listTitle: string;

	/**
	 * Font size of title (optional)
	 */
	@Input() titleFontSize: string;

	/**
	 * Font size of title (optional)
	 */
	@Input() displayItemCount: boolean;

	/**
	 * Add button busy observable stream
	 */
	@Input() addButtonBusy: Observable<boolean>;

	/**
	 * Delete button busy observable stream
	 */
	@Input() deleteButtonBusy: Observable<boolean>;

	/**
	 * Generic menu button busy observable stream
	 */
	@Input() menuBusy: Observable<boolean>;

	/**
	 * This is the current count of indexes in the list attached to this header.
	 */
	@Input() indexCount$: Observable<number>;

	/**
	 * This is the current count of fileted indexes in the list attached to this header.
	 */
	@Input() indexFilteredCount$: Observable<number>;

	// The form group the search button is linked to.
	@Input() listControlForm: FormGroup;

	/**
	 * This form controls the all filters option and is seperate
	 * to the other filters so that it doesn't get picked up above.
	 */
	allFiltersForm: FormGroup = this.formBuilder.group({
		All: [true],
	});

	/**
	 * The form controling the filter options.
	 */
	@Input() listFilterForm: FormGroup;

	/**
	 * The form search input control name.
	 */
	@Input() listFilterFormSearchInputControlName = "searchFormControl";

	/**
	 * The sort options to use.
	 */
	@Input() sortOptions: SortOptionVO[] = [];

	/**
	 * Are we displaying the all filters option?
	 */
	@Input() displayAllFilters = true;

	/**
	 * The filter options to use.
	 */
	@Input() filterOptions: FilterOptionVO[] = [];

	/**
	 * Knowledge base
	 */
	@Input() showKowledgeBaseIcon: boolean;
	@Input() knowledgeBasePagePath: string;

	// The search box.
	@ViewChildren("searchFormInput") searchFormInput;

	// Decides which buttons to display in the list menu
	@Input()
	set showDisable(v: boolean) {
		this._showDisable = v;
		this.updateMenuButtonShow();
	}

	@Input()
	set showDelete(a: boolean) {
		this._showDelete = a;
		this.updateMenuButtonShow();
	}

	@Input()
	set showEdit(a: boolean) {
		this._showEdit = a;
		this.updateMenuButtonShow();
	}

	@Input()
	set showAdd(a: boolean) {
		this._showAdd = a;
		this.updateMenuButtonShow();
	}

	@Input()
	set showSearch(a: boolean) {
		this._showSearch = a;
		this.updateMenuButtonShow();
	}

	_showDisable: boolean;

	_showDelete: boolean;

	_showEdit: boolean;

	_showAdd: boolean;

	_showSearch: boolean;

	// Set to same value as addButtonVisibleAs...if you can add, you can delete or disable
	@Input() addButtonVisibleAs: EntityPermissionAHubVO;

	@ViewChild("listButtonMenu") listButtonMenu: MatMenu;

	@ContentChildren("headerMenu", { read: ElementRef, descendants: true })
	injectedMenuItem;

	@ContentChildren("inHeader", { read: ElementRef, descendants: true })
	injectedHeader;

	headerMenuChanges: Observable<any> = of(undefined);

	@Output() disableButtonClick = new EventEmitter<string>();

	// Fires whenever the 'add' button is clicked
	@Output() addButtonClick: EventEmitter<void> = new EventEmitter<void>();

	// Fires whenever the 'delete' button is clicked
	@Output() deleteButtonClick: EventEmitter<void> = new EventEmitter<void>();

	// Fires whenever the 'edit' button is clicked
	@Output() editButtonClick: EventEmitter<void> = new EventEmitter<void>();

	// Check for menu buttons
	showButtonMenu = false;

	activeSort = "";

	/**
	 * Used top toggle the search on smaller screens
	 */
	@Output() showSearchClick: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly cdRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		// Do we have any sort options? If not, stop here.
		if (this.sortOptions.length === 0) {
			return;
		}

		// Get the current sort property.
		const sortPropertyName: string =
			this.listControlForm.controls["sortPropertyName"].value;

		// Do we have a current sort property? If so, stop here.
		if (sortPropertyName && sortPropertyName !== "") {
			return;
		}

		// Otherwise we want to get the first sort option and use it.
		const sortOption = this.sortOptions[0];

		// Set it.
		this.sortPropertySet(sortOption.sortProperty, sortOption.invertSort);

		// Set the filter on to true on all of the filter options.
		if (this.filterOptions && this.filterOptions.length > 0) {
			this.filterOptions.forEach((filterOption) =>
				this.listFilterForm.addControl(
					filterOption.label,
					this.formBuilder.control(filterOption.defaultValue)
				)
			);
		}

		// Update the all filters value.
		this.updateAllFiltersValue();
	}

	// Lets make sure the button menu is visible if the list is using the built in add/delete/edit buttons
	ngAfterViewInit() {
		this.updateMenuButtonShow();
	}

	// Lets make sure the button menu is visible if menu items have been injected by the list parent (ng-content)
	ngAfterContentInit(): void {
		// Lets handle revisting a page where nothing has actually changed
		this.updateMenuButtonShow();

		// Check if button menu has any buttons
		if (
			this.injectedMenuItem &&
			this.injectedMenuItem.first &&
			this.injectedMenuItem.first.nativeElement
		) {
			this.headerMenuChanges = fromEvent(
				this.injectedMenuItem.first.nativeElement,
				"DOMSubtreeModified"
			);
		}

		this.headerMenuChanges.pipe(delay(0)).subscribe((change) => {
			this.updateMenuButtonShow();
		});
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	/**
	 * This function will set the current sort property in the form.
	 * This will change the lists sorting.
	 */
	sortPropertySet(sortPropertyName: string, invertSort: boolean) {
		this.activeSort = sortPropertyName;
		this.listControlForm.controls["sortPropertyName"].setValue(
			sortPropertyName
		);
		this.listControlForm.controls["sortInverted"].setValue(invertSort);
	}

	searchToggleClicked($event) {
		this.showSearchClick.emit($event);
	}

	/**
	 * This function will update the all filters value.
	 */
	private updateAllFiltersValue() {
		// Look for an instance of a filter that's been turned off.
		const turnedOffFilter = this.filterOptions.find(
			(filterOption) => !this.listFilterForm.controls[filterOption.label].value
		);

		// Turn the All filter if we cannot find any that are off.
		this.allFiltersForm.controls["All"].setValue(turnedOffFilter === undefined);
	}

	/**
	 * This function will turn on all of the filters if one or more are unselected,
	 * otherwise it will turn them all off.
	 */
	filterAllToggle(event) {
		// Are there any filter options turned off?
		const turnedOffFilter = this.filterOptions.find(
			(filterOption) => !this.listFilterForm.controls[filterOption.label].value
		);

		// If so, we want to turn them all on, otherwise off.
		const targetValue: boolean = turnedOffFilter !== undefined;

		// Set the filter on to true on all of the filter options.
		if (this.filterOptions && this.filterOptions.length > 0) {
			this.filterOptions.forEach((filterOption) =>
				this.listFilterForm.controls[filterOption.label].setValue(targetValue)
			);
		}
		// Stop the box from closing.
		event.stopPropagation();
	}

	/**
	 * This function is called when the user clicks on the filter option.
	 *
	 * @param event       The event that caused this handler to be called.
	 */
	filterOptionClick(event) {
		// Stop the box from closing.
		event.stopPropagation();
	}

	/**
	 * This function is called when the toggle option value changes.
	 *
	 * @param filterOption
	 */
	filterOptionChangeHandler(filterOption) {
		// Make sure we update the all filters option.
		this.updateAllFiltersValue();
	}

	/**
	 * Update the menu button checking
	 */
	updateMenuButtonShow() {
		// We need a render to happen before we evaluate it
		timer(200)
			.pipe(take(1))
			.subscribe(() => (this.showButtonMenu = this.showMenuButtonCheck()));

		// this.showButtonMenu = this.showMenuButtonCheck()
	}

	/**
	 * Should we be showing the menu button
	 */
	showMenuButtonCheck() {
		//Do we have any of our pre-defined buttons in place
		if (
			this.listButtonMenu &&
			this.listButtonMenu._allItems &&
			this.listButtonMenu._allItems.length > 0
		) {
			return true;
		}

		//Checks the custom menu options
		return (
			this.injectedMenuItem &&
			this.injectedMenuItem.first &&
			this.injectedMenuItem.first.nativeElement &&
			this.injectedMenuItem.first.nativeElement.firstChild &&
			this.injectedMenuItem.first.nativeElement.firstChild.children &&
			this.injectedMenuItem.first.nativeElement.firstChild.children.length > 0
		);
	}

	addButtonClicked($event) {
		this.addButtonClick.emit($event);
	}

	deleteButtonClicked() {
		this.deleteButtonClick.emit();
	}

	editButtonClicked() {
		this.editButtonClick.emit();
	}

	disableButtonClicked() {
		this.disableButtonClick.emit();
	}
}
