<div class="container col" [formGroup]="resizeForm">
	<div class="col-12">
		<mat-form-field class="mr10" appearance="outline" class="col-12">
			<mat-label>Size</mat-label>
			<mat-select class="notranslate" formControlName="resizeDefaults">
				<mat-option [value]="'LARGE'">
					<div class="mat-option-container">
						<span
							class="notranslate"
							[ngClass]="{
								imageWarning:
									large > dialogVO.maxHeight || large > dialogVO.maxWidth
							}"
							>Large (2048px Max)</span
						>
					</div>
				</mat-option>
				<mat-option [value]="'MEDIUM'">
					<div class="mat-option-container">
						<span
							class="notranslate"
							[ngClass]="{
								imageWarning:
									medium > dialogVO.maxHeight || medium > dialogVO.maxWidth
							}"
							>Medium (1024px Max)</span
						>
					</div>
				</mat-option>
				<mat-option [value]="'SMALL'">
					<div class="mat-option-container">
						<span
							class="notranslate"
							[ngClass]="{
								imageWarning:
									small > dialogVO.maxHeight || small > dialogVO.maxWidth
							}"
							>Small (256px Max)</span
						>
					</div>
				</mat-option>
				<mat-option [value]="'ORIGINAL'">
					<div class="mat-option-container">
						<span class="notranslate"
							>Original ({{ dialogVO.maxWidth }}x{{
								dialogVO.maxHeight
							}}px)</span
						>
					</div>
				</mat-option>
				<mat-option [value]="'CUSTOM'">
					<div class="mat-option-container">
						<span class="notranslate">Custom</span>
					</div>
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-12 row align-center" *ngIf="displayCustomFields">
		<mat-form-field class="mr10" appearance="outline">
			<mat-label>Width ({{ dialogVO.maxWidth }} max) px</mat-label>
			<input
				matInput
				type="number"
				min="25"
				[value]="dialogVO.maxWidth"
				[max]="dialogVO.maxWidth"
				placeholder="Width"
				formControlName="resizedWidth"
			/>
		</mat-form-field>
		<span>X</span>
		<mat-form-field class="mr10 ml10" appearance="outline">
			<mat-label>Height ({{ dialogVO.maxHeight }} max) px</mat-label>
			<input
				matInput
				type="number"
				min="25"
				[value]="dialogVO.maxHeight"
				[max]="dialogVO.maxHeight"
				placeholder="Height"
				formControlName="resizedHeight"
			/>
		</mat-form-field>
	</div>

	<mat-form-field class="mr10" appearance="outline" class="col-12">
		<mat-select class="notranslate" formControlName="imageType">
			<mat-option [value]="'PNG'" selected>
				<div class="mat-option-container">
					<span class="notranslate">PNG</span>
				</div>
			</mat-option>
			<mat-option [value]="'JPG'">
				<div class="mat-option-container">
					<span class="notranslate">JPG</span>
				</div>
			</mat-option>
			<mat-option [value]="'WEBP'">
				<div class="mat-option-container">
					<span class="notranslate">WEBP</span>
				</div>
			</mat-option>
		</mat-select>
	</mat-form-field>
	<div
		*ngIf="displayWarningNotice"
		class="col-12 tac align-center justify-center"
		style="font-size: 12px; color: #c00"
	>
		Size is larger than original. The quality of the image may be effected.
	</div>
</div>
