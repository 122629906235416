<div style="width: 500px; max-width: 100%" class="col-12 col">
	<p class="col-12 clear">{{ this.dialogParams.message }}</p>

	<mat-expansion-panel
		(opened)="this.hiddenMessageOpen = true"
		(closed)="this.hiddenMessageOpen = false"
		class="col-12 clear"
	>
		<mat-expansion-panel-header>
			<mat-panel-title>
				{{
					!this.hiddenMessageOpen ? "Show Error Details" : "Hide Error Details"
				}}
			</mat-panel-title>
		</mat-expansion-panel-header>

		<div
			style="height: 300px; overflow-y: auto; overflow-x: hidden"
			*ngIf="this.hiddenMessageOpen"
		>
			<p *ngFor="let hiddenMessageLine of this.dialogParams.hiddenMessage">
				{{ hiddenMessageLine }}
			</p>
		</div>
	</mat-expansion-panel>
</div>
