<div
	*ngIf="groupedFeedback !== undefined"
	class="feedback-unit"
	[ngClass]="{ 'is-nested': isNested }"
>
	<div
		class="feedback-item-info"
		[ngClass]="{ 'has-image': groupedFeedback.imageUrl }"
	>
		<div
			*ngIf="
				groupedFeedback.imageUrl && groupedFeedback.feedbackViews?.length > 0
			"
			class="feedback-info-image"
			[ngStyle]="{
				'background-image': 'url(' + groupedFeedback.imageUrl + ')'
			}"
		>
			<div
				class="feedback-info-image-hover"
				[ngStyle]="{
					'background-image': 'url(' + groupedFeedback.imageUrl + ')'
				}"
			></div>
		</div>
		<div style="display: flex; flex-direction: column">
			<div class="bold notranslate" style="font-size: 14px" *ngIf="!isNested">
				{{ groupedFeedback.title }}
			</div>
			<div
				*ngIf="groupedFeedback.subtitle"
				class="feedback-subtitle notranslate"
			>
				{{ groupedFeedback.subtitle }}
			</div>
		</div>
	</div>
	<div class="col-12 col p10">
		<app-feedback-message
			*ngFor="
				let feedbackView of groupedFeedback.feedbackViews;
				trackBy: trackById
			"
			[feedback]="feedbackView"
			[isCurrentUser]="currentUserId === feedbackView?.userIndex?.id"
		>
		</app-feedback-message>
	</div>
	<app-feedback-container
		[isNested]="true"
		*ngFor="let nestedGroupFeedback of groupedFeedback.nestedGroups"
		[groupedFeedback]="nestedGroupFeedback"
	>
	</app-feedback-container>
	<div class="feedback-unit-end"></div>
</div>
