import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
} from "@angular/forms";
import { ProductViewClassConfigWithInheritanceAHubVO } from "app/modules/routes/aview/valueObjects/product-view-class-config-with-inheritance.aview.vo";
import { DataSetLibraryViewClassConfigWithInheritanceAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-class-config-with-inheritance.ahub.vo";
import { DataSetLibraryViewClassConfigAHubVO } from "app/valueObjects/ahub/library/dataset-library-view-class-config.ahub.vo";
import { ProductClassIndexAHubVO } from "app/valueObjects/ahub/library/product-class-index.ahub.vo";
import { PropertyAllocationObjectVO } from "app/valueObjects/stream/product-allocation-object-stream.vo";
import { ExtractDefinitionPropertyAllocationObjectVO } from "app/valueObjects/view/extract-definition-property-allocation.view.vo";
import { isEqual } from "lodash";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators";
import { DialogService } from "../../dialogs/dialog.service";
import { componentDestroyStream, Hark } from "../../hark.decorator";
import { Utils } from "../../utils";

@Component({
	selector: "app-library-view-class-config",
	templateUrl: "./library-view-class-config.component.html",
	styleUrls: ["./library-view-class-config.component.scss"],
})
@Hark()
export class LibraryViewClassConfigComponent implements OnInit {
	@Input()
	selectedClassLibraryViewConfig$: BehaviorSubject<ProductViewClassConfigWithInheritanceAHubVO>;

	@Input() allocs$: Observable<PropertyAllocationObjectVO[]>;
	allocs: PropertyAllocationObjectVO[];
	assetTypeAllocs$: Observable<PropertyAllocationObjectVO[]>;
	nonAssetTypeAllocs$: Observable<PropertyAllocationObjectVO[]>;

	@Input() selectedClass$: Observable<ProductClassIndexAHubVO>;

	@Input() classIndexes: ProductClassIndexAHubVO[];

	@Output()
	configChangeHandler: EventEmitter<DataSetLibraryViewClassConfigAHubVO> = new EventEmitter<DataSetLibraryViewClassConfigAHubVO>();

	/**
	 * Library View Config Category Form control group
	 */
	libraryViewClassConfigForm: FormGroup =
		this.buildLibraryViewClassConfigForm();

	get productInfoPropertyAllocs(): FormArray {
		return this.libraryViewClassConfigForm.get(
			"productInfoPropertyAllocs"
		) as FormArray;
	}

	pristineClassConfig: DataSetLibraryViewClassConfigAHubVO;

	displayedColumns = ["id", "value"];

	dataSource$: BehaviorSubject<AbstractControl[]> = new BehaviorSubject([]);

	classLabelMap: Map<number, string>;
	inheritedAllocClassLabelMap: Map<number, string> = new Map<number, string>();

	filteredAssetTypeAllocs$: Observable<PropertyAllocationObjectVO[]>;
	filteredNoneAssetTypeAllocs$: Observable<PropertyAllocationObjectVO[]>;

	/**
	 * Used to return the value of each 'select-with-search' option we should use for searching
	 */
	allocPropertyLabelValue = (
		option: ExtractDefinitionPropertyAllocationObjectVO
	) => {
		return option.property.label;
	};

	/**
	 * Used to determine which value of the option object should be applied if an option is selected
	 */
	allocValue = (option: ExtractDefinitionPropertyAllocationObjectVO) => {
		return option;
	};

	/**
	 * Used to determine which value of the option object should be applied if an option is selected
	 */
	allocIdValue = (option: ExtractDefinitionPropertyAllocationObjectVO) => {
		return option.id;
	};

	imagePropertyId: number;

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly dialogService: DialogService
	) {}

	ngOnInit() {
		this.classLabelMap = new Map<number, string>(
			this.classIndexes.map((clazz) => [clazz.id, clazz.label])
		);

		combineLatest([
			this.selectedClassLibraryViewConfig$.pipe(distinctUntilChanged(isEqual)),
			this.allocs$.pipe(),
		])
			.pipe(takeUntil(componentDestroyStream(this)))
			.subscribe(([classConfig, allocs]) => {
				// Handy for determining the property chip background colours
				this.allocs = allocs;

				// We need a 'pristine' copy too, such that we can only update (and ultimately save)
				// what has been set by the user and not all of the merged properties
				this.pristineClassConfig = Utils.clone(classConfig);

				// Clear any previous productInfoPropertyAllocs form array items
				// this.libraryViewClassConfigForm.removeControl('productInfoPropertyAllocs');
				this.dataSource$.next([]);
				this.inheritedAllocClassLabelMap.clear();

				// This class config is 'merged' from all parent class configs
				// Now lets fill in the form with existing data
				this.formsSet(classConfig);

				this.imagePropertyId = classConfig.productMainImagePropertyAlloc;

				if (
					classConfig &&
					classConfig.productInfoPropertyAllocs &&
					classConfig.productInfoPropertyAllocs.length > 0
				) {
					// Did this class config inherit any info allocs from its parents
					// If so let make a map of alloc to class labels for the view
					if (
						classConfig.productInfoPropertyAllocsInheritanceMap &&
						classConfig.productInfoPropertyAllocsInheritanceMap.size > 0
					) {
						classConfig.productInfoPropertyAllocsInheritanceMap.forEach(
							(key, value) => {
								this.inheritedAllocClassLabelMap.set(
									value,
									this.classLabelMap.get(key)
								);
							}
						);
					}

					// Lets build some FormArray elements from the propertyInfoAllocIds
					const formArray: FormArray =
						this.buildProductInfoPropertyAllocsFormArray(classConfig);

					// Lets build the parameters form array based on the selectedExportedDestination
					this.libraryViewClassConfigForm.setControl(
						"productInfoPropertyAllocs",
						formArray
					);

					// Lets also use these FormArray controls to populate our mat-table
					this.dataSource$.next(formArray.controls);
				}
			});

		this.libraryViewClassConfigForm.valueChanges
			.pipe(
				filter((formValue) => formValue !== undefined),
				takeUntil(componentDestroyStream(this))
			)
			.subscribe((formValue) => {
				// Lets tidy up the fluff required by productInfoPropertyAllocs form array tables, just return the
				// alloc id array
				const fluffFreeLibraryViewClassConfig: DataSetLibraryViewClassConfigAHubVO =
					formValue;
				if (formValue.productInfoPropertyAllocs) {
					const infoAllocIds: number[] = [];
					formValue.productInfoPropertyAllocs.forEach((allocFormValue) => {
						if (allocFormValue.id > 0) {
							infoAllocIds.push(allocFormValue.id);
						}
					});
					fluffFreeLibraryViewClassConfig.productInfoPropertyAllocs =
						infoAllocIds;
				} else {
					formValue.productInfoPropertyAllocs = [];
				}

				if (
					!isEqual(fluffFreeLibraryViewClassConfig, this.pristineClassConfig)
				) {
					this.configChangeHandler.emit(fluffFreeLibraryViewClassConfig);
				}

				// We only want to emit these changes one at a time, so lets reset the pristine to reflect this change
				// this.pristineClassConfig = fluffFreeLibraryViewClassConfig;
			});

		this.assetTypeAllocs$ = this.allocs$.pipe(
			map((allocs) =>
				allocs.filter(
					(alloc) =>
						alloc.property.primitiveType === "ASSET" ||
						alloc.property.label === "NOT SET"
				)
			)
		);

		this.nonAssetTypeAllocs$ = this.allocs$.pipe(
			map((allocs) =>
				allocs.filter(
					(alloc) =>
						alloc.property.primitiveType !== "ASSET" ||
						alloc.property.label === "NOT SET"
				)
			)
		);

		// Filtered properties for search (Alphabetical Order)
		this.filteredAssetTypeAllocs$ = this.assetTypeAllocs$.pipe(
			map((allocs) => {
				return this.sortAllocationsAlphabetically(allocs);
			})
		);

		this.filteredNoneAssetTypeAllocs$ = this.nonAssetTypeAllocs$.pipe(
			map((allocs) => {
				return this.sortAllocationsAlphabetically(allocs);
			})
		);
	}

	buildLibraryViewClassConfigForm(): FormGroup {
		return this.formBuilder.group({
			classId: [],
			productIdentifierPropertyAlloc: new FormControl(),
			productMainImagePropertyAlloc: [],
		});
	}

	/**
	 * Set the ClassLibraryViewConfigAHubVO into our forms which are currently using it
	 */
	private formsSet(classConfig: DataSetLibraryViewClassConfigAHubVO) {
		//Set the ClassLibraryViewConfigAHubVO as generic form data
		let formData: any = Utils.clone(classConfig);

		//If the data is undefined then we will set the form to empty
		if (!formData) {
			formData = {};
		}

		//We need to ensure this has some value otherwise it will break
		if (!formData.productInfoPropertyAllocs) {
			formData.productInfoPropertyAllocs = [];
		}

		//Update the form data
		this.libraryViewClassConfigForm.reset(formData, {
			onlySelf: false,
			emitEvent: false,
		});
	}

	buildProductInfoPropertyAllocsFormArray(
		classConfig: DataSetLibraryViewClassConfigWithInheritanceAHubVO
	): FormArray {
		let formArray: FormArray;

		if (classConfig && classConfig.productInfoPropertyAllocs) {
			formArray = new FormArray([]);
			classConfig.productInfoPropertyAllocs.forEach((allocId) => {
				const formControl = this.formBuilder.group({
					id: allocId,
					value: "",
				});
				// Lets disable this info property if it was inherited from a parent class
				if (this.inheritedAllocClassLabelMap.get(allocId)) {
					formControl.disable();
				}
				formArray.push(formControl);
			});
		}

		return formArray;
	}

	addProductInfoAlloc() {
		const formArray: FormArray = this.libraryViewClassConfigForm.controls
			.productInfoPropertyAllocs
			? (this.libraryViewClassConfigForm.controls
					.productInfoPropertyAllocs as FormArray)
			: new FormArray([]);
		formArray.push(
			this.formBuilder.group({
				id: 0,
				value: "",
			})
		);
		this.libraryViewClassConfigForm.setControl(
			"productInfoPropertyAllocs",
			formArray
		);
		this.dataSource$.next(formArray.controls);
	}

	/**
	 * allows info properties to be moved 'up' and 'down' the viewing order. Manipulation is done on a simple array of value (to avoid valuesChanges firing) then a
	 * new formArray is created and replaces exisiting form array
	 */
	move(shift, currentIndex) {
		let reorderedFormArray: FormArray = new FormArray([]);
		let exisitingPropertyInfoValues: any[] =
			this.libraryViewClassConfigForm.controls.productInfoPropertyAllocs.value;

		let newIndex: number = currentIndex + shift;
		if (newIndex === -1) {
			newIndex = exisitingPropertyInfoValues.length - 1;
		} else if (newIndex === exisitingPropertyInfoValues.length) {
			newIndex = 0;
		}

		this.moveItem(exisitingPropertyInfoValues, currentIndex, newIndex);

		exisitingPropertyInfoValues.forEach((infoProperty) => {
			reorderedFormArray.push(this.formBuilder.group(infoProperty));
		});

		this.libraryViewClassConfigForm.setControl(
			"productInfoPropertyAllocs",
			reorderedFormArray
		);
	}

	moveItem(data, from, to) {
		// remove `from` item and store it
		const f = data.splice(from, 1)[0];
		// insert stored item into position `to`
		data.splice(to, 0, f);
	}

	remove(currentIndex) {
		const formArray: FormArray = this.libraryViewClassConfigForm.controls
			.productInfoPropertyAllocs as FormArray;
		formArray.removeAt(currentIndex);
	}

	ngOnDestroy() {
		// Empty On destroy to ensure @Hark decorator works for an AOT build
	}

	getBackgroundColourFromSelectedFormControlAlloc(
		formControl: AbstractControl
	): string {
		let colour = "lightgrey";

		const selectedAlloc: ExtractDefinitionPropertyAllocationObjectVO =
			formControl.value;

		if (selectedAlloc && selectedAlloc.section) {
			colour = selectedAlloc.section.colour;
		}
		return colour;
	}

	getBackgroundColourFromSelectedFormControlAllocId(
		formControl: AbstractControl
	): string {
		let colour = "lightgrey";

		if (this.allocs) {
			const selectedAllocId: number = formControl.value;

			const selectedAlloc = this.allocs.find(
				(alloc) => alloc.id === selectedAllocId
			);

			if (selectedAlloc && selectedAlloc.section) {
				colour = selectedAlloc.section.colour;
			}
		}

		return colour;
	}

	sortAllocationsAlphabetically(allocations) {
		if (!allocations) {
			return 0;
		}

		return allocations.sort((a, b) => {
			// Make sure NOT SET is at the end.
			if (a.property.label == "NOT SET") {
				return 1;
			}

			if (a.property.label > b.property.label) {
				return 1;
			} else if (a.property.label < b.property.label) {
				return -1;
			}
			return a.section.label > b.section.label ? 1 : -1;
		});
	}

	hasValidSelectableOptions(alloc: PropertyAllocationObjectVO[]): boolean {
		//Nothing there then bail out
		if (!alloc || alloc.length == 0) {
			return false;
		}

		//Did we find a valid allocation
		return alloc.find((alloc) => alloc.id > 0) !== undefined;
	}

	emptyInfoPropertyAlreadyAvailable(): boolean {
		const infoProperties: InfoPropertyFormGroup[] = this
			.libraryViewClassConfigForm.controls.productInfoPropertyAllocs
			? this.libraryViewClassConfigForm.controls.productInfoPropertyAllocs.value
			: [];

		const emptyInfoProperties = infoProperties.find(
			(infoProperty) => infoProperty.id === 0
		);
		return emptyInfoProperties !== undefined;
	}
}

export interface ClassConfigChange {
	key: string;
	value: string;
}

export interface InfoPropertyFormGroup {
	id: number;
	value: string;
}
