<li
	class="tree-item col col-12 flex-1 scroll-y"
	[ngClass]="{
		'has-children': tree.subTrees.length > 0,
		'no-children': tree.subTrees.length < 1,
		'inactive-dropdown': !this.tree.childrenShow,
		isHighlighted: tree.isHighlighted
	}"
	id="list-{{ tree.id }}"
>
	<div class="highlight"></div>
	<!--   [cdkDropListConnectedTo]="connectedListArray" -->
	<div
		class="row align-center col-12 tree-item-label flex-nowrap node-item"
		[attr.data-id]="tree.id"
		[attr.id]="'node-' + tree.id"
		(click)="this.tree.treeComponent.treeSubTreeSelect(tree)"
		[ngClass]="{ isHighlighted: tree.isHighlighted }"
	>
		<i
			aria-hidden="true"
			class="bi bi-caret-down-fill flex"
			*ngIf="tree.subTrees.length > 0 && !this.tree.childrenShow"
			(click)="this.tree.treeComponent.treeSubTreeOpen(tree)"
		></i>
		<i
			aria-hidden="true"
			class="bi bi-caret-up-fill flex"
			*ngIf="tree.subTrees.length > 0 && this.tree.childrenShow"
			(click)="this.tree.treeComponent.treeSubTreeClose(tree)"
		></i>
		<span class="flex-1 flex">{{ tree.node.label }} </span>
		<!-- <i aria-hidden="true" class="bi bi-arrow-up-circle-fill ml10" (click)='tree.treeComponent.treeConfig.nodeOrderMoveUpFunction(tree.treeComponent.treeConfig.functionScope, tree.node)'></i>
    <i aria-hidden="true" class="bi bi-arrow-down-circle-fill ml10" (click)='tree.treeComponent.treeConfig.nodeOrderMoveDownFunction(tree.treeComponent.treeConfig.functionScope, tree.node)'></i> -->
		<i
			aria-hidden="true"
			[matMenuTriggerFor]="menu"
			class="bi bi-three-dots-vertical ml20"
		></i>
		<mat-menu #menu="matMenu" class="tal">
			<button
				mat-menu-item
				(click)="tree.treeComponent.treeAddToNode(tree.node)"
			>
				<span>Create Child Category</span>
			</button>
			<!-- <button  mat-menu-item (click)='openMoveDialog()'>
        <span>Move</span>
      </button> -->
			<button mat-menu-item (click)="openOrderDialog()" *ngIf="ancestryIds[0]">
				<span>Order</span>
			</button>
			<button
				mat-menu-item
				(click)="tree.treeComponent.treeDeleteNode(tree.node)"
			>
				<span>Delete</span>
			</button>
			<button
				mat-menu-item
				(click)="tree.treeComponent.treeEditNode(tree.node)"
			>
				<span>Edit</span>
			</button>
		</mat-menu>
	</div>
	<ul
		*ngIf="tree.subTrees.length > 0 && this.tree.childrenShow"
		[ngClass]="{
			'has-children': tree.subTrees.length > 0,
			'no-children': tree.subTrees.length < 1,
			isHidden: !this.tree.childrenShow
		}"
	>
		<app-tree-list-item
			[tree]="treeItem"
			*ngFor="let treeItem of tree.subTrees; let i = index"
		></app-tree-list-item>
	</ul>
</li>

<!-- tree.treeComponent.treeMoveNode(tree.node, parent.node) -->
