<div
	style="
		min-width: 170px;
		max-width: 400px;
		height: 100%;
		display: inline-block;
	"
>
	<div class="col">
		<div
			style="
				display: flex;
				justify-content: space-between;
				flex: 1;
				align-items: center;
				padding: 8px;
				border-bottom: solid 1px #ccc;
			"
		>
			<span style="font-size: 16px; font-weight: 600">List</span>
			<button
				mat-raised-button
				*ngIf="this.showDoneEditing"
				color="primary"
				(click)="this.doneEditingClick()"
			>
				Done
			</button>
		</div>

		<app-multiple-add-input
			[list]="listValues$ | async"
			[inputLabel]="'New Value'"
			[allowDragSort]="true"
			[listChanged$]="listChanged$"
			[inputForm]="addValueForm"
			style="background-color: white; width: 100%"
			(listChagedData)="listDataChanged($event)"
		>
		</app-multiple-add-input>

		<form
			[formGroup]="listInputForm"
			style="
				display: flex;
				padding: 0px 12px;
				align-items: center;
				border-top: solid 1px #ccc;
			"
			*ngIf="showRawData"
		>
			<mat-form-field style="flex: 1; padding-top: 6px" appearance="outline">
				<mat-label>List Value</mat-label>
				<input
					matInput
					class="notranslate"
					style="width: 100%"
					type="text"
					formControlName="listInput"
					autocomplete="off"
					jsonArrayValid="listInput"
				/>
			</mat-form-field>
			<mat-icon
				id="copyIcon"
				class="notranslate"
				style="cursor: pointer; margin-left: 16px"
				matTooltip="Copy"
				matTooltipPosition="above"
				(click)="this.copyValueToClipboard(); $event.stopPropagation()"
			>
				file_copy</mat-icon
			>
		</form>
	</div>
</div>
