<div
	style="
		min-width: 170px;
		max-width: 400px;
		height: 100%;
		display: flex;
		flex-direction: column;
	"
>
	<div
		style="
			display: flex;
			justify-content: space-between;
			flex: 1;
			align-items: center;
			padding: 8px;
		"
	>
		<span style="font-size: 16px; font-weight: 600">Select Options</span>
		<button
			*ngIf="showDoneEditing"
			mat-raised-button
			color="primary"
			(click)="this.doneEditingClick()"
		>
			Done
		</button>
	</div>

	<div style="max-height: 300px; overflow: auto; width: 100% !important">
		<mat-selection-list
			(selectionChange)="selectionChanged()"
			[(ngModel)]="selectedOptions"
			cdkDropList
			class="option-list"
			(cdkDropListDropped)="drop($event)"
		>
			<mat-list-option
				checkboxPosition="before"
				class="option-list-item"
				color="primary"
				*ngFor="let option of valueOptions"
				[value]="option.value"
				cdkDrag
			>
				{{ option.label }}
			</mat-list-option>
		</mat-selection-list>
	</div>

	<form
		[formGroup]="listInputForm"
		style="display: flex; padding: 0px 12px; align-items: center"
		*ngIf="showRawData"
	>
		<mat-form-field style="flex: 1; padding-top: 8px" appearance="outline">
			<mat-label>List Value</mat-label>
			<input
				matInput
				class="notranslate"
				style="width: 100%"
				type="text"
				formControlName="listInput"
				autocomplete="off"
				jsonArrayValid="listInput"
			/>
		</mat-form-field>

		<mat-icon
			id="copyIcon"
			class="notranslate"
			style="cursor: pointer; margin-left: 16px"
			matTooltip="Copy"
			matTooltipPosition="above"
			(click)="this.copyValueToClipboard($event); $event.stopPropagation()"
		>
			file_copy</mat-icon
		>
	</form>
</div>
