import {
	promiseMergeArray,
	ServiceAnalytics,
	ServiceOptions,
} from "@harksolutions/ahub-web-services";
import { AHubService } from "app/services/ahub/ahub.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { AHubActions } from "../actions/ahub.actions";
import { AHubBaseEpic } from "./ahub-base.epics";
import { Epic } from "./epic";
import { ActionNumberBooleanAnalyticFilterAHubVO } from "../actions/types/ahub-analytic.action-types";

/**
 * Class for the View epic functions
 */
export class AHubAnalyticsEpics extends AHubBaseEpic implements Epic {
	/**
	 * Web service class we will be using to make all our calls
	 */
	serviceAnalytics: ServiceAnalytics = undefined;

	constructor(public readonly aHubService: AHubService) {
		super(aHubService);

		const serviceOptions = new ServiceOptions(
			environment.aHubApi.domain,
			environment.aHubApi.basePath
		);

		serviceOptions.logRequest = true;

		//Create our service analytics with the base parameters for our requests
		this.serviceAnalytics = new ServiceAnalytics(serviceOptions);
	}

	/**
	 * Returns a list of the epic function avalible from this class
	 * If a new epic is added to the class it must be added to this list
	 */
	epicMethods(): any[] {
		return [this.analyticsFetch];
	}

	/**
	 * Watch for the analytics fetch action.
	 *
	 * @param action$ 			The action to test.
	 *
	 * @returns 				Returns the next acion to run.
	 */
	analyticsFetch = (
		action$: Observable<ActionNumberBooleanAnalyticFilterAHubVO>
	) =>
		action$.pipe(
			filter(({ type }) => type === AHubActions.ANALYTICS_FETCH),
			// this.tapLogAction(),
			mergeMap((action) =>
				this.dataToAction(
					this.serviceAnalytics.analyicsGet(
						this.reqOptSigned(),
						action.number,
						action.analyticFilter,
						action.boolean
					),
					action,
					AHubActions.analyticsSet
				)
			)
		);
}
