<div
	idToolTip="#{{ (this.currentClientIndex$ | async)?.id }}"
	class="clickable no-scroll-visible align-center flex fb-center"
	*ngIf="(this.clientIndexsLength$ | async) > 0"
	[ngStyle]="{
		'pointer-events': (this.clientIndexsLength$ | async) > 0 ? 'auto' : 'none'
	}"
>
	<i
		aria-hidden="true"
		class="notranslate bi bi-building"
		style="font-size: 20px; margin-right: 10px"
	></i>
	<span style="line-height: 1em">{{
		(this.currentClientIndex$ | async)?.name
	}}</span>

	<div
		[matMenuTriggerFor]="clientMenu"
		class="menuTrigger"
		*ngIf="(this.clientIndexsLength$ | async) >= 2"
	></div>
</div>

<mat-menu #clientMenu="matMenu" x-position="before" y-position="below">
	<button mat-menu-item (click)="switchClient()">Switch Client</button>
</mat-menu>
