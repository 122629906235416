<div class="col-12 col full-height oh" id="list-view">
	<!-- <div class='col-12 col full-height oh' cdkDropList  (cdkDropListDropped)="drop($event)"> -->
	<div class="col-12 col full-height scroll-y">
		<app-tree-list-item
			cdkDropListGroup
			class="col-12 col"
			*ngIf="this.tree != null"
			[tree]="tree"
		></app-tree-list-item>
	</div>
</div>
<div class="tree-wrapper" id="tree-view">
	<div style="display: flex; flex: 5; height: 100%">
		<svg
			#svgView
			class="disable-selection"
			style="width: 100%; height: 100%"
			[attr.viewBox]="viewboxDefinition"
			(mousedown)="svgViewMouseEvent($event)"
			(mouseup)="svgViewMouseEvent($event)"
			(mousewheel)="mouseWheel($event)"
			(window:resize)="this.viewboxMatchComponent()"
		>
			<!-- The rendering of the tree -->
			<svg:g #treeRoot>
				<svg:g
					*ngIf="this.tree != null"
					[tree]="tree"
					[treeConfig]="this.treeConfig"
					app-subtree
				/>

				<!-- A place holder for sub tree display clone copy for when dragging is happening  [attr.transform]="this.draggingSubtreeTranslatedPosition"-->
				<svg:g #draggingTree>
					<svg:g
						*ngIf="this.draggingSubtree != null && draggingVisableDelay"
						[tree]="this.draggingSubtree"
						[treeConfig]="this.treeConfig"
						app-subtree
						style="opacity: 0.5; cursor: move"
					/>
				</svg:g>
			</svg:g>
		</svg>
	</div>

	<div class="toolbar-container">
		<app-fab-icon-busy-button
			[position]="'left'"
			[buttonIcon]="'bi-arrows-angle-expand'"
			(clicked)="this.treeSubTreeOpenAll()"
			matTooltip="Expand All Branches"
		>
		</app-fab-icon-busy-button>
		<app-fab-icon-busy-button
			[position]="'center'"
			[buttonIcon]="'bi-arrows-angle-contract'"
			(clicked)="this.treeSubTreeCloseAll()"
			matTooltip="Collapse All Branches"
		>
		</app-fab-icon-busy-button>
		<app-fab-icon-busy-button
			[position]="'center'"
			[buttonIcon]="'bi-zoom-in'"
			(clicked)="this.treeZoomIn()"
			matTooltip="Zoom In"
		>
		</app-fab-icon-busy-button>
		<app-fab-icon-busy-button
			[position]="'center'"
			[buttonIcon]="'bi-zoom-out'"
			(clicked)="this.treeZoomOut()"
			matTooltip="Zoom out"
		>
		</app-fab-icon-busy-button>
		<app-fab-icon-busy-button
			*ngIf="zoomToFitCheckBox"
			[position]="'right'"
			[buttonIcon]="'bi-fullscreen'"
			(clicked)="zoomToFitCheckBox = !zoomToFitCheckBox; this.treeZoomFitAll()"
			matTooltip="Automatic Zoom To Fit Enabled"
		>
		</app-fab-icon-busy-button>
		<app-fab-icon-busy-button
			*ngIf="!zoomToFitCheckBox"
			[position]="'right'"
			[buttonIcon]="'bi-arrows-fullscreen'"
			(clicked)="zoomToFitCheckBox = !zoomToFitCheckBox; this.treeZoomFitAll()"
			matTooltip="Manual Zoom, click to enable Zoom To Fit"
		>
		</app-fab-icon-busy-button>
	</div>
</div>
