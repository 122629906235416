<div
	style="position: relative"
	class="col full-height product-view-full-container"
>
	<div class="col-12 space-between align-center pb20" style="padding-top: 12px">
		<div class="flex productViewFullTitle">
			<span class="bold">{{ selectedCategory.label }}</span>
			<mat-icon class="notranslate">chevron_right</mat-icon>
			<span class="bold">{{ productTitle }}</span>
		</div>
		<div class="flex justify-end align-center">
			<div
				class="flex"
				style="align-items: center"
				*ngIf="(productSectionCount$ | async) > 1"
			>
				<!-- Group By slide toggle container -->
				<mat-checkbox
					(change)="groupBySectionOrPropertyChange($event)"
					[(ngModel)]="groupBySection"
				>
					Arrange By Property Group
				</mat-checkbox>
			</div>
			<div class="pl20">
				<app-raised-busy-button
					[buttonLabel]="'Back'"
					(clicked)="closeProductView()"
				>
				</app-raised-busy-button>
			</div>
		</div>
	</div>
	<!-- IMAGE AND DATA CONTAINER -->
	<div
		[ngClass]="{ detailsOnly: (productAssetsView$ | async)?.length == null }"
		class="assetsAndDetailsContainer oh"
	>
		<!-- ASSET CONTAINER -->
		<div class="leftImages" *ngIf="productAssetsView$ | async">
			<div class="flex-1 col scroll-y">
				<app-product-asset-view
					[tooltip]="'Click to view image in a pop up window'"
					*ngFor="let asset of productAssetsView$ | async; let i = index"
					[productAssetViewParam]="asset"
					[ngClass]="{ active: i == 0, firstChild: i == 0 }"
					[attr.data-index]="i"
					style="
						display: block;
						border: 1px solid #e4e9ec !important;
						border-radius: 4px;
					"
					class="mb10"
				>
				</app-product-asset-view>
			</div>
		</div>
		<!-- PRODUCT DETAILS CONTAINER -->
		<div class="detailsContainer oh">
			<!-- GROUP BY SECTION CONTAINER -->
			<div class="full-height col scroll-y">
				<!-- SECTION CONTAINER -->
				<div
					*ngFor="
						let sectionView of productViewPropertiesDataSourceGroupedBySection$
							| async
					"
					class="col p15 mb10"
				>
					<div class="col-12 row">
						<div
							class="pb10 pl10 pr10 pt10 align-center bold flex"
							*ngFor="let section of sectionView?.sections"
						>
							<span
								class="sectionIconLarge"
								[style.background]="section.colour"
							></span>
							<span style="font-size: 1.7em">{{ section.label }}</span>
						</div>
					</div>

					<div class="row col-12 pb20">
						<div
							*ngFor="let columnIndex of [1, 2]"
							style="overflow: auto"
							class="ml10 mr10 flex-1"
							[ngClass]="{
								rightTable: columnIndex == 2,
								leftTable: columnIndex == 1
							}"
						>
							<!-- PROPERTY CONTAINER -->
							<table
								mat-table
								[dataSource]="
									propertyViewsForColumn(sectionView.propertyViews, columnIndex)
								"
								aria-hidden="true"
								class="flex-1"
								style="width: 100%"
							>
								<ng-container matColumnDef="propertyName">
									<th mat-header-cell *matHeaderCellDef scope="row">
										Property Name
									</th>
									<td mat-cell *matCellDef="let element">
										<div class="flex align-center">
											<span
												class="sectionIconSmall"
												*ngIf="sectionView?.sections?.length > 1"
												[style.background]="
													element.propertyAlloc?.section.colour
												"
												[matTooltip]="element.propertyAlloc?.section?.label"
												[matTooltipPosition]="'left'"
											></span>
											<span class="bold">
												{{ element.propertyAlloc?.property.label }}</span
											>
										</div>
									</td>
								</ng-container>
								<!-- Property Value Column -->
								<ng-container matColumnDef="propertyValue">
									<th mat-header-cell *matHeaderCellDef scope="row">Value</th>
									<td mat-cell *matCellDef="let element">
										<div
											*ngIf="
												!element.isMatrix &&
												element.propertyAlloc?.property.typeReference !==
													'TABLE' &&
												element.propertyAlloc?.property.typeReference !==
													'TEXTLIST' &&
												element.propertyAlloc?.property.typeReference !==
													'BOOLEAN'
											"
										>
											<span>{{ element.propertyValue }}</span>
										</div>
										<!-- Boolean -->
										<div
											*ngIf="
												element.propertyAlloc?.property.typeReference ==
												'BOOLEAN'
											"
										>
											<mat-checkbox
												[checked]="element.propertyValue"
												[disabled]="true"
											>
											</mat-checkbox>
										</div>
										<!-- Boolean -->
										<!-- Text Lists -->
										<div
											*ngIf="
												element.propertyAlloc?.property.typeReference ==
												'TEXTLIST'
											"
										>
											<ul class="ml15">
												<li
													*ngFor="
														let item of stringToJson(element.propertyValue)
													"
													class="pb10"
												>
													{{ item }}
												</li>
											</ul>
										</div>
										<!-- Text Lists -->

										<!-- Matrix -->
										<app-matrix-property-editor
											*ngIf="element.isMatrix"
											[matrix]="element.matrixPropertyView?.matrixDefinition"
											[rawData]="element.matrixPropertyView?.matrixData"
											[productId]="0"
											[showRawDataInput]="false"
											[property]="element.propertyAlloc?.property"
											[propertyType]="
												element.matrixPropertyView?.matrixPropertyType
											"
											[editable]="false"
											[showTitle]="false"
											class="col-12 pt10"
										>
										</app-matrix-property-editor>
										<!-- Matrix -->
									</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr
									mat-row
									*matRowDef="let row; columns: displayedColumns"
								></tr>
							</table>
						</div>

						<!-- Matrix Options -->

						<div
							*ngIf="sectionView?.matrixGridOptions?.gridOptions"
							class="col-12 col pl10 pr10 pt40 full-width-data"
							style="
								grid-column-end: 3;
								grid-column-start: 1;
								min-height: 330px;
							"
						>
							<div class="card-sub-title">Product Option Information</div>

							<ag-grid-angular
								class="ag-theme-alpine"
								style="height: 220px; width: 100%"
								[frameworkComponents]="this.frameworkComponents"
								[gridOptions]="sectionView.matrixGridOptions.gridOptions"
							>
							</ag-grid-angular>
						</div>

						<!-- Tables -->

						<div
							*ngIf="
								sectionView.propertyTableViews &&
								sectionView.propertyTableViews.length > 0
							"
							class="col-12 col pl10 pr10 pt40"
						>
							<div
								class="card-sub-title"
								style="margin-bottom: 40px !important"
							>
								Tables
							</div>
							<div class="col-12 row tableSectionContainer">
								<div
									*ngFor="let table of sectionView.propertyTableViews"
									class="pb20 col mb20 tableSection"
								>
									<div class="flex align-center pb15">
										<span
											class="sectionIconSmall"
											*ngIf="sectionView?.sections?.length > 1"
											[style.background]="table.propertyAlloc?.section.colour"
										></span>
										<span class="bold" style="font-size: 12px">
											{{ table.propertyAlloc?.property.label }}</span
										>
									</div>

									<div
										*ngIf="
											formatTableData(table.propertyValue);
											let formattedTable
										"
									>
										<property-table-renderer
											*ngIf="tableHasData(formattedTable)"
											[rawData]="formattedTable"
										>
										</property-table-renderer>
									</div>
									<div
										*ngIf="formatTableData(table.propertyValue).rows.length < 1"
									>
										<div class="col-12 note align-center justify-center">
											No data to display
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
