<!--
    DEV-NOTE :   We need to set a size on the pop-up editor as otherwise it cannot calculate
                 the position of the popup to ensure it is onscreen. At this point I have just set
                 the width and height
    https://trello.com/c/ITr23Ocw/245-matrix-popup-is-not-well-positioned

-->
<app-matrix-property-editor
	style="width: 454px"
	[matrix]="matrix"
	[rawData]="rawData"
	[productId]="productId"
	[property]="paramsCustom.property"
	[propertyType]="paramsCustom.propertyType"
	[editable]="editable"
	[showDoneButton]="true"
	(matrixValueUpdated)="matrixValueUpdated($event)"
	(doneEditing)="doneEditing()"
>
</app-matrix-property-editor>
