<mat-tree
	[dataSource]="flatDataSource"
	[treeControl]="flatTreeControl"
	class="tree"
	#tree
	style="max-width: 350px"
	class="full-height scroll-y"
	[dir]="treeDirection"
>
	<!-- This is the tree node template for leaf nodes -->
	<mat-tree-node
		*matTreeNodeDef="let node"
		matTreeNodePadding
		[matTreeNodePaddingIndent]="16"
		(click)="selectNodeSet(node); $event.stopPropagation()"
		[ngClass]="{ 'selected-item': this.selectedNode == node }"
	>
		<button mat-icon-button disabled></button>
		<span
			*ngIf="!componentFactory"
			class="notranslate"
			style="overflow: hidden; text-overflow: ellipsis"
			>{{ this.nodeNameGet(node) }}</span
		>
		<app-injected-wrapper
			*ngIf="componentFactory != undefined"
			[componentFactory]="componentFactory"
			[dataVO]="node"
			[paramName]="componentVOParamName"
		>
		</app-injected-wrapper>
	</mat-tree-node>

	<!-- This is the tree node template for expandable nodes -->
	<mat-tree-node
		*matTreeNodeDef="let node; when: hasChild"
		matTreeNodePadding
		[matTreeNodePaddingIndent]="16"
		(click)="selectNodeSet(node); $event.stopPropagation()"
		[ngClass]="{ 'selected-item': this.selectedNode == node }"
	>
		<button mat-icon-button matTreeNodeToggle>
			<mat-icon class="mat-icon-rtl-mirror">
				{{ flatTreeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
			</mat-icon>
		</button>

		<span
			*ngIf="!componentFactory"
			class="notranslate"
			[ngStyle]="{
				margin: treeDirection == 'rtl' ? '0px 0px 0px 40px' : '0px 40px 0px 0px'
			}"
			>{{ this.nodeNameGet(node) }}</span
		>
		<app-injected-wrapper
			*ngIf="componentFactory != undefined"
			[componentFactory]="componentFactory"
			[dataVO]="node"
			[paramName]="componentVOParamName"
		>
		</app-injected-wrapper>
	</mat-tree-node>
</mat-tree>
