<mat-form-field style="width: 100%" appearance="outline">
	<mat-label>{{ placeholder }}</mat-label>
	<mat-select
		class="notranslate"
		[formControl]="selectFormControl"
		(openedChange)="focusSearchInput()"
	>
		<mat-select-trigger>
			<ng-container
				*ngTemplateOutlet="
					optionTemplate;
					context: { $implicit: selectedOption }
				"
			></ng-container>
		</mat-select-trigger>
		<div>
			<div class="oh stickySearch">
				<mat-form-field
					style="width: 100%; margin-bottom: -1.25em"
					appearance="outline"
				>
					<mat-label>Search</mat-label>
					<input
						matInput
						#searchInput
						class="notranslate"
						style="width: 100%"
						type="text"
						autocomplete="off"
						(keyup)="searchTermChanged($event)"
					/>
				</mat-form-field>
			</div>
			<div class="pr10 pl5">
				<mat-option
					*ngFor="let option of selectOptions"
					[value]="selectedValueFunction(option)"
					[disabled]="shouldOptionBeDisabled(option)"
					[matTooltip]="
						_disabledOptionsByIdWithReason.get(selectedValueFunction(option))
					"
					matTooltipPosition="above"
				>
					<ng-container
						*ngTemplateOutlet="optionTemplate; context: { $implicit: option }"
					></ng-container>
				</mat-option>
			</div>
		</div>
	</mat-select>
</mat-form-field>
