<a
	href="{{ knowledgeBaseUrl }}/ahub{{ knowledgeBasePagePath }}"
	target="_blank"
	rel="noopener noreferrer"
	*ngIf="knowledgeBasePagePath"
	class="flex align-center"
>
	<i
		aria-hidden="true"
		class="notranslate bi-info-circle"
		style="color: #b289bf; font-size: 18px; margin-top: -10px"
	></i>
</a>
