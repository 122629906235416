import { EntityPermissionAHubVO } from "valueObjects/ahub/accounts/entity-permission.ahub.vo";
import { ToolIDEnum } from "./tool-id.view.enum";

export interface RouteViewVO {
	id?: string;
	name: string;
	pageTitle: string;
	icon: string;
	iconActive?: string;
	svgIcon?: string;
	hideInMenu?: boolean;
	showKowledgeBaseIcon?: boolean;
	knowledgeBasePagePath?: string;
	routes: RouteViewVO[];
	description: string;
	permissions: EntityPermissionAHubVO[];
	routerLink: string;
	enabled: boolean;
	toolIds?: ToolIDEnum[];
}

/**
 * Pre-defined id's which can be used to reference key bits later
 */
export const ID_DASHBOARD = "DASHBOARD";
export const ID_AVIEW = "AVIEW";
export const ID_AWORKBOOK = "AWORKBOOK";
export const ID_USER_ACCOUNT = "USER_ACCOUNT";
export const ID_CONTENT_VIEWER = "CONTENT_VIEWER";
export const ID_ACCESS_CONTROL = "ACCESS_CONTROL";
export const ID_LIBRARY_STRUCTURE = "LIBRARY_STRUCTURE";
export const ID_LIBRARY_CONTENTS = "LIBRARY_CONTENTS";
export const ID_PUBLISHING = "PUBLISHING";
export const ID_DISTRIBUTION = "DISTRIBUTION";
export const ID_USAGE = "USAGE";
export const ID_ANALYTICS = "ANALYTICS";
