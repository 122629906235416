<div class="flex-container row fb-center" style="overflow: visible">
	<span *ngIf="this.tableCurrent < this.dataTotal"
		>Total: {{ this.tableCurrent + "/" + this.dataTotal }}</span
	>
	<span *ngIf="this.tableCurrent >= this.dataTotal" style="margin-right: 4px"
		>Total: {{ this.dataTotal }}</span
	>

	<mat-icon
		class="notranslate mediumIcon color-primary"
		*ngIf="!downloading && this.warnings?.length > 0"
		matTooltipClass="multilineTooltip"
		[matTooltip]="this.warnings"
		>more</mat-icon
	>

	<mat-icon *ngIf="downloading" class="notranslate mediumIcon downloadIcon"
		>cloud_download</mat-icon
	>
</div>
