<iframe
	#frame
	sandbox="allow-scripts"
	[srcdoc]="this.iFrameContent | trustedContent: 'html'"
	style="width: 100%; height: 100%; border: none"
	scrolling="no"
	title="3D Renderer"
	name="3D Renderer"
>
	Browser doesn't support 3D rendering
</iframe>
