<div
	class="col-12 row logEntry"
	[ngClass]="{
		errorLog: entry?.type == 'ERROR',
		warnLog: entry?.type == 'WARN',
		activeToggle: activeToggle
	}"
>
	<div class="col-2 pointer" (click)="clicked()">
		<span class="bold pr5 logHeading"
			>[{{ entry?.loggedTime | date: "h:mm:ss a" }}]
		</span>
		<span></span>
	</div>
	<div class="toggleBtn" (click)="clicked()"></div>
	<div class="col-10 col errorInfoContainer">
		<div class="col-12 clear logMessage pointer" (click)="clicked()">
			<span>{{ entry?.message }}</span>
		</div>
		<div class="col-12 col hiddenToggleContent">
			<div class="col-12 pb5 pt5">
				<span class="bold pr5 logHeading">Type: </span>
				<span>{{ entry?.type }}</span>
			</div>
			<div class="col-12 pb5">
				<span class="bold pr5 logHeading">Group: </span>
				<span>{{ entry?.groupName | titleCase }}</span>
			</div>
			<div class="col-12 pb5" *ngIf="entry?.subGroupName">
				<span class="bold pr5 logHeading">Sub Group: </span>
				<span>{{ entry?.subGroupName | titleCase }}</span>
			</div>
		</div>
	</div>
</div>
