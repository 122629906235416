<div
	class="col-12 col oh"
	[cdkDragBoundary]="'.largeCard'"
	cdkDrag
	id="feedback-drag-container"
	[ngStyle]="{
		width: width,
		height: height,
		bottom: bottom,
		right: right,
		'z-index': timeDependentZIndex
	}"
>
	<div class="feedback-title row align-center">
		<i aria-hidden="true" class="bi bi-grip-vertical mr10" cdkDragHandle></i>
		<div
			class="bold flex flex-1"
			style="
				justify-content: space-between;
				align-items: center;
				font-weight: 600 !important;
			"
		>
			<span class="notranslate">{{ subtitle }}</span>
			<form [formGroup]="groupFeedbackForm" class="flex">
				<mat-checkbox
					formControlName="grouped"
					labelPosition="before"
					class="mr10"
					style="font-size: 11px"
					>Group Feedback
				</mat-checkbox>
			</form>
		</div>
		<i
			aria-hidden="true"
			class="notranslate bi bi-x"
			(click)="closeWindow()"
		></i>
	</div>
	<div class="feedback-list oh full-height">
		<div class="pr10 pb10 pt10 col-12 col oh full-height">
			<div
				class="scroll-y full-height col-12 col"
				id="message-list"
				#feedbackContainer
			>
				<div
					*ngIf="!((groupedFeedback$ | async)?.length > 0)"
					class="no-feedback ml10"
				>
					<div>No Comments To display</div>
				</div>
				<app-feedback-container
					*ngFor="let feedback of groupedFeedback$ | async; trackBy: trackById"
					[groupedFeedback]="feedback"
				>
				</app-feedback-container>
			</div>
		</div>
	</div>
	<div class="col-12 col bottom-bar">
		<div class="hark-chat-user-input">
			<input
				type="text"
				[(ngModel)]="feedbackMessageInput"
				#userFeedbackInput
				class="hark-chat-container-message-input"
				placeholder="Write message"
				id="chatContent"
				(keyup.enter)="addFeedbackInput()"
			/>
			<mat-icon (click)="addFeedbackInput()" class="hark-chat-send"
				>send</mat-icon
			>
		</div>
	</div>

	<div
		class="dragHandle"
		cdkDrag
		(cdkDragStarted)="onResizeStart($event)"
		(cdkDragMoved)="onResizeHandleDrag($event)"
	></div>
</div>
