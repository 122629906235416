<div
	style="
		width: 500px;
		display: flex;
		flex-direction: row;
		align-items: baseline;
	"
>
	<mat-form-field style="margin-top: 16px; width: 100%" appearance="outline">
		<mat-label>File name</mat-label>
		<input
			matInput
			#fileNameInput
			placeholder="file name"
			[(ngModel)]="fileName"
			spellcheck="false"
			(change)="updateDialogVO()"
		/>
	</mat-form-field>
	<span class="notranslate">.{{ this.fileExtension }}</span>
</div>
