import { Injectable } from "@angular/core";

/**
 * This service allows us to easily save files.
 */
@Injectable()
export class FileSaverService {
	constructor() {
		// This is intentional
	}

	/**
	 * This function will save a files data out with a specified file name into the downloads folder
	 * as default.
	 *
	 * @param data            The data to save.
	 * @param fileName        The name of the file to save.
	 * @param mimeType        The type of file you are saving.
	 */
	fileStringDataSave(
		data: string,
		fileName: string,
		mimeType = "text/plain;charset=utf-8"
	) {
		// Create a new blob to save. \ufeff required to make excel open csv with utf-8 encoding (eg show euro charatcers (umlaut))
		let blob = new Blob(["\ufeff", data], { type: mimeType });

		// Are we using Internet explorer 10?
		if (navigator.msSaveBlob) {
			// Yes, so we need to use the MS save function.
			navigator.msSaveBlob(blob, fileName);
		} else {
			// Otherwise we create a new artifical link and click it.
			// Create the link.
			let link = document.createElement("a");

			// Make sure we can save the file.
			if (link.download !== undefined) {
				// Browsers that support HTML5 download attribute.
				let url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", fileName);

				// Make sure the component is not visible.
				link.style.visibility = "hidden";

				// Then add it to the DOM.
				document.body.appendChild(link);

				// Click the link to activate the save.
				link.click();

				// Then remove the link component from the DOM.
				document.body.removeChild(link);
			}
		}
	}
}
